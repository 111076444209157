import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { getMyOrders, getMyOrdersCount } from "../../../actions/myOrders";
import { asPage } from "./Page";
import { Pagination } from "../../../assets";
import OrderTile from "../OrderTile";

function MyOrders() {

    const limit = 4; // Number of orders per page
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const { user, orders, count } = useSelector(state => ({
        user: state.session.user,
        orders: state.myOrders.items,
        loading: state.myOrders.loading,
        count: state.myOrders.count
    }));

    useEffect(() => {
        if (user)
            dispatch(getMyOrdersCount(user.id));
    }, [dispatch, user]);

    useEffect(() => {
        if (user)
            dispatch(getMyOrders(user.id, (page - 1) * limit, limit));
    }, [dispatch, page, user]);

    // Redirect if not logged in
    if (!Boolean(user)) return <Redirect to="/booking/bays" />

    return <div className="my-orders maximum-width">
        <h2 className="text-center"><b>My</b> Orders</h2>
        <h3>This is a list of the orders you've made. You can only cancel orders if you make the request more than 24 hours prior to the starting time. If you want to make a new booking, <Link className="as-link" to="/booking/bays">click here</Link>.</h3>
        <div className="bookings">
            {Boolean(orders.length)
                ?
                orders.map(o => <OrderTile key={o.id} order={o} />)
                :
                <h3 className="text-center">You don't have any orders.</h3>
            }
        </div>
        {Boolean(orders.length) && <div className="text-center">
            <Pagination
                page={page}
                totalPages={Math.ceil((count || 0) / limit)}
                onPageChange={newPage => setPage(newPage)}
            />
        </div>}
    </div>
}

export default asPage(MyOrders)