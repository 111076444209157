import React, { useState } from "react";
import { validateName, validateEmail } from "./helpers";
import Field from "./Field";
import { Button } from "../../fields";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../actions/session";

export default function EmailSignup() {

    const dispatch = useDispatch();
    const { loading, loginError, needsConfirmation } = useSelector(state => state.session);

    const [values, setValues] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});

    function fieldChange({ name, value, error }) {
        setValues({
            ...values,
            [name]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [name]: error
        });
    }

    if (needsConfirmation) return <section className="text-center">
        <h4 style={{ padding: "0px 15px 30px 15px" }}>Great! Now just to validate your email.</h4>
        <div className="inner">
            You will have just received an email to {values.email}.
			<br /><br />
			Click the link in the email and it will validate your account. You can then come back here and log in.
			<br /><br />
        </div>
    </section>

    return <form key="email-signup" onSubmit={e => {
        e.preventDefault();
        const { confirmPassword, ...data } = values;
        dispatch(signup(data));
    }}>
        {/* <h4>Sign up with email</h4>
        <br /> */}
        <Field
            onChange={fieldChange}
            name="email"
            values={values}
            errors={fieldErrors}
            autoComplete="email"
            placeholder="Email"
            preProcess={value => value.toLowerCase()}
            validate={validateEmail}
        />
        <div className="flexy">
            <Field
                onChange={fieldChange}
                name="firstName"
                values={values}
                errors={fieldErrors}
                autoComplete="given-name"
                placeholder="First Name"
                validate={validateName}
                style={{
                    marginRight: 5
                }}
            />
            <Field
                onChange={fieldChange}
                name="lastName"
                values={values}
                errors={fieldErrors}
                autoComplete="family-name"
                placeholder="Last Name"
                validate={validateName}
                style={{
                    marginLeft: 5
                }}
            />
        </div>
        <Field
            onChange={fieldChange}
            name="password"
            type="password"
            values={values}
            errors={fieldErrors}
            autoComplete="new-password"
            placeholder="Password"
            validate={value => {
                let errors = [];

                if (!/.{7,40}/.test(value))
                    errors.push("Between 7 and 40 characters.");
                if (!/[a-z]/g.test(value))
                    errors.push("At least one lower case letter.");
                if (!/[A-Z]/g.test(value))
                    errors.push("At least one upper case letter.");
                if (!/[0-9]/g.test(value))
                    errors.push("At least one number.");

                if (!errors.length) {
                    return true;
                } else {
                    return errors.join(" ");
                }
            }}
        />
        <Field
            onChange={fieldChange}
            name="confirmPassword"
            type="password"
            values={values}
            errors={fieldErrors}
            autoComplete="new-password"
            placeholder="Confirm Password"
            validate={value => {
                if (value !== values.password) {
                    return "Must match the new password."
                } else {
                    return true;
                }
            }}
        />
        <Button
            type="submit"
            loading={loading}
            disabled={Object.values(fieldErrors).filter(e => e).length}
        >Create Account</Button>
        {loginError && <>
            <br />
            <div className="error text-center">
                {loginError}
            </div>
        </>}
    </form>
}