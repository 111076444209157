import React from "react";
import LoginWidget from "../../../assets/LoginWidget";

export function asPage(Component, Footer = null) {
	return () => <div className="page">
		<header>
			<LoginWidget />
		</header>
		<div className="pageContent">
			<div className="overlay dots" />
			{/* <div className="overlay dots" style={{ transform: "scale(-1)" }} /> */}
			<Component />
		</div>
		{Footer && <footer><Footer /></footer>}
	</div>
}