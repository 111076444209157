import React from "react";
import { ImgIcon } from "../../assets";
import { chevron } from "../../icons";

export default function UpDownArrows({ value = 0, onChange = () => { }, max = 20, min = 0, size= 20 }) {
	return <div className="upDownArrows">
		<div
			className={`arrow up ${value < max ? "" : "disabled"}`}
			onClick={value < max ? e => onChange(value + 1) : null}
		>
			<ImgIcon
				size={size}
				onClick={value < max ? e => { } : null}
				className="up"
				src={chevron}
			/>
		</div>
		<div
			className={`arrow down ${value > min ? "" : "disabled"}`}
			onClick={value > min ? e => onChange(value - 1) : null}
		>
			<ImgIcon
				size={size}
				onClick={value > min ? e => { } : null}
				className="down"
				src={chevron}
			/>
		</div>
	</div>
}