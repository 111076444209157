import React, { useEffect, useMemo } from "react";
import moment from "moment";
import { Redirect } from "react-router-dom";
// Redux.
import { useDispatch, useSelector } from "react-redux";
import { getBays } from "../actions/bays";
import { getSystemUsers } from "../actions/users";
import { getOrders } from "../actions/orders";
import { getClubs } from "../actions/clubs";
// Style.
import "./style.scss";
// Helper functions
import { useInterval, setCookie } from "../helpers";
// Components
import AdminLogin from "../assets/AdminLogin";
import Logger from "../assets/Logger";
import { BayBlock, BayColumn } from "./components/bays";
import BaysHeader from "./components/BaysHeader";
import BaysLegend from "./components/BaysLegend";
import Tools from "./components/Tools";
import { Spinner, SpinnerWrapper } from "../assets";
import SlotTimes from "./components/bays/SlotTimes";
import BayRow from "./components/bays/BayRow";
import { getPublicBookings } from "../actions/publicBookings";
import { VIEWING_DATE_COOKIE } from "../constants";

export default function Admin() {

    const dispatch = useDispatch();
    const viewingDate = useSelector(state => state.time.viewing, (before, after) => before.isSame(after));
    const { settings, ready, user, bays } = useSelector(state => ({
        settings: state.settings,
        ready: state.session.ready,
        user: state.session.user,
        bays: state.bays.items
    }));

    useEffect(() => {
        if (user && user.role.type === "staff") {
            dispatch(getBays());
            dispatch(getSystemUsers());
            dispatch(getClubs());
        }
    }, [dispatch, user]);

    const currentDay = useMemo(() => viewingDate.clone().startOf("day").format("YYYY-MM-DD"), [viewingDate]);
    useEffect(() => {
        let to;
        function getThem() {
            const day = moment(currentDay);
            dispatch(getOrders(day, day.clone().endOf("day")));
            dispatch(getPublicBookings(day.clone().startOf("day"), day.clone().endOf("day")));
        }
        if (user && user.role.type === "staff") {
            to = setTimeout(getThem, 500);
        }
        return () => {
            if (to) clearTimeout(to);
        }
    }, [dispatch, user, currentDay]);

    useInterval(() => {
        function getThem() {
            const day = moment(currentDay);
            dispatch(getPublicBookings(day.clone().startOf("day"), day.clone().endOf("day")));
        }
        if (user && user.role.type === "staff") {
            getThem();
        }
    }, 20000);

    useInterval(() => {
        setTimeout(() => {
            function getThem() {
                const day = moment(currentDay);
                dispatch(getOrders(day, day.clone().endOf("day")));
            }
            if (user && user.role.type === "staff") {
                getThem();
            }
        }, 10000);
    }, 20000);

    // Cookie for viewing date.
    useEffect(() => {
        setCookie({
            key: VIEWING_DATE_COOKIE,
            value: viewingDate.format()
        });
    }, [viewingDate]);

    // We shouldn't render anything if we don't have necessary data.
    if (!ready || !settings.id) return <SpinnerWrapper>
        <Spinner color="#333333" size={150} />
    </SpinnerWrapper>

    // If we get the user, but they aren't staff, render a message.
    if (user && user.role.type !== "staff") return <Redirect to="/booking" />

    return <>
        {!user
            ?
            <AdminLogin />
            :
            <div id="admin-panel">
                <div className="bays">
                    <BaysHeader />
                    <BayRow>
                        <BayBlock>
                            {bays.slice(0, 15).map(bay => (
                                <BayColumn
                                    tooltipSide={"left"}
                                    key={bay.id}
                                    bay={bay}
                                />
                            ))}
                            <SlotTimes />
                            {bays.slice(15, 30).map(bay => (
                                <BayColumn
                                    tooltipSide={"right"}
                                    key={bay.id}
                                    bay={bay}
                                />
                            ))}
                        </BayBlock>
                    </BayRow>
                    <BayRow>
                        <BayBlock>
                            {bays.slice(30, 45).map(bay => (
                                <BayColumn
                                    tooltipSide={"left"}
                                    key={bay.id}
                                    bay={bay}
                                />
                            ))}
                            <SlotTimes />
                            {bays.slice(45, 60).map(bay => (
                                <BayColumn
                                    tooltipSide={"right"}
                                    key={bay.id}
                                    bay={bay}
                                />
                            ))}
                        </BayBlock>
                    </BayRow>
                    <BaysLegend />
                </div>
                <Tools />
            </div>
        }
        <Logger style={
            user ? { left: "calc(50% - 150px)", bottom: "90px" } : { left: "50%", bottom: "20px" }
        } />
    </>
}

