import axios from "axios";
import { API_ROOT, COOKIE_NAME } from "../constants";
import { setCookie, errorResponse } from "../helpers";
import moment from "moment";
import { addLog } from "./logger";
import { addBooking } from "./customerBookings";
// Authentication.
export const LOGIN_REQUEST_START = "LOGIN_REQUEST_START";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAIL = "LOGIN_REQUEST_FAIL";

export const SIGNUP_REQUEST_START = "SIGNUP_REQUEST_START";
export const SIGNUP_REQUEST_SUCCESS = "SIGNUP_REQUEST_SUCCESS";
export const SIGNUP_REQUEST_FAIL = "SIGNUP_REQUEST_FAIL";

export const REQUEST_PASSWORD_RESET_START = "REQUEST_PASSWORD_RESET_START";
export const REQUEST_PASSWORD_RESET_SUCCESS = "REQUEST_PASSWORD_RESET_SUCCESS";
export const REQUEST_PASSWORD_RESET_FAIL = "REQUEST_PASSWORD_RESET_FAIL";

export const PASSWORD_RESET_START = "PASSWORD_RESET_START";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";

export const CURRENT_USER_REQUEST_START = "CURRENT_USER_REQUEST_START";
export const CURRENT_USER_REQUEST_SUCCESS = "CURRENT_USER_REQUEST_SUCCESS";
export const CURRENT_USER_REQUEST_FAIL = "CURRENT_USER_REQUEST_FAIL";

export const SEND_EMAIL_CONFIRMATION_START = "SEND_EMAIL_CONFIRMATION_START";
export const SEND_EMAIL_CONFIRMATION_SUCCESS = "SEND_EMAIL_CONFIRMATION_SUCCESS";
export const SEND_EMAIL_CONFIRMATION_FAIL = "SEND_EMAIL_CONFIRMATION_FAIL"

export const LOGOUT = "LOGOUT";
// Misc
export const SET_SESSION_DATA = "SET_SESSION_DATA";

// For authenticating a user.
export function login({ email, password }) {
	return function (dispatch, getState) {

		dispatch({
			type: LOGIN_REQUEST_START,
			credentials: { email, password }
		});

		// Get any bookings in advance that we'll need to create after successful login.
		const { customerBookings: { items: bookingsToCreate } } = getState();

		axios.post(`${API_ROOT}/auth/local`, {
			identifier: email,
			password,
		}).then(({ data }) => {
			// Success!
			dispatch({
				type: LOGIN_REQUEST_SUCCESS,
				user: data.user,
				token: data.jwt
			});
			// Also set a cookie.
			setCookie({
				key: COOKIE_NAME,
				value: data.jwt,
				path: "/",
				expires: moment().add(1, "month")
			});

			// Create any bookings that we need to create for this user.
			bookingsToCreate.forEach(booking => dispatch(addBooking(booking)));

		}).catch(error => {
			const errorMessage = errorResponse(error);
			dispatch({
				type: LOGIN_REQUEST_FAIL,
				error: errorMessage
			});
			dispatch(addLog({
				type: "error",
				text: errorMessage
			}));
		});

	}
}

export function signup({ email, password, firstName, lastName }) {
	return function (dispatch) {
		dispatch({ type: SIGNUP_REQUEST_START });

		axios.post(`${API_ROOT}/auth/local/register`, {
			email,
			password,
			firstName,
			lastName
		}).then(({ data }) => {
			// Success!
			dispatch({
				type: SIGNUP_REQUEST_SUCCESS,
				user: data.user
			});
			// Also set a cookie.
			setCookie({
				key: COOKIE_NAME,
				value: data.jwt,
				path: "/",
				expires: moment().add(1, "month")
			});
			window._hsq.push(["identify", {
				email: data.user.email
			}]);
		}).catch(error => {
			dispatch({
				type: SIGNUP_REQUEST_FAIL,
				error: errorResponse(error)
			});
		});

	}
}

// Requesting a password reset email.
export function requestPasswordReset({ email }, callback) {
	return function (dispatch) {
		dispatch({ type: REQUEST_PASSWORD_RESET_START });

		axios.post(`${API_ROOT}/auth/forgot-password`, {
			email,
		}).then(({ data }) => {
			// Success!
			dispatch({
				type: REQUEST_PASSWORD_RESET_SUCCESS
			});
			if (callback) callback();
		}).catch(error => {
			dispatch({
				type: REQUEST_PASSWORD_RESET_FAIL,
				error: errorResponse(error)
			});
		});

	}
}

// Resetting the password using the passed code.
export function passwordReset({ code, password }, callback) {
	return function (dispatch) {
		dispatch({ type: PASSWORD_RESET_START });

		axios.post(`${API_ROOT}/auth/reset-password`, {
			code,
			password,
			passwordConfirmation: password,
		}).then(res => res.data).then(data => {
			// Check the user is actually validated.
			if (!data.user.confirmed) {
				let error = "Your password was reset, but your email address has never been confirmed. Go find your welcome email and click the link."
				dispatch({
					type: PASSWORD_RESET_FAIL,
					error
				});
				if (callback) callback(error);
				return;
			}
			// Successfully resetting the password can log them in.
			dispatch({
				type: PASSWORD_RESET_SUCCESS
			});
			if (callback) callback(null);
		}).catch(error => {
			dispatch({
				type: PASSWORD_RESET_FAIL,
				error: errorResponse(error)
			});
		});
	}
}

export function requestCurrentUser() {
	return function (dispatch, getState) {
		// Trigger the start.
		dispatch({
			type: CURRENT_USER_REQUEST_START
		});
		// Get the current token.
		const { session: { token } } = getState();
		// If there isn't one, we can exit early.
		if (!token) return dispatch({
			type: CURRENT_USER_REQUEST_FAIL
		});

		axios.get(`${API_ROOT}/users/me`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).then(res => res.data).then(user => {
			dispatch({
				type: CURRENT_USER_REQUEST_SUCCESS,
				user
			});
		}).catch(error => {
			setCookie({
				key: COOKIE_NAME,
				path: "/",
				value: ""
			});
			dispatch({
				type: CURRENT_USER_REQUEST_FAIL,
				error: errorResponse(error)
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(error)
			}));
		})
	}
}

export function sendEmailConfirmation(email, cb) {
	return function (dispatch, getState) {
		// Trigger the start
		dispatch({
			type: SEND_EMAIL_CONFIRMATION_START
		});

		axios.post(`${API_ROOT}/auth/send-email-confirmation`, {
			email,
		}).then(res => {
			// Handle success.
			dispatch({
				type: SEND_EMAIL_CONFIRMATION_SUCCESS
			})
			if (cb) cb();
		}).catch(error => {
			dispatch({
				type: SEND_EMAIL_CONFIRMATION_FAIL,
				error: errorResponse(error)
            })
            dispatch(addLog({
				type: "error",
				text: errorResponse(error)
			}));
		});
	}
}

export function logout() {
	return function (dispatch, getState) {
		setCookie({
			key: COOKIE_NAME,
			path: "/",
			value: ""
		});

		// // Get any current orders from state.
		// const { customerBookings: { items: currentOrders } } = getState();
		// // If there were any, attempt to remove them.
		// if (currentOrders.length)
		// 	currentOrders.forEach(booking => dispatch(removeBooking(booking)));
		// Finally dispatch the logout event.
		dispatch({
			type: LOGOUT
		});
	}
}

// For setting general session data.
export function setSessionData(data) {
	return {
		type: SET_SESSION_DATA,
		data
	}
};