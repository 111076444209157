import React, { useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "../../actions/orders";
import { setSearchFilter, setSearchPage, setSearchDate } from "../../actions/ordersLookup";
import { Button, DateField } from "../../fields";
import { Pagination, Spinner, ImgIcon } from "../../assets";
import { check } from "../../icons";
import { useModal } from "../../assets/useModal";
import BookingEditor from "./BookingEditor";

export default function OrdersLookup() {

    const dispatch = useDispatch();
    const { orders, loading, filter, page, date } = useSelector(state => ({
        orders: state.orders.items,
        loading: state.orders.getLoading,
        filter: state.ordersLookup.filter,
        page: state.ordersLookup.page,
        date: state.ordersLookup.date
    }));

    const { setModal } = useModal();
    const limit = 25; // Number of orders per page

    useEffect(() => {
        dispatch(getOrders(date.from, date.to))
    }, [dispatch, date]);

    const filteredOrders = orders
        .filter(o => {
            return o.bookings.filter(
                b => moment(b.date).isSameOrAfter(date.from) && moment(b.date).isSameOrBefore(date.to)
            ).length;
        })
        .filter(o => {
            if (!filter) return o;
            const lowercaseSearch = filter.toLowerCase();
            return o.user.email.toLowerCase().includes(lowercaseSearch) ||
                `${o.user.firstName} ${o.user.lastName}`.toLowerCase().includes(lowercaseSearch) ||
                o.id === Number(filter);
        })
        .sort((a, b) => b.id - a.id);

    return <div className={"orders-lookup-admin" + (filteredOrders.length ? " with-pagination" : "")}>
        <h2 style={{ margin: "0px 0px 10px 0px" }}>
            <span className="align-center"><b>Order</b> Lookup</span>
            <div className={`syncing align-center ${loading ? " active" : ""}`}>
                {loading
                    ?
                    <>
                        <div className="align-center spinner-wrapper">
                            <Spinner color="#ffffff" size={22} />
                        </div>
                        <span className="align-center">Syncing orders</span>
                    </>
                    :
                    <>
                        <ImgIcon src={check} size={22} className="align-center" />
                        <span className="align-center">Orders synced</span>
                    </>
                }
            </div>
        </h2>
        <hr />
        <div className="search-field">
            <input
                type="text"
                placeholder="search..."
                value={filter}
                autoFocus={true}
                onFocus={e => e.target.select()}
                onChange={e => {
                    // Setting a search query
                    dispatch(setSearchFilter(e.target.value));
                    // Going back to page 1
                    dispatch(setSearchPage(1));
                }}
            />
            <div className="date-changer flexy">
                <DateField
                    style={{ marginRight: "10px" }}
                    label="From"
                    value={date.from}
                    onChange={val => {
                        // Add some check to make sure from is before two
                        let newDate = { ...date, from: val.startOf('day') };
                        dispatch(setSearchDate(newDate))
                    }}
                />
                <DateField
                    label="To"
                    value={date.to}
                    onChange={val => {
                        // Check to make sure from is after to
                        let newDate = { ...date, to: val.endOf('day') };
                        dispatch(setSearchDate(newDate))
                    }}
                />
            </div>
        </div>
        <div className="orders-list">
            {filteredOrders.length
                ?
                filteredOrders
                    .slice((page - 1) * limit, (page) * limit)
                    .map(o => (
                        <div className="order" key={o.id}>
                            <div className="user">
                                <div className="align-center">
                                    <b>#{o.id}</b> - {o.user.firstName} {o.user.lastName} - {o.user.email}
                                </div>
                                <Button
                                    className="align-center"
                                    onClick={() => {
                                        setModal(<BookingEditor orderId={o.id} />);
                                    }}
                                >
                                    View order
                                </Button>
                            </div>
                            <div className="bookings">
                                {o.bookings
                                    .filter(b => moment(b.date).isSameOrAfter(date.from) && moment(b.date).isSameOrBefore(date.to))
                                    .map(b => (
                                        <div className="flexy" key={b.id}>
                                            <div><b>Date: </b>{moment(b.date).format("Do-MMMM-YYYY")}</div>
                                            <div><b>Slot: </b>{b.slot.from.slice(0, 5)} - {b.slot.to.slice(0, 5)}</div>
                                            <div><b>Bay: </b>{b.bay}</div>
                                            <div><b>Status: </b>{b.status}</div>
                                            <div>
                                                <b>Extras: </b>
                                                {!Boolean(b.extras.length)
                                                    ?
                                                    "None"
                                                    :
                                                    b.extras.map(extra => (
                                                        <span
                                                            style={{ paddingLeft: "5px" }}
                                                            key={extra.id}
                                                        >
                                                            {extra.club.name} x{extra.quantity}
                                                        </span>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ))
                :
                <div className="no-orders text-center">
                    <br />
                    <div>No orders matching your search criteria.</div>
                    <br />
                </div>
            }
        </div>
        {Boolean(filteredOrders.length) && <div className="text-center pagination-wrapper">
            <Pagination
                page={page}
                totalPages={Math.ceil(filteredOrders.length / limit)}
                onPageChange={newPage => dispatch(setSearchPage(newPage))}
            />
        </div>}
    </div>

}