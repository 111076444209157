import React, { useState } from "react";
import Field from "./Field";
import { validateEmail } from "./helpers";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../fields"; 
import { requestPasswordReset } from "../../actions/session";

export default function ResetPasswordRequest() {

	const { loading, loginError } = useSelector(state => state.session);
	const [values, setValues] = useState({});
	const [fieldErrors, setFieldErrors] = useState({});
	const [requestMade, setRequestMade] = useState(false);
	const dispatch = useDispatch();

	function fieldChange({ name, value, error }) {
		setValues({
			...values,
			[name]: value
		});
		setFieldErrors({
			...fieldErrors,
			[name]: error
		});
	}

	if (requestMade) return <div>
		<h4>Success</h4>
		<br/>
		<div>
			Check your emails for your reset password link.
		</div>
	</div>

	return <form onSubmit={e => {
		e.preventDefault();
		if (Object.values(fieldErrors).filter(e => e).length) return;
		dispatch(requestPasswordReset(values, () => {
			setRequestMade(true);
		}));
	}}>
		{/* <h4>Request a password reset</h4>
		<br />
		<div>
			Enter the email you use to log in, and we'll send you a reset link.
			<br /><br />
		</div> */}
		<Field
			onChange={fieldChange}
			name="email"
			values={values}
			errors={fieldErrors}
			autoComplete="email"
			placeholder="Email"
			validate={validateEmail}
		/>
		<Button
            loading={loading}
			type="submit"
			disabled={loading || Object.values(fieldErrors).filter(e => e).length}
		>Request reset</Button>
		{loginError && <>
			<br />
			<div className="error text-center">
				{loginError}
			</div>
		</>}
	</form>
}