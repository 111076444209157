import { initialState } from ".";
import { 
    GET_REPORT_DATA_START, 
    GET_REPORT_DATA_SUCCESS, 
    GET_REPORT_DATA_FAIL 
} from "../actions/reportData";

export default function (state = initialState.reportData, action) {

    switch(action.type) {
        case GET_REPORT_DATA_START:
            return {
                ...state,
                loading: true
            };

        case GET_REPORT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.data
            }
        
            case GET_REPORT_DATA_FAIL:
                return {
                    ...state,
                    loading: false
                }

        default:
            return state
    }

}