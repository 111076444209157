import React, { useState, useContext, createContext, useCallback } from "react";
import { close } from "../icons";
import { PaddedIcon } from "./"
import { CSSTransition } from "react-transition-group";
import { useEffect } from "react";
import { usePrevious } from "../helpers";
// import { useHistory } from "react-router-dom";

const ModalContext = createContext();

function Modal({ open, closeModal, content, closable, className }) {

	const [validDownTarget, setValidDownTarget] = useState(false);

	useEffect(() => {
		function onKeyUp(e) {
			if (open && e.key === "Escape") closeModal();
		}
		if (open) document.addEventListener("keyup", onKeyUp);
		return () => {
			document.removeEventListener("keyup", onKeyUp);
		}
	}, [open, closeModal]);

	return <CSSTransition
		key={"modal"}
		in={open}
		timeout={500}
		classNames="modal"
	>
		<div
			className={`modal-wrapper modal-wrapper-${open ? "open modal-enter-done" : "closed modal-exit-done"} ${className ? className : ""}`}
		>
            <div 
                className="close-on-click"
                onMouseDown={e => {
                    e.stopPropagation();
                    setValidDownTarget(true);
                }}
                onMouseUp={e => {
                    e.stopPropagation();
                    if (validDownTarget && open && closable) closeModal();
                    setValidDownTarget(false);
                }}
            >
                <div
                    className="modal-inner"
                    onMouseDown={e => e.stopPropagation()}
                    onMouseUp={e => {
                        e.stopPropagation();
                        setValidDownTarget(false);
                    }}
                >
                    {content}
                    {closable &&
                        <PaddedIcon
                            size={15}
                            cursor={"pointer"}
                            style={{
                                backgroundColor: "#d3d3d3",
                                padding: "5px",
                                zIndex: 1
                            }}
                            onClick={e => {
                                closeModal();
                            }}
                            className="close-modal noSelect"
                            src={close}
                        />}
                </div>
            </div>
		</div>
	</CSSTransition>
}

export const ModalProvider = ({ children }) => {

	// const history = useHistory();
	const [content, setContent] = useState("");
	const [config, setConfig] = useState({
		closable: true,
		clearOnClose: false
	});
	const [open, setOpen] = useState(false);
	const wasOpen = usePrevious(open);

	const setModal = useCallback((newContent, newConfig) => {
		setContent(typeof newContent === "function" ? newContent() : newContent);
		if (newConfig) setConfig({ ...newConfig });
	}, [setContent, setConfig]);

	const openModal = useCallback(() => {
		// if (window.history && window.history.pushState) {
		// 	window.history.pushState({}, "", window.location.href);
		// }
		document.querySelector("body").style.overflow = "hidden";
		setOpen(true);
	}, [setOpen]);

	const closeModal = useCallback(() => {
		document.querySelector("body").style.overflow = "auto";
		setOpen(false);
	}, [setOpen]);

	useEffect(() => {
		if (!open && wasOpen && config.clearOnClose) setTimeout(() => {
			setModal(null);
		}, 200);
	}, [open, setModal, wasOpen, config.clearOnClose])

	// history.listen((location, action) => {
	// 	if (action === "POP" && open) {
	// 		closeModal();
	// 	}
	// });

	return <ModalContext.Provider value={{
		setModal,
		openModal,
		closeModal,
		toggleModal: () => {
			if (open) {
				closeModal();
			} else {
				openModal();
			}
		},
		modalOpen: open
	}}>
		{children}
		<Modal
			open={open}
			openModal={openModal}
			closeModal={closeModal}
			content={content}
			{...config}
		/>
	</ModalContext.Provider>

};

export const useModal = () => useContext(ModalContext);