import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setViewingTime } from "../../actions/time";
import { roundToSlot } from "../../helpers";
import { golfer, chevron } from "../../icons";
import { ImgIcon } from "../../assets"
import DateSelector from "./DateSelector";

export default function BaysHeader() {

    const dispatch = useDispatch();
    const { current, viewing } = useSelector(state => state.time);

    return <header className="flexy">
        <div className="time-display">
            <img src={golfer} className="golfer" alt="golf" />
            <div className="currentTime">
                <h2 className="pointer" onClick={() => dispatch(setViewingTime(roundToSlot(current)))}>
                    {current.format("HH:mm")}
                </h2>
                <h3><b>{timeTillSession(current)} minutes</b> till next session.</h3>
            </div>
        </div>
        <div className="time-selector">
            <div
                className="arrow back shadow"
                onClick={() => dispatch(setViewingTime(viewing.clone().subtract(30, 'm')))}
            >
                <ImgIcon size={25} src={chevron} cursor="pointer" />
            </div>
            <div className="time">{viewing.format("HH:mm")}</div>
            <div
                className="arrow forward shadow"
                onClick={() => dispatch(setViewingTime(viewing.clone().add(30, 'm')))}
            >
                <ImgIcon size={25} src={chevron} cursor="pointer" />
            </div>
        </div>
        <DateSelector />
    </header>
}

function timeTillSession(time) {
    let minutes = time.minutes();
    return minutes >= 30 ? 60 - minutes : 30 - minutes
}