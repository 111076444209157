import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBays } from "../../../../actions/bays";
import { addBooking, ping } from "../../../../actions/customerBookings";
import { asPage } from "../Page";
import BayGraphic from "../../../../assets/BayGraphic";
import { Spinner, SpinnerWrapper } from "../../../../assets";
import DateSelector from "../../DateSelector";
// import TimeSelector from "../../TimeSelector";
// import TimeSelectorHorizontal from "../../TimeSelectorHorizontal";
import BookingsList from "../../BookingsList";
import { useModal } from "../../../../assets/useModal";
import { eye } from "../../../../icons";
import { groupBookings } from "../../../../helpers";
import QuestionSet from "./QuestionSet";
import { useMemo } from "react";
import { MAX_PEOPLE_PER_SESSION } from "../../../../constants";
import { useState } from "react";
import { useCallback } from "react";

function Bays() {

	const history = useHistory();
	const [creatingBookings, setCreatingBookings] = useState(false);

	// const { setModal, openModal } = useModal();
	const dispatch = useDispatch();
	const { viewing, bays, clubs, baysLoading } = useSelector(state => ({
		viewing: state.time.viewing,
		bookings: state.customerBookings.items,
		bays: state.bays.items,
		clubs: state.clubs.items,
		baysLoading: state.bays.loading
	}));

	// Create a day string, and a slot object from the viewing date.
	const { slot, day } = useMemo(() => ({
		slot: {
			date: viewing.clone(),
			from: viewing.format("HH:mm[:00.000]"),
			to: viewing.clone().add(30, "m").format("HH:mm[:00.000]")
		},
		day: viewing.format("YYYY-MM-DD")
	}), [viewing]);

	// Get bays on mount.
	useEffect(() => {
		dispatch(getBays());
	}, [dispatch]);

	// Takes the answers from the questions and creates a bookings.
	async function createBookingsFromAnswers(values) {
		const bookings = [];
		const {
			players: { adults = 0, children = 0 },
			duration: { slots = 2 },
			clubs: { selected: selectedClubs }
		} = values;
		function incrementSlot(slot, steps = 1) {
			const date = slot.date.clone().add(30 * steps, "minutes");
			return {
				from: date.format("HH:mm[:00.000]"),
				to: date.clone().add(30, "m").format("HH:mm[:00.000]")
			}
		}
		// Create an array of people.
		const ADULT = 1;
		const CHILD = 2;
		let people = [
			...[...new Array(adults)].map(() => ADULT),
			...[...new Array(children)].map(() => CHILD)
		].sort(() => Math.random() - Math.random());
		// Iterate each bay.
		values.bays.selected.forEach((bayNumber, index) => {
			// Splice off some people for this bay.
			const peopleForBay = people.splice(0, MAX_PEOPLE_PER_SESSION);
			for (let i = 0; i < slots; i++) {
				// Create the booking.
				bookings.push({
					date: day,
					slot: incrementSlot(slot, i),
					bay: bays.find(b => b.number === bayNumber),
					status: "tentative",
					// If it's the first booking of the set, add the clubs.
					extras: (index === 0 && i === 0) ? selectedClubs.map(item => ({
						...item,
						club: clubs.find(club => club.id === item.club),
						__component: "extra.club",
					})) : [],
					adults: peopleForBay.filter(p => p === ADULT).length,
					children: peopleForBay.filter(p => p === CHILD).length,
				});
			}
		});

		// Add them.
		for (let i = 0; i < bookings.length; i++) {
			await new Promise((resolve, reject) => {
				dispatch(addBooking(bookings[i], err => {
					if (err) {
						reject(err);
					} else {
						resolve(err);
					}
				}));
			});
		}
		// All done.
		return;
	}

	// We shouldn't render anything if we don't have necessary data.
	if (baysLoading) return <SpinnerWrapper className="full-content-height"><Spinner color="#333333" size={150} /></SpinnerWrapper>

	return <div className="bays text-center maximum-width">
		<h2><b>When</b> are you playing?</h2>
		<h3 style={{ paddingBottom: 10 }}>
			Use the date selector in the middle of the page to pick the time you want to play.
		</h3>
		<Legend />
		<div className="baySelection">
			<BayGraphic />
			<DateSelector />
		</div>
		{creatingBookings ?
			<Spinner color="#333333" size={50} />
			:
			<QuestionSet
				onComplete={async values => {
					setCreatingBookings(true);
					try {
						await createBookingsFromAnswers(values);
						history.push("/booking/checkout");
					} catch (error) {
						setCreatingBookings(false);
					}
				}}
			/>
		}
	</div>
};

function Footer() {

	const dispatch = useDispatch();
	const { bookings, user } = useSelector(state => ({
		// viewing: state.time.viewing,
		bookings: state.customerBookings.items,
		user: state.session.user
	}));
	const { setModal, openModal } = useModal();

	// Handle validation errors in bookings.
	const calculateErrors = useCallback(function () {
		// Group up the bookings so we can check session lengths.
		const sessions = groupBookings(bookings);
		// Generate the errors array.
		return [
			sessions.find(session => session.bookings.length < 2) ?
				"Bookings on the same bay must be at least an hour. Add another slot." : (
					sessions.find(session => session.bookings.length % 2 !== 0) ?
						"All bookings on the same bay have to be in increments of an hour. Add another slot." : null
				),
		].filter(Boolean);
	}, [bookings]);

	const [errors, setErrors] = useState(calculateErrors());
	const [thinking, setThinking] = useState(false);

	useEffect(() => {
		let newErrors = calculateErrors();
		let to;
		setThinking(true);
		to = setTimeout(() => {
			if (newErrors.join("") !== errors.join()) {
				setErrors(newErrors);
			}
			setThinking(false);
		}, 500);
		return () => {
			if (to) clearTimeout(to);
		}
	}, [calculateErrors, errors]);

	const canProceed = Boolean(bookings.length && !errors.length);

	if (thinking) {
		return <div style={{
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)"
		}}>
			<Spinner size={50} />
		</div>
	}

	return (
		<div
			className={[
				"inner",
				"text-center",
				errors.length && "errored"
			].filter(Boolean).join(" ")}
			style={{ paddingRight: canProceed ? "15vh" : "0" }}
		>
			{/* If not errored, show the summary. Otherwise show the footer in error state, and list the errors. */}
			<div
				className={`summary pointer ${errors.length ? "errors" : ""}`}
				onClick={e => {
					setModal(<BookingsList />, { className: "", closable: true, clearOnClose: true });
					openModal();
				}}
			>
				{!errors.length ?
					<>
						<span className="align-center">
                            {Boolean(bookings.length)
                                ?
                                    `${bookings.length} slot${bookings.length === 1 ? "" : "s"} selected`
                                :
                                    ""
                            }
                        </span>
						{canProceed && <div className="round-btn text-center align-center">
							<img src={eye} alt="eye icon" />
							<div><b>VIEW</b></div>
						</div>}
					</>
					:
					<>
						{errors.join(" ")} <b>Click for booking summary.</b>
					</>
				}
			</div>

			{canProceed &&
				<Link className="next-stage-btn" to="/booking/checkout" onClick={() => {
					// If user logged in ping bookings
					if (Boolean(user)) dispatch(ping());
				}}>NEXT</Link>
			}
		</div>
	)
};

function DummyBay({ status, isEntertainment = false }) {
	const colorClasses = {
		booked: "bookedColor-bg",
		available: isEntertainment ? "availableColorEntertainment-bg" : "bayColor-bg",
		selected: "selectedColor-bg",
		disabled: "nullColor-bg",
	}
	return <div className={`dummyBay ${colorClasses[status] || ""}`}>6</div>
}

function Legend() {

	const customerBookings = useSelector(state => state.customerBookings.items);

	return <div className="booking-legend">
		<hr />
		<div className="flexy bay-types">
			<div>
				<span>Standard</span>
				<DummyBay status="available" />
			</div>
			<div>
				<span>Entertainment</span>
				<DummyBay status="available" isEntertainment={true} />
			</div>
			{Boolean(customerBookings.length) && <div>
				<span>Selected</span>
				<DummyBay status="selected" />
			</div>}
			<div>
				<span>Unavailable</span>
				<DummyBay status="booked" isEntertainment={true} />
				<DummyBay status="disabled" isEntertainment={true} />
			</div>
		</div>
		<hr />
	</div>
}

export default asPage(Bays, Footer);