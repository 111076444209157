import axios from "axios";
import { API_ROOT } from "../constants";
import { addLog } from "./logger";

export const GET_CLUBS_START = "GET_CLUBS_START";
export const GET_CLUBS_SUCCESS = "GET_CLUBS_SUCCESS";
export const GET_CLUBS_FAIL = "GET_CLUBS_FAIL";

export function getClubs() {
    return function (dispatch, getState) {

        dispatch({
            type: GET_CLUBS_START
        });

        // Get the current token
        const { session: { token } } = getState();

        axios.get(`${API_ROOT}/clubs`, {
            headers: token ? {
                Authorization: `Bearer ${token}`
            } : {}
        }).then(res => res.data).then(clubs => {
            dispatch({
                type: GET_CLUBS_SUCCESS,
                clubs
            });
        }).catch(err => {
            console.error("Error getting clubs: ", err);
            dispatch({
                type: GET_CLUBS_FAIL
            })
            dispatch(addLog({
                type: "error",
                text: "Error getting clubs."
            }));
        })
    }
}