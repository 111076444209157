import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../fields";
import { editBooking, editOrder, refundOrder } from "../../actions/orders";
import { canRefund } from "../../helpers";
import { useModal } from "../../assets/useModal";
import OrdersLookup from "./OrdersLookup";

export default function BookingEditor({ orderId }) {

    const dispatch = useDispatch();
    const { order } = useSelector(state => ({
        order: state.orders.items.find(o => o.id === orderId)
    }));

    const { setModal } = useModal();

    const isLoading = order && order.loading;
    const buttonStyles = { width: "131px", padding: "5px 15px" };

    return <div className="booking-editor-admin">
        <Button
            className="back-to-lookup"
            onClick={() => {
                setModal(<OrdersLookup />)
            }}
        >
            Order Lookup
        </Button>
        <h2 style={{ margin: "0px 0px 10px 0px" }}>Order Details</h2>
        <div className="field-combiner">
            {Boolean(order.id) && <div style={{ flex: 5 }}>
                <b>Id</b>
                <div>#{order.id}</div>
            </div>}
            {Boolean(order.transactionId) && <div>
                <b>Braintree ID</b>
                <div>#{order.transactionId}</div>
            </div>}
            {Boolean(order.user.firstName && order.user.lastName) && <div>
                <b>Customer Name</b>
                <div>{order.user.firstName} {order.user.lastName}</div>
            </div>}
            {Boolean(order.user.email) && <div style={{ flex: 20 }}>
                <b>Customer Email</b>
                <div>{order.user.email}</div>
            </div>}
            {Boolean(order.user.telephone) && <div>
                <b>Telephone</b>
                <div>{order.user.telephone}</div>
            </div>}
            <div style={{ flex: 5 }}>
                <b>Adults</b>
                <div>x {order.bookings[0].adults}</div>
            </div>
            <div style={{ flex: 5 }}>
                <b>Children</b>
                <div>x {order.bookings[0].children}</div>
            </div>
        </div>
        <hr />
        {Boolean(order.createdBy) && <>
            <h2 style={{ margin: "0px 0px 10px 0px" }}>Created By</h2>
            <div className="field-combiner">
                {Boolean(order.createdBy.firstName && order.createdBy.lastName) && <div>
                    <b>Name</b>
                    <div>{order.createdBy.firstName} {order.createdBy.lastName}</div>
                </div>}
                {Boolean(order.createdBy.email) && <div>
                    <b>Email</b>
                    <div>{order.createdBy.email}</div>
                </div>}
                <div></div>
                <div></div>
                <div></div>
            </div>
            <hr />
        </>}
        <h2 style={{ margin: "0px 0px 10px 0px" }}>{`Booking${order.bookings.length > 1 ? "s" : ""}:`}</h2>
        <div className="flexy" style={{ marginBottom: "10px" }}>
            <div><b>Date</b></div>
            <div><b>Slot</b></div>
            <div><b>Bay</b></div>
            <div><b>Status</b></div>
            <div><b>Extras</b></div>
        </div>
        {order.bookings.map((b, i) => {
            let showButtons = b.status !== "cancelled";
            return (
                <div style={(i + 1) === order.bookings.length ? {} : { paddingBottom: "20px" }} key={b.id}>
                    <div className="flexy" style={{ paddingBottom: "5px" }}>
                        <div>{moment(b.date).format("Do-MMMM-YYYY")}</div>
                        <div>{`${b.slot.from.slice(0, 5)} - ${b.slot.to.slice(0, 5)}`}</div>
                        <div>{b.bay}</div>
                        <div>{b.status}</div>
                        <div>
                            {!Boolean(b.extras.length)
                                ?
                                <div>None</div>
                                :
                                <div>
                                    {b.extras.map(extra => (
                                        <div key={extra.id}>{`${extra.club.name} club x ${extra.quantity}`}</div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        {showButtons && <Button
                            className="align-center"
                            disabled={isLoading}
                            // loading={isLoading}
                            style={{ ...buttonStyles, backgroundColor: "#ff0031", marginRight: "10px" }}
                            onClick={() => dispatch(editBooking(b.order, b.id, { status: "cancelled" }))}
                        >Cancel Booking</Button>}
                        {showButtons && <Button
                            className="align-center"
                            disabled={isLoading}
                            // loading={isLoading}
                            style={{ ...buttonStyles, backgroundColor: b.attended ? "#ff0031" : "#00d355", width: "141px" }}
                            onClick={() => dispatch(editBooking(b.order, b.id, { attended: !b.attended }))}
                        >{b.attended ? "Mark Unattended" : "Mark Attended"}</Button>}
                    </div>
                </div>
            )
        })}
        <hr />
        <div>
            <h2 style={{ marginBottom: "9px" }}>
                Total Price: <b style={{ paddingLeft: "5px" }}>{`£${totalPrice(order.lineItems) / 100}`}</b>
                <b style={{ paddingLeft: "3px", color: order.refunded ? "#ff0031" : (order.paid ? "#00d355" : "#ff0031") }}>
                    {`${order.refunded ? "(REFUNDED)" : (order.paid ? "(PAID)" : "(UNPAID)")}`}
                </b>
            </h2>
        </div>
        <div>
            <Button
                disabled={isLoading}
                className="align-center"
                style={{ ...buttonStyles, backgroundColor: order.paid ? "#ff0031" : "#00d355" }}
                onClick={() => { dispatch(editOrder(order.id, { paid: !order.paid })) }}
            >{order.paid ? "Mark Unpaid" : "Mark Paid"}</Button>
            {(order.transactionId && canRefund(order.bookings) && !order.refunded) && <Button
                disabled={isLoading}
                className="align-center"
                style={{ ...buttonStyles, backgroundColor: "#ff0031", marginLeft: "10px" }}
                onClick={() => {
                    if (window.confirm("Are you sure you want to refund this order?"))
                        dispatch(refundOrder(order.id))
                }}
            >
                Refund Order
            </Button>}
        </div>
    </div>
}

function totalPrice(lineItems = []) {
    let total = 0;
    lineItems.forEach(item => total += (item.price * item.quantity));
    return total;
}