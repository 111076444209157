import React, { useState } from "react";
import { ImgIcon } from ".";
import { logo } from "../icons";
import { Button } from "../fields";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../actions/session";

export default function AdminLogin() {

    const [values, setValues] = useState({ email: "", password: "" });
    const { loading } = useSelector(state => state.session);
    const dispatch = useDispatch();

    function submit(e) {
        e.preventDefault();
        if (loading) return;
        dispatch(login(values))
    }

    function onChange(e) {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    }

    return (
        <div className="user-login">
            <div className="login-box">
                <ImgIcon src={logo} size={150} />
                <br /><br />
                <form onSubmit={submit}>
                    <input
                        className="rounded"
                        type="text"
                        value={values.username}
                        name="email"
                        onChange={onChange}
                        placeholder="Email"
                        style={{
                            marginBottom: "5px"
                        }}
                    />
                    <input
                        className="rounded"
                        value={values.password}
                        name="password"
                        onChange={onChange}
                        placeholder="Password"
                        type="password"
                        style={{
                            marginBottom: "10px"
                        }}
                    />
                    <Button
                        type="submit"
                        loading={loading}
                        disabled={!values.password || !values.email}
                    >Login</Button>
                </form>
            </div>
        </div>
    )
}