import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ImgIcon } from "../assets";
import { logo } from "../icons";
import EmailLogin from "../assets/login/EmailLogin";
import "./style.scss";

export default function ConfirmedEmail() {

    const { user } = useSelector(state => state.session);
    const isLogged = Boolean(user);

    return <div className="confirmed-email">
        <div className="inner-confirm">
            <ImgIcon src={logo} size={150} />
            <br /><br />
            <h3>{isLogged ? "Success!" : "Thank You!"}</h3>
            <br />
            <div>{isLogged 
                ? "You can no go to:"
                : "Your email was confirmed, you can now log in:"}
            </div>
            <br />
            {isLogged
                ?
                <div className="flexy">
                    <Link className="link-btn orders" to="/booking/my-orders">MY ORDERS</Link>
                    <Link className="link-btn bays" to="/booking/bays">BOOK A BAY</Link>
                </div>
                :
                <div className="login">
                    <EmailLogin />
                </div>
            }
        </div>
    </div>

}