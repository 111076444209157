import { combineReducers } from "redux";
import initialState from "./initialState";
// Reducer imports.
import session from "./session";
import utils from "./utils";
import time from "./time";
import slots from "./slots";
import clubs from "./clubs";
import bays from "./bays";
import ordersLookup from "./ordersLookup";
import newOrder from "./newOrder";
import orders from "./orders";
import reportData from "./reportData";
import myOrders from "./myOrders";
import users from "./users";
import logger from "./logger";
import settings from "./settings";
import publicBookings from "./publicBookings";
import customerBookings from "./customerBookings";

// Export out the starting state.
export { initialState };

export default combineReducers({
    session,
    utils,
    time,
    slots,
    clubs,
    bays,
    ordersLookup,
    newOrder,
    orders,
    myOrders,
    reportData,
    users,
	logger,
    settings,
	customerBookings,
	publicBookings
});