import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser, removeUser } from "../../actions/newOrder";
import { ImgIcon, PaddedIcon } from "../../assets";
import { UserLookupField, CheckboxField } from "../../fields";
import { useModal } from "../../assets/useModal";
import { plus, close } from "../../icons";
import UserCreator from "./UserCreator";

export default function UserSection() {

	const dispatch = useDispatch();
	const { openModal, setModal } = useModal();
	const { orderUser, allUsers } = useSelector(state => ({
		orderUser: state.newOrder.user,
		allUsers: state.users.items
	}));

	let venueUser = findSystemUser(allUsers, "Venue");
	let anonymousUser = findSystemUser(allUsers, "Anonymous");

	return (
		<div className="user">
			<b>Customer details</b>
			{Boolean(orderUser && !orderUser.isSystemUser)
				?
				<div className="selected-user">
					{Boolean(orderUser.firstName) && <div>{orderUser.firstName} {orderUser.lastName}</div>}
					<span title={orderUser.email}>{orderUser.email}</span>
					<PaddedIcon
						cursor="pointer"
						src={close}
						size={15}
						style={{
							backgroundColor: "#4d4d4d",
							padding: "5px"
						}}
						onClick={() => dispatch(removeUser())}
					/>
				</div>
				:
				<div className="user-selector">
					<div
						className="create-user rounded pointer space-between"
						onClick={() => {
							setModal(
								<UserCreator />
							);
							openModal();
						}}
					>
						<span>Create Customer</span>
						<ImgIcon src={plus} size={22} />
					</div>
					<UserLookupField onChange={user => dispatch(setUser(user))} />
					<div className="system-user flexy">
						<CheckboxField
							label={<span>Proceed as <b>Anonymous</b></span>}
							value={anonymousUser && orderUser && anonymousUser.username === orderUser.username}
							onChange={val => {
								val ? dispatch(setUser(anonymousUser)) : dispatch(removeUser())
							}}
						/>
						<CheckboxField
							label={<span>Proceed as <b>Venue</b></span>}
							value={venueUser && orderUser && venueUser.username === orderUser.username}
							onChange={val => {
								val ? dispatch(setUser(venueUser)) : dispatch(removeUser())
							}}
						/>
					</div>
				</div>
			}
		</div>
	)
}

function findSystemUser(users, username) {
	return users.find(u => u.isSystemUser && u.username === username)
}

