import React from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

export default function BayBlock({ children }) {

    const blockWrapper = useRef();

    return <div className="bay-block" ref={blockWrapper}>
        {children}
        <TimeLine blockWrapper={blockWrapper} />
    </div>
}

function TimeLine({ blockWrapper }) {

    const [timeLinePosition, setTimeLinePosition] = useState(0);

    const { current, viewing } = useSelector(state => ({
        current: state.time.current,
        viewing: state.time.viewing
    }));

    useEffect(() => {
        function calculateTimeLinePosition() {
            // Try and find a reference to the slots container for height.
            if (!blockWrapper.current) return null;
            const container = blockWrapper.current.querySelector(".slots");
            if (!container) return null;
            // Calculate as a fraction the time passed through the last 2 hour block.
            const secondsPassed = current.diff(viewing, "seconds");
            const twoHours = (60 * 60 * 2);
            if (secondsPassed > twoHours || secondsPassed < 0) return null;
            // Return the amount of pixels through.
            return container.offsetHeight - (container.offsetHeight * (secondsPassed / twoHours));
        }
        setTimeLinePosition(calculateTimeLinePosition());
    }, [current, viewing, blockWrapper]);

    return timeLinePosition ? <span className="time-line" style={{ bottom: timeLinePosition }} /> : null;
}
