import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { asPage } from "./Page";
import GroupSummary from "../GroupSummary";
import { calculateOrderTotal, groupBookings, errorResponse, checkIfTempBookings } from "../../../helpers";
import Login from "../../../assets/login";
import { Spinner, SpinnerWrapper } from "../../../assets";
import axios from "axios";
import { API_ROOT } from "../../../constants";
import DropIn from "braintree-web-drop-in-react";
import { Button } from "../../../fields";
import { checkout, loyaltyCheckout, ping } from "../../../actions/customerBookings";
import { addLog } from "../../../actions/logger";

function Checkout() {

	const dispatch = useDispatch();
	const history = useHistory();
	const [braintreeToken, setBraintreeToken] = useState(null);
	const [braintreeInstance, setBraintreeInstance] = useState(null);
	const [error, setError] = useState(null);

	const { user, bookings, settings, sessionToken, loading } = useSelector(state => ({
		user: state.session.user,
		sessionToken: state.session.token,
		bookings: state.customerBookings.items,
		settings: state.settings,
		loading: state.customerBookings.loading
	}));

	const bookingGroups = groupBookings(bookings);

	// If the user logs in, get the braintreeToken.
	useEffect(() => {
		if (sessionToken && user && !user.hasLoyaltyCard && !braintreeToken) getBraintreeToken(sessionToken)
			.then(setBraintreeToken)
			.catch(err => dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			})));
	}, [user, braintreeToken, sessionToken, dispatch]);
	// Clear the braintree token on user change.
	useEffect(() => {
		if (!user) setBraintreeToken(false);
	}, [user]);

	useEffect(() => {
		let to;
		if (!bookingGroups.length && !loading) {
			to = setTimeout(() => {
				history.push("/booking/bays");
			}, 2000);
		}
		return () => {
			if (to) clearTimeout(to);
		}
	}, [bookingGroups, loading, history]);

	// Redirect to bays view if no bookings selected
	// if (!Boolean(bookingGroups.length && !loading)) return <Redirect to="/booking/bays" />

	if (!bookingGroups.length) return <SpinnerWrapper className="full-content-height"><Spinner color="#333333" size={150} /></SpinnerWrapper>

	const hadBookingErrors = calculateErrors(bookings).length

	return (
		<div className="checkout maximum-width">
			<h2 className="text-center"><b>Order</b> Summary</h2>
			<h3>Please review your order. If you need to remove some bookings, click the bin icon next to the slot you want to remove. If you want to add more bookings, press <b>back</b> at the bottom of the page. Otherwise, complete your purchase to confirm your bookings.</h3>
			<div className="summary">
				{bookingGroups.sort((a, b) => a.bookings[0].bay.number - b.bookings[0].bay.number).map((bg, index) => (
					<GroupSummary key={index} group={bg} groupIndex={index} />
				))}
			</div>
			<div className="total flexy">
				<div><b>Order Total</b></div>
				<div className="text-right">
					<b>£{(calculateOrderTotal(bookingGroups, settings) / 100).toFixed(2)}</b>
				</div>
			</div>
			{/* Prompt the user to log in if they aren't */}
			{!user && <div>
				<div className="featureFont text-center">You have to be logged in to complete the booking.</div>
				<br />
				<br />
				<Login />
			</div>}
			{Boolean(
				user &&
				user.hasLoyaltyCard &&
				!checkIfTempBookings(bookings) &&
				!hadBookingErrors
			) && <div>
					<div className="featureFont text-center">
						Hello Loyalty Card Holder, if you are happy with your selection press the book now button below to reserve your bays. You can then pay for them when you arrive at the driving range.
                </div>
					<br />
					<br />
					<Button
						loading={loading}
						onClick={() => {
							dispatch(loyaltyCheckout(user.hasLoyaltyCard, () => {
								history.push("/booking/my-orders");
							}))
						}}
					>
						<span style={{ fontWeight: "300" }}>CLICK TO</span> CONFIRM
                </Button>
				</div>}
			{/* Retrieving the braintree token from the server. */}
			{Boolean(user && !user.hasLoyaltyCard && !braintreeToken && !hadBookingErrors) && <div>
				<Spinner />
			</div>}
			{/* Displaying the actual payment widget */}
			{Boolean(user && !user.hasLoyaltyCard && braintreeToken && !hadBookingErrors) && <div>
				<DropIn
					options={{ authorization: braintreeToken }}
					onInstance={setBraintreeInstance}
				/>
				{Boolean(braintreeInstance) && <Button
					className="stripe-btn"
					loading={loading}
					onClick={() => {
						setError(null);
						braintreeInstance.requestPaymentMethod().then(result => {
							dispatch(checkout(result.nonce, user.hasLoyaltyCard, () => {
								history.push("/booking/my-orders");
							}));
						}).catch(error => {
							setError(error.message);
						});
					}}
				>
					<span style={{ fontWeight: "300" }}>CLICK TO</span> CHECKOUT
                </Button>}
			</div>}
			{Boolean(error) && <div className="error text-center">
				{error}
			</div>}
		</div>
	)
};

function Footer() {

	const dispatch = useDispatch();
	const { bookings, user } = useSelector(state => ({
		bookings: state.customerBookings.items,
		user: state.session.user
	}))

	const errors = calculateErrors(bookings);

	return (
		<div
			className={[
				"inner",
				"text-center",
				errors.length && "errored"
			].filter(Boolean).join(" ")}
			style={{ paddingLeft: "15vh" }}
		>
			<Link
				className={"prev-stage-btn" + (errors.length ? " with-error" : "")}
				to={errors.length ? "/booking/bays" : "/booking/sessions"}
				onClick={() => {
					// If user logged in ping bookings
					if (Boolean(user)) dispatch(ping());
				}}
			>BACK</Link>
			{/* If not errored, show the summary. Otherwise show the footer in error state, and list the errors. */}
			<div className={`summary ${errors.length ? "errors" : ""}`}>
				{!errors.length ?
					<div style={{ opacity: "0" }}>Placeholder</div>
					:
					<>
						{errors[0]}
					</>
				}
			</div>
		</div>
	)

}

// Handle validation errors in bookings.
function calculateErrors(bookings) {
	// Group up the bookings so we can check session lengths.
	const sessions = groupBookings(bookings);
	// Generate the errors array.
	return [
		sessions.find(session => session.bookings.length < 2) ?
			"Bookings on the same bay must be at least an hour. Add another slot." : (
				sessions.find(session => session.bookings.length % 2 !== 0) ?
					"All bookings on the same bay have to be in increments of an hour. Add another slot." : null
			),
	].filter(Boolean);
};

function getBraintreeToken(sessionToken) {
	return axios.get(`${API_ROOT}/orders/braintree-token`, {
		headers: { Authorization: `Bearer ${sessionToken}` }
	}).then(res => res.data.clientToken);
}

export default asPage(Checkout, Footer);