import { useSelector } from "react-redux";
import { BOOKED, SELECTED, AVAILABLE, DISABLED } from "../constants";
import { findOpeningHoursType } from "../helpers";
import { useMemo } from "react";

/**
* @param {Number} bayNumber 
* @param {moment.Moment} from 
* @param {Array} bookings 
* @param {Number} length 
* @param {Object} settings 
*/
export function bayFree(bayNumber, slot, bookings = [], settings, length = 1) {

	// let loggable = bayNumber === 4 && length > 1;

	// Iterate 'length' times.
	const fromDate = slot.date.clone();
	for (let i = 0; i < length; i++) {
		// Check the time slot is actually supported.
		let effect = findOpeningHoursType(fromDate, settings);
		// if (loggable) console.log("Effect", fromDate.format("HH:mm"), effect);
		if (!effect) return false;
		// Check if there is a booking on that slot.
		let date = fromDate.format("YYYY-MM-DD");
		let from = fromDate.format("HH:mm:00.000");
		let foundBooking = bookings.find(booking =>
			booking.bay.number === bayNumber &&
			booking.date === date &&
			booking.slot.from === from
		);
		// And if there is, reject.
		if (foundBooking) return false;
		// Otherwise add 30 minutes and try the next one.
		fromDate.add(30, "minutes");
	}
	// We're good.
	return true;
}

/**
 * 
 * @param {moment.Moment} time The time to get bay availability for.
 * @param {Object} requirements Properties the bookings must adhere to.
 * 
 * @returns {Array} [Function: getBay(number), Array: Bay statuses].
 */
export default function useBayStatuses(time, { length = 1 } = {}) {

	const {
		settings,
		publicBookings,
		customerBookings,
		bays
	} = useSelector(state => ({
		settings: state.settings,
		publicBookings: state.publicBookings.items.filter(booking => booking.status !== "cancelled"),
		customerBookings: state.customerBookings.items,
		bays: state.bays.items
	}));

	const currentDate = time.format("YYYY-MM-DD");

	const slot = useMemo(function () {
		return {
			date: time.clone(),
			from: time.format("HH:mm[:00.000]"),
			to: time.clone().add(30, "minutes").format("HH:mm[:00.000]")
		}
	}, [time]);

	// Form an object of availability, with keys as the bay numbers.
	const bayAvailability = useMemo(function () {
		const result = [];
		if (!settings || !bays) return result;
		bays.forEach(bay => {
			if (!bay.bookable) {
				result.push({
					status: DISABLED,
					number: bay.number,
					type: bay.type
				});
			} else if (customerBookings.find(b =>
				b.date === currentDate &&
				b.slot.from === slot.from &&
				b.bay.number === bay.number
			)) {
				result.push({
					status: SELECTED,
					number: bay.number,
					type: bay.type
				});
			} else if (!bayFree(bay.number, slot, publicBookings, settings, length)) {
				result.push({
					status: BOOKED,
					number: bay.number,
					type: bay.type
				});
			} else {
				result.push({
					status: AVAILABLE,
					number: bay.number,
					type: bay.type
				});
			}
		});
		function getBay(number) {
			return result.find(b => b.number === number) || {};
		}
		return [getBay, result];
	}, [settings, publicBookings, customerBookings, bays, currentDate, slot, length]);

	return bayAvailability;
}