import { initialState } from ".";
import {
    GET_ORDERS_START,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    CREATE_ORDER_START,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    EDIT_BOOKING_START,
    EDIT_BOOKING_SUCCESS,
    EDIT_BOOKING_FAIL,
    EDIT_ORDER_START,
    EDIT_ORDER_SUCCESS,
    EDIT_ORDER_FAIL,
    REFUND_ORDER_START,
    REFUND_ORDER_SUCCESS,
    REFUND_ORDER_FAIL
} from "../actions/orders";
import { mergeObjectArrays } from "../helpers";

export default function (state = initialState.orders, action) {

    switch (action.type) {
        // Getting existing orders
        case GET_ORDERS_START:
            return {
                ...state,
                getLoading: true
            };

        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                items: mergeObjectArrays(state.items, action.orders, (a, b) => a.id === b.id),
                getLoading: false
            };

        case GET_ORDERS_FAIL:
            return {
                ...state,
                getLoading: false
            };

        // Creating an order.

        case CREATE_ORDER_START:
            return {
                ...state,
                createLoading: true
            };

        case CREATE_ORDER_SUCCESS:
            return {
                ...state,
                items: [...state.items, action.order],
                createLoading: false
            };

        case CREATE_ORDER_FAIL:
            return {
                ...state,
                createLoading: false
            };

        case EDIT_BOOKING_START:
            return {
                ...state,
                items: state.items.map(order => order.id === action.orderId ? { ...order, loading: true } : order)
            };

        case EDIT_BOOKING_SUCCESS:
            return {
                ...state,
                items: state.items.map(order => {
                    if (order.id === action.orderId) {
                        let bookings = order.bookings.map(b => {
                            if (b.id === action.booking.id) return action.booking;
                            return b;
                        })
                        return { ...order, bookings, loading: false }
                    }
                    return order;
                })
            };

        case EDIT_BOOKING_FAIL:
            return {
                ...state,
                items: state.items.map(order => order.id === action.orderId ? { ...order, loading: false } : order)
            };

        case EDIT_ORDER_START:
        case REFUND_ORDER_START:
            return {
                ...state,
                items: state.items.map(order => order.id === action.orderId ? { ...order, loading: true } : order)
            };

        case EDIT_ORDER_SUCCESS:
        case REFUND_ORDER_SUCCESS:
            return {
                ...state,
                items: state.items.map(order => order.id === action.order.id ? { ...action.order, loading: false } : order)
            };

        case EDIT_ORDER_FAIL:
        case REFUND_ORDER_FAIL:
            return {
                ...state,
                items: state.items.map(order => order.id === action.orderId ? { ...order, loading: false } : order)
            };

        default:
            return state
    }

}