import React, { useState } from "react";
import { useSelector } from "react-redux";
import EmailLogin from "./EmailLogin";
import EmailSignup from "./EmailSignup";
import ResetPassword from "./ResetPassword";
import ResetPasswordRequest from "./ResetPasswordRequest";
export const SOCIAL = "SOCIAL";
export const EMAIL_LOGIN = "EMAIL_LOGIN";
export const EMAIL_SIGNUP = "EMAIL_SIGNUP";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD = "RESET_PASSWORD";

export default function Login() {

    const {
		resetPassword,
		code,
		email
	} = useSelector(state => state.session.query);
    
    const [mode, setMode] = useState((() =>{
        if (resetPassword === "true" && code && email) {
            return RESET_PASSWORD
        } else {
            return EMAIL_LOGIN
        }
    }))

	function onRequestFormChange(form) {
		setMode(form);
	}

	return <div className="login">
		<div className="emailLogin">
			{/* Logging in manually */}
			{mode === EMAIL_LOGIN && <>
				<EmailLogin />
				<br />
				<div
					className="link text-center"
					onClick={e => setMode(EMAIL_SIGNUP)}
				>No account yet? <span>Click here.</span></div>
				<br />
				<div
					className="link text-center"
					onClick={e => setMode(RESET_PASSWORD_REQUEST)}
				>Forgotten your password? <span>Click here.</span></div>
			</>}

			{/* Requesting a new password */}
			{mode === RESET_PASSWORD_REQUEST && <>
				<ResetPasswordRequest />
				<br />
				<div
					className="link text-center"
					onClick={e => setMode(EMAIL_LOGIN)}
				>Back to login? <span>Click here.</span></div>
			</>}

			{/* Requesting a new password */}
			{mode === RESET_PASSWORD && <>
				<ResetPassword onRequestFormChange={onRequestFormChange} />
				<br />
				<div
					className="link text-center"
					onClick={e => setMode(EMAIL_LOGIN)}
				>Back to login? <span>Click here.</span></div>
			</>}

			{/* Signing up manually */}
			{mode === EMAIL_SIGNUP && <>
				<EmailSignup />
				<br />
				<div
					className="link text-center"
					onClick={e => setMode(EMAIL_LOGIN)}
				>Already have an account? <span>Click here.</span></div>
			</>}
		</div>
	</div>

}