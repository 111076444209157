import ws from "../assets/Websocket";
import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// Style
import "./style.scss";
// Components
import MyOrders from "./components/pages/MyOrders";
import Bays from "./components/pages/Bays";
// import Sessions from "./components/pages/Sessions";
import Checkout from "./components/pages/Checkout";
import Logger from "../assets/Logger";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerOrder } from "../actions/customerBookings";
import { getClubs } from "../actions/clubs";
import useActivityMonitor from "../assets/useActivityMonitor";
import { useModal } from "../assets/useModal";
import InactivityModal from "./components/InactivityModal";
import { getPublicBookings } from "../actions/publicBookings";
import moment from "moment";
import { usePrevious } from "../helpers";

export default function Booking() {

	window.location.href = "https://yourgolfbooking.com/venues/greenwich-peninsula/booking/bays";

	const isActive = useActivityMonitor();
	const wasActive = usePrevious(isActive);
	const { setModal, openModal, closeModal } = useModal();

	const dispatch = useDispatch();
	const { user, viewing } = useSelector(state => ({
		user: state.session.user,
		viewing: state.time.viewing,
	}));

	// Getting the bookings for the day whenever the day changes.
	const day = viewing.format("YYYY-MM-DD");
	useEffect(() => {
		let to = setTimeout(() => {
			dispatch(getPublicBookings(moment(day).startOf("day"), moment(day).endOf("day")));
		}, 500);
		return () => {
			if (to) clearTimeout(to);
		}
	}, [dispatch, day]);

	useEffect(() => {
		// Close the websocket if we become inactive.
		if (!isActive && wasActive) {
			ws.close();
			setModal(<InactivityModal />, { className: "size-to-content", closable: true, clearOnClose: true });
			openModal();
		} else if (isActive && wasActive === false) {
			// Re-get the bookings and open the websocket if we become active again.
			ws.open();
			dispatch(getPublicBookings(moment(day).startOf("day"), moment(day).endOf("day")));
			if (user) dispatch(getCustomerOrder());
			closeModal();
		}
	}, [isActive, wasActive, closeModal, setModal, openModal, dispatch, day, user]);

	// Get the customer's current bookings.
	useEffect(() => {
		if (user) dispatch(getCustomerOrder());
    }, [user, dispatch]);
    
    // Getting clubs
    useEffect(() => {
        dispatch(getClubs());
    },[dispatch])

	return <div id="booking-view">
		<Switch>
			<Route path="/booking/my-orders" render={router => (
				<MyOrders />
			)} />
			<Route path="/booking/bays" render={router => (
				<Bays />
			)} />
			{/* <Route path="/booking/sessions" render={router => (
				<Sessions />
			)} /> */}
			<Route path="/booking/checkout" render={router => (
				<Checkout />
			)} />
			<Route path="/" render={router => (
				<Redirect to="/booking/bays" />
			)} />
		</Switch>
		<Logger style={{ left: "50%", bottom: "12vh" }} />
	</div>
}
