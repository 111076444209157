import { initialState } from ".";
import { SET_CURRENT_TIME, SET_VIEWING_TIME } from "../actions/time";

export default function (state = initialState.time, action) {
    switch(action.type) {
        case SET_CURRENT_TIME:
            return {
                ...state,
                current: action.time
            }
        case SET_VIEWING_TIME:
            return {
                ...state,
                viewing: action.time
            }
        default:
            return state
    }
}