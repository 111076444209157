import { initialState } from ".";
import {
	GET_BAYS_START,
	GET_BAYS_SUCCESS,
	GET_BAYS_FAIL,
	UPDATE_BAY_START,
	UPDATE_BAY_SUCCESS,
	UPDATE_BAY_FAIL,
} from "../actions/bays";
import { mergeObjectArrays } from "../helpers";

export default function (state = initialState.bays, action) {

	switch (action.type) {
		// Getting clubs
		case GET_BAYS_START:
			return {
				...state,
				loading: true
			};

		case GET_BAYS_SUCCESS:
			return {
				...state,
				items: action.bays,
				loading: false
			};

		case GET_BAYS_FAIL:
			return {
				...state,
				loading: false
			};

		case UPDATE_BAY_START:
			return {
				...state,
				items: mergeObjectArrays(state.items, [{
					...action.bay,
					loading: true
				}], (a, b) => a.id === b.id),
			};

		case UPDATE_BAY_SUCCESS:
			return {
				...state,
				items: mergeObjectArrays(state.items, [{
					...action.bay,
					loading: false
				}], (a, b) => a.id === b.id),
			};

		case UPDATE_BAY_FAIL:
			return {
				...state,
				items: state.items
					.map(bay => bay.id === action.bay.id ? { ...bay, loading: false } : bay),
			};

		default:
			return state

	}

}