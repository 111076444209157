import { initialState } from ".";
import { 
    GET_SETTINGS_START, 
    GET_SETTINGS_SUCCESS, 
    GET_SETTINGS_FAIL,
    UPDATE_SETTINGS_START,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAIL 
} from "../actions/settings";

export default function (state = initialState.settings, action) {

	switch (action.type) {
		// Getting clubs
		case GET_SETTINGS_START:
        case UPDATE_SETTINGS_START:
			return {
				...state,
				loading: true
			};

		case GET_SETTINGS_SUCCESS:
			return {
                ...state,
				...action.settings,
				loading: false
            };
            
        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.settings,
                loading: false
            }

		case GET_SETTINGS_FAIL:
        case UPDATE_SETTINGS_FAIL :
			return {
				...state,
				loading: false
			};

		default:
			return state

	}
}