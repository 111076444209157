import React from "react";
import { useModal } from "../../assets/useModal";

export default function InactivityModal() {

	const { closeModal } = useModal();

	function imBack() {
		closeModal();
	}

	return <div className="inactivityModal text-left">
		<h2 style={{ marginTop: -10 }}>You <b>Timed Out</b></h2>
		<hr />
		<div>
			You haven't been active in a while. When you're back, click the button below.
			<br /><br />
		</div>
		<button onClick={imBack}>I'm back</button>
	</div>
}