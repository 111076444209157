export const UTILS_DRAG_START = "UTILS_DRAG_START";
export const UTILS_DRAG_END = "UTILS_DRAG_END";
// export const UTILS_DRAG = "UTILS_DRAG";

export function dragStart(elem, position) {
    return {
        type: UTILS_DRAG_START,
        elem,
        position
    }
};

export function dragEnd() {
    return {
        type: UTILS_DRAG_END
    }
}

// export function drag({ x, y }) {
//     return {
//         type: UTILS_DRAG,
//         x, y
//     }
// }