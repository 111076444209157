import axios from "axios";
import { API_ROOT } from "../constants";
import { addLog } from "./logger";
// import moment from "moment";
import { errorResponse } from "../helpers";

export const GET_REPORT_DATA_START = "GET_REPORT_DATA_START";
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS";
export const GET_REPORT_DATA_FAIL = "GET_REPORT_DATA_FAIL";

/**
 * @param {moment.Moment} from Date from.
 * @param {moment.Moment} to Date to.
 * @param {String} getBy 
 * @param {String} increment 
 */
export function getData(from, to, getBy, increment) {
	return function (dispatch, getState) {
		dispatch({
			type: GET_REPORT_DATA_START
		});

		// Get the current token
		const { session: { token } } = getState();

		axios.get(`${API_ROOT}/orders/report`, {
			params: {
				from: from.format(),
				to: to.format(),
				getBy,
				increment
			},
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(data => {
			dispatch({
				type: GET_REPORT_DATA_SUCCESS,
				data
			});
		}).catch(err => {
			dispatch({
				type: GET_REPORT_DATA_FAIL
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}));
		});
	}
}
