import moment from "moment";
import { initialState } from ".";
import {
	GET_PUBLIC_BOOKINGS_START,
	GET_PUBLIC_BOOKINGS_SUCCESS,
	GET_PUBLIC_BOOKINGS_FAIL,
	REMOVE_PUBLIC_BOOKINGS,
} from "../actions/publicBookings";
import { mergeObjectArrays } from "../helpers";
import { REMOVE_CUSTOMER_BOOKING_SUCCESS } from "../actions/customerBookings";

export default function (state = initialState.publicBookings, action) {

	switch (action.type) {
		// Getting clubs
		case GET_PUBLIC_BOOKINGS_START:
			return {
				...state,
				loading: true
			};

		case GET_PUBLIC_BOOKINGS_SUCCESS:
			return {
				...state,
				items: mergeObjectArrays(state.items, action.publicBookings, (a, b) => a.id === b.id),
				loading: false
			};

		case GET_PUBLIC_BOOKINGS_FAIL:
			return {
				...state,
				loading: false
			};

		case REMOVE_CUSTOMER_BOOKING_SUCCESS:
			return {
				...state,
				items: state.items.filter(booking => !(
					action.booking.bay.number === booking.bay.number &&
					action.booking.slot.from === booking.slot.from &&
					moment(action.booking.date).isSame(moment(booking.date), "day")
				))
			}

		case REMOVE_PUBLIC_BOOKINGS:
			return {
				...state,
				items: state.items.filter(booking => !action.publicBookings.find(ab => ab.id === booking.id))
			}

		default:
			return state

	}
}