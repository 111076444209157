import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { getData } from "../../actions/reportData";
import { Button, DateField, DropdownField, CheckboxField } from "../../fields";
import { ImgIcon, Spinner, SpinnerWrapper } from "../../assets";
import { search } from "../../icons";
// ease of use constants.
const ORDER_DATE = "ORDER_DATE";
const PLAY_DATE = "PLAY_DATE";

export default function OrdersReport() {

    const dispatch = useDispatch();
    const { data, loading } = useSelector(state => ({
        data: state.reportData.items,
        loading: state.reportData.loading
    }));

    // Values for data queries
    const [from, setFrom] = useState(moment().startOf('day'));
    const [to, setTo] = useState(moment().endOf('day'));
    const [by, setBy] = useState(PLAY_DATE);
    const [increment, setIncrement] = useState("day");
    // Values for toggling chart lines.
    const [onlineAttended, setOnlineAttended] = useState(true);
    const [onlineAttendedCount, setOnlineAttendedCount] = useState(false);

    const [onlineUnattended, setOnlineUnattended] = useState(true);
    const [onlineUnattendedCount, setOnlineUnattendedCount] = useState(false);

    const [walkInAttended, setWalkInAttended] = useState(true);
    const [walkInAttendedCount, setWalkInAttendedCount] = useState(false);

    const [walkInUnattended, setWalkInUnattended] = useState(true);
    const [walkInUnattendedCount, setWalkInUnattendedCount] = useState(false);

    const [totalAttended, setTotalAttended] = useState(true);
    const [totalAttendedCount, setTotalAttendedCount] = useState(false);

    const [totalUnattended, setTotalUnattended] = useState(true);
    const [totalUnattendedCount, setTotalUnattendedCount] = useState(false);

    // const [total, setTotal] = useState(true);
    // const [totalTakings, setTotalTakings] = useState(true);
    // const [totalCount, setTotalCount] = useState(false);

    // const [refunds, setRefunds] = useState(true);
    // const [refundsCount, setRefundsCount] = useState(false);

    // const [online, setOnline] = useState(true);
    // const [onlineTakings, setOnlineTakings] = useState(true);
    // const [onlineCount, setOnlineCount] = useState(false);

    // const [walkIn, setWalkIn] = useState(true);
    // const [walkInTakings, setWalkInTakings] = useState(true);
    // const [walkInCount, setWalkInCount] = useState(false);

    // const [loyalty, setLoyalty] = useState(true);
    // const [loyaltyCount, setLoyaltyCount] = useState(false);

    // const [paid, setPaid] = useState(true);
    // const [paidCount, setPaidCount] = useState(false);

    // const [unpaid, setUnpaid] = useState(true);
    // const [unpaidCount, setUnpaidCount] = useState(false);

    // const [attended, setAttended] = useState(true);
    // const [attendedCount, setAttendedCount] = useState(false);

    // const [unattended, setUnattended] = useState(true);
    // const [unattendedCount, setUnattendedCount] = useState(false);

    const [allVolumes, setAllVolumes] = useState(true);
    const [allCounts, setAllCounts] = useState(false);


    const options = incrementOptions(to, from);

    useEffect(() => {
        if (!options.includes(increment)) {
            setIncrement(options[0]);
        }
    }, [increment, setIncrement, options]);

    useEffect(() => {
        if (allVolumes) {
            volumesSetter(true)
        } else {
            volumesSetter(false)
        }
    }, [allVolumes]);

    useEffect(() => {
        if (allCounts) {
            countsSetter(true)
        } else {
            countsSetter(false)
        }
    }, [allCounts]);

    function change(from, to) {
        setFrom(from);
        setTo(to);
    };

    function volumesSetter(val) {
        // setTotal(val);
        // setTotalTakings(val)
        // setRefunds(val);
        // setOnline(val);
        // setOnlineTakings(val);
        // setWalkIn(val);
        // setWalkInTakings(val);
        // setLoyalty(val);
        // setPaid(val);
        // setUnpaid(val);
        // setAttended(val);
        // setUnattended(val);
        setOnlineAttended(val);
        setOnlineUnattended(val);
        setWalkInAttended(val);
        setWalkInUnattended(val);
        setTotalAttended(val);
        setTotalUnattended(val);
    }

    function countsSetter(val) {
        setOnlineAttendedCount(val);
        setOnlineUnattendedCount(val);
        setWalkInAttendedCount(val);
        setWalkInUnattendedCount(val);
        setTotalAttendedCount(val);
        setTotalUnattendedCount(val);
        // setTotalCount(val);
        // setRefundsCount(val);
        // setOnlineCount(val);
        // setWalkInCount(val);
        // setLoyaltyCount(val);
        // setPaidCount(val);
        // setUnpaidCount(val);
        // setAttendedCount(val);
        // setUnattendedCount(val);

    }

    function getTotal(key) {
        if (!data.length) return 0;
        return data.reduce((acc, cur) => {
            return acc + cur[key]
        }, 0)
    }

    return <div className="orders-report-admin">
        <h2 style={{ margin: "0px 0px 10px 0px" }}><b>Orders</b> Report</h2>
        <hr />
        <div className="from-to-row">
            <DateField
                label="From"
                value={from}
                onChange={val => {
                    // Add some check to make sure from is before to.
                    setFrom(val.startOf('day'))
                }}
            />
            <DateField
                label="To"
                value={to}
                onChange={val => {
                    // Check to make sure from is after to
                    setTo(val.endOf("day"))
                }}
            />
            <div className="dropdown-wrapper">
                <label>Search by</label>
                <DropdownField
                    value={by}
                    options={[
                        { name: "Order date", value: ORDER_DATE },
                        { name: "Play date", value: PLAY_DATE }
                    ]}
                    height={2}
                    onChange={val => setBy(val)}
                />
            </div>
            <div className="dropdown-wrapper">
                <label>Increment</label>
                <DropdownField
                    value={increment}
                    options={options.map(option => ({ name: option, value: option }))}
                    height={options.length}
                    onChange={val => setIncrement(val)}
                />
            </div>
            <Button
                onClick={() => dispatch(getData(from, to, by, increment))}
            >
                <ImgIcon className="align-center" size={15} src={search} />
                <span className="align-center">Search</span>
            </Button>
        </div>
        <br />
        <div className="button-row v-middle">
            <Button onClick={() => {
                change(moment().startOf("day"), moment().endOf("day"))
            }}>Today</Button>
            <Button onClick={() => {
                change(
                    moment().subtract(8, "days").startOf('day'),
                    moment().subtract(1, "days").endOf('day')
                )
            }}>Last 7 days</Button>
            <Button onClick={() => {
                change(
                    moment().subtract(3, "months").startOf('day'),
                    moment().subtract(1, "days").endOf('day')
                )
            }}>Last 3 months</Button>
            <Button onClick={() => {
                change(
                    moment().subtract(1, "years").startOf('day'),
                    moment().subtract(1, "days").endOf('day')
                )
            }}>Last 12 months</Button>
            <Button onClick={() => {
                change(
                    moment().startOf('month'),
                    moment()
                )
            }}>Month to date</Button>
            <Button onClick={() => {
                change(
                    moment().startOf('quarter'),
                    moment()
                )
            }}>Quarter to date</Button>
            <Button onClick={() => {
                change(
                    moment().startOf('year'),
                    moment()
                )
            }}>Year to date</Button>
        </div>
        <hr />
        <div className="results-row">
            {loading
                ?
                <SpinnerWrapper style={{ height: "400px" }}><Spinner color="#333333" size={150} /></SpinnerWrapper>
                :
                Boolean(data.length)
                    ?
                    <>
                        <ResponsiveContainer width="97%" height={400}>
                            <LineChart data={formatData(data, increment)}>
                                {onlineAttended && <Line type="linear" dataKey="online_attended" stroke="#00ba89" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {onlineAttendedCount && <Line type="linear" dataKey="online_attended_count" stroke="#00ba89" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {onlineUnattended && <Line type="linear" dataKey="online_unattended" stroke="#bada55" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {onlineUnattendedCount && <Line type="linear" dataKey="online_unattended_count" stroke="#bada55" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {walkInAttended && <Line type="linear" dataKey="walk_in_attended" stroke="#0094a5" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {walkInAttendedCount && <Line type="linear" dataKey="walk_in_attended_count" stroke="#0094a5" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {walkInUnattended && <Line type="linear" dataKey="walk_in_unattended" stroke="#d996fc" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {walkInUnattendedCount && <Line type="linear" dataKey="walk_in_unattended_count" stroke="#d996fc" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {totalAttended && <Line type="linear" dataKey="total_attended" stroke="#1d6e2f" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {totalAttendedCount && <Line type="linear" dataKey="total_attended_count" stroke="#1d6e2f" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {totalUnattended && <Line type="linear" dataKey="total_unattended" stroke="#f7347a" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {totalUnattendedCount && <Line type="linear" dataKey="total_unattended_count" stroke="#f7347a" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {/* {total && <Line type="linear" dataKey="total" stroke="#00ba89" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {totalTakings && <Line type="linear" dataKey="total_takings" stroke="#bada55" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {totalCount && <Line type="linear" dataKey="total_count" stroke="#00ba89" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {refunds && <Line type="linear" dataKey="refunds" stroke="#9e0040" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {refundsCount && <Line type="linear" dataKey="refunds_count" stroke="#9e0040" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {online && <Line type="linear" dataKey="online" stroke="#0094a5" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {onlineTakings && <Line type="linear" dataKey="online_takings" stroke="#7fe5f0" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {onlineCount && <Line type="linear" dataKey="online_count" stroke="#0094a5" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {walkIn && <Line type="linear" dataKey="walk_in" stroke="#d996fc" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {walkInTakings && <Line type="linear" dataKey="walk_in_takings" stroke="#800080" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {walkInCount && <Line type="linear" dataKey="walk_in_count" stroke="#d996fc" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {loyalty && <Line type="linear" dataKey="loyalty_card" stroke="#ff7f50" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {loyaltyCount && <Line type="linear" dataKey="loyalty_card_count" stroke="#ff7f50" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {paid && <Line type="linear" dataKey="paid" stroke="#1d6e2f" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {paidCount && <Line type="linear" dataKey="paid_count" stroke="#1d6e2f" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {unpaid && <Line type="linear" dataKey="unpaid" stroke="#daa520" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {unpaidCount && <Line type="linear" dataKey="unpaid_count" stroke="#daa520" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {attended && <Line type="linear" dataKey="attended" stroke="#0000ff" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {attendedCount && <Line type="linear" dataKey="attended_count" stroke="#0000ff" dot={{ strokeWidth: 2 }} strokeWidth={2} />}

                                {unattended && <Line type="linear" dataKey="unattended" stroke="#f7347a" dot={{ strokeWidth: 2 }} strokeWidth={2} />}
                                {unattendedCount && <Line type="linear" dataKey="unattended_count" stroke="#f7347a" dot={{ strokeWidth: 2 }} strokeWidth={2} />} */}

                                <Legend verticalAlign="top" height={36} />
                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                        <br />
                        <div className="data-summary flexy">
                            <div className="volume-row">
                                <h3><b>Volumes</b></h3>
                                <br />
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Show all"
                                        size={25}
                                        value={allVolumes}
                                        onChange={val => setAllVolumes(val)}
                                    />
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Online attended"
                                        size={25}
                                        value={onlineAttended}
                                        fill="#00ba89"
                                        onChange={val => setOnlineAttended(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("online_attended") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Online unattended"
                                        size={25}
                                        value={onlineUnattended}
                                        fill="#bada55"
                                        onChange={val => setOnlineUnattended(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("online_unattended") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Walk in attended"
                                        size={25}
                                        value={walkInAttended}
                                        fill="#0094a5"
                                        onChange={val => setWalkInAttended(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("walk_in_attended") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Walk in unattended"
                                        size={25}
                                        value={walkInUnattended}
                                        fill="#d996fc"
                                        onChange={val => setWalkInUnattended(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("walk_in_unattended") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Total attended"
                                        size={25}
                                        value={totalAttended}
                                        fill="#1d6e2f"
                                        onChange={val => setTotalAttended(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("total_attended") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Total unattended"
                                        size={25}
                                        value={totalUnattended}
                                        fill="#f7347a"
                                        onChange={val => setTotalUnattended(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("total_unattended") / 100).toFixed(2)}</b>
                                </div>
                                {/* <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Total: "
                                        fill="#00ba89"
                                        size={25}
                                        value={total}
                                        onChange={val => setTotal(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("total") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Total takings: "
                                        fill="#bada55"
                                        size={25}
                                        value={totalTakings}
                                        onChange={val => setTotalTakings(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("total_takings") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Refunds: "
                                        fill="#9e0040"
                                        size={25}
                                        value={refunds}
                                        onChange={val => setRefunds(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("refunds") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Online: "
                                        fill="#0094a5"
                                        size={25}
                                        value={online}
                                        onChange={val => setOnline(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("online") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Online takings: "
                                        fill="#7fe5f0"
                                        size={25}
                                        value={onlineTakings}
                                        onChange={val => setOnlineTakings(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("online_takings") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Walk in: "
                                        fill="#d996fc"
                                        size={25}
                                        value={walkIn}
                                        onChange={val => setWalkIn(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("walk_in") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Walk in takings: "
                                        fill="#800080"
                                        size={25}
                                        value={walkInTakings}
                                        onChange={val => setWalkInTakings(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("walk_in_takings") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Loyalty card: "
                                        fill="#ff7f50"
                                        size={25}
                                        value={loyalty}
                                        onChange={val => setLoyalty(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("loyalty_card") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Paid: "
                                        fill="#1d6e2f"
                                        size={25}
                                        value={paid}
                                        onChange={val => setPaid(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("paid") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Unpaid: "
                                        fill="#daa520"
                                        size={25}
                                        value={unpaid}
                                        onChange={val => setUnpaid(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("unpaid") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Attended: "
                                        fill="#0000ff"
                                        size={25}
                                        value={attended}
                                        onChange={val => setAttended(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("attended") / 100).toFixed(2)}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Unattended: "
                                        fill="#f7347a"
                                        size={25}
                                        value={unattended}
                                        onChange={val => setUnattended(val)}
                                    />
                                    <b className="align-center data-value">£{(getTotal("unattended") / 100).toFixed(2)}</b>
                                </div> */}
                            </div>
                            <div className="count-row">
                                <h3><b>Counts</b></h3>
                                <br />
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Show all"
                                        size={25}
                                        value={allCounts}
                                        onChange={val => setAllCounts(val)}
                                    />
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Online attended"
                                        size={25}
                                        value={onlineAttendedCount}
                                        fill="#00ba89"
                                        onChange={val => setOnlineAttendedCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("online_attended_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Online unattended"
                                        size={25}
                                        value={onlineUnattendedCount}
                                        fill="#bada55"
                                        onChange={val => setOnlineUnattendedCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("online_unattended_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Walk in attended"
                                        size={25}
                                        value={walkInAttendedCount}
                                        fill="#0094a5"
                                        onChange={val => setWalkInAttendedCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("walk_in_attended_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Walk in unattended"
                                        size={25}
                                        value={walkInUnattendedCount}
                                        fill="#d996fc"
                                        onChange={val => setWalkInUnattendedCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("walk_in_unattended_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Total attended"
                                        size={25}
                                        value={totalAttendedCount}
                                        fill="#1d6e2f"
                                        onChange={val => setTotalAttendedCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("total_attended_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Total unattended"
                                        size={25}
                                        value={totalUnattendedCount}
                                        fill="#f7347a"
                                        onChange={val => setTotalUnattendedCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("total_unattended_count")}</b>
                                </div>
                                {/* <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Total: "
                                        fill="#00ba89"
                                        size={25}
                                        value={totalCount}
                                        onChange={val => setTotalCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("total_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Refunds: "
                                        fill="#9e0040"
                                        size={25}
                                        value={refundsCount}
                                        onChange={val => setRefundsCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("refunds_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Online: "
                                        fill="#0094a5"
                                        size={25}
                                        value={onlineCount}
                                        onChange={val => setOnlineCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("online_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Walk in: "
                                        fill="#d996fc"
                                        size={25}
                                        value={walkInCount}
                                        onChange={val => setWalkInCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("walk_in_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Loyalty: "
                                        fill="#ff7f50"
                                        size={25}
                                        value={loyaltyCount}
                                        onChange={val => setLoyaltyCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("loyalty_card_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Paid: "
                                        fill="#1d6e2f"
                                        size={25}
                                        value={paidCount}
                                        onChange={val => setPaidCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("paid_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Unpaid: "
                                        fill="#daa520"
                                        size={25}
                                        value={unpaidCount}
                                        onChange={val => setUnpaidCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("unpaid_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Attended: "
                                        fill="#0000ff"
                                        size={25}
                                        value={attendedCount}
                                        onChange={val => setAttendedCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("attended_count")}</b>
                                </div>
                                <div style={{ lineHeight: "25px" }}>
                                    <CheckboxField
                                        className="align-center"
                                        label="Unattended: "
                                        fill="#f7347a"
                                        size={25}
                                        value={unattendedCount}
                                        onChange={val => setUnattendedCount(val)}
                                    />
                                    <b className="align-center data-value">{getTotal("unattended_count")}</b>
                                </div> */}
                            </div>
                        </div>
                    </>
                    :
                    <div className="text-center" style={{ height: "400px" }}>
                        <h2 className="absolute-center">Press the <b>search</b> button to get data.</h2>
                    </div>
            }
        </div>
    </div>
};

function incrementOptions(to, from) {
    // Getting day and month difference between to and from dates
    const dayDiff = to.diff(from, "days");
    const monthDiff = to.diff(from, "months");
    // Returns an array of increment options based on the from and to values
    if (dayDiff < 2) {
        return ["hour"]
    } else if (dayDiff < 7) {
        return ["hour", "day"]
    } else if (monthDiff < 2) {
        return ["day"]
    } else if (monthDiff < 6) {
        return ["day", "month"]
    } else if (monthDiff < 24) {
        return ["month"]
    } else {
        return ["month", "year"]
    }
};

function formatDate(date, format) {
    if (format === "hour") {
        return moment(date).format("H")
    } else if (format === "day") {
        return moment(date).format("DD/MM")
    } else if (format === "month") {
        return moment(date).format("MMM")
    } else if (format === "year") {
        return moment(date).format("YYYY")
    } else {
        return date;
    }
}

function formatData(data, increment) {
    return data.map(item => (
        {
            ...item,
            date: formatDate(item.date, increment),
            online_attended: Number(( item.online_attended / 100 )).toFixed(2),
            online_unattended: Number(( item.online_unattended / 100 )).toFixed(2),
            walk_in_attended: Number(( item.walk_in_attended / 100 )).toFixed(2),
            walk_in_unattended: Number(( item.walk_in_unattended / 100 )).toFixed(2),
            total_attended: Number(( item.total_attended / 100 )).toFixed(2),
            total_unattended: Number(( item.total_unattended / 100 )).toFixed(2)
        //     date: formatDate(item.date, increment),
        //     total: Number((item.total / 100).toFixed(2)),
        //     total_takings: Number((item.total_takings / 100).toFixed(2)),
        //     refunds: Number((item.refunds / 100).toFixed(2)),
        //     online: Number((item.online / 100).toFixed(2)),
        //     online_takings: Number((item.online_takings / 100).toFixed(2)),
        //     walk_in: Number((item.walk_in / 100).toFixed(2)),
        //     walk_in_takings: Number((item.walk_in_takings / 100).toFixed(2)),
        //     loyalty_card: Number((item.loyalty_card / 100).toFixed(2)),
        //     paid: Number((item.paid / 100).toFixed(2)),
        //     unpaid: Number((item.unpaid / 100).toFixed(2)),
        //     attended: Number((item.attended / 100).toFixed(2)),
        //     unattended: Number((item.unattended / 100).toFixed(2)),
        }))
}