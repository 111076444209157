import { initialState } from ".";
import { 
    ADD_ORDER_BOOKING, 
    REMOVE_ORDER_BOOKING, 
    ADD_BOOKING_EXTRA, 
    REMOVE_BOOKING_EXTRA, 
    SET_ORDER_USER, 
    REMOVE_ORDER_USER, 
    CLEAR_ORDER_ALL, 
    SET_BOOKING_PEOPLE,
    SET_ORDER_PAID,
    ORDER_WITH_LOYALTY_CARD 
} from "../actions/newOrder";
import { groupBookings } from "../helpers";

export default function (state = initialState.newOrder, action) {
    switch (action.type) {
        case ADD_ORDER_BOOKING:
            return {
                ...state,
                bookingGroups: groupBookings([
                    ...state.bookingGroups.map(bg => bg.bookings),
                    action.booking
                ].flat())
            }

        case REMOVE_ORDER_BOOKING:

            return {
                ...state,
                bookingGroups: groupBookings(
                    state.bookingGroups
                        .map(bg => bg.bookings)
                        .flat()
                        .filter(booking => !(
                            action.booking.bay.number === booking.bay.number &&
                            action.booking.slot.from === booking.slot.from &&
                            action.booking.date === booking.date
                        ))
                )
            }

        case ADD_BOOKING_EXTRA:
            return {
                ...state,
                bookingGroups: state.bookingGroups.map((group, index) => {
                    if (index === action.groupIndex) {
                        // Checking if extra was already added, if yes increase quantity
                        let position = group.extras.map(e => e.club.id).indexOf(action.extra.club.id);
                        if (position === -1) {
                            // Add extra
                            group.extras.push(action.extra)
                        } else {
                            // Increase quantity
                            group.extras[position].quantity += 1;
                        }
                    }
                    return group;
                })
            }

        case REMOVE_BOOKING_EXTRA:
            return {
                ...state,
                bookingGroups: state.bookingGroups.map((group, index) => {
                    if (index === action.groupIndex) {
                        group.extras = group.extras
                            .map(e => {
                                // Lower the quantity by 1
                                if (e.club.id === action.clubId) e.quantity -= 1;
                                return e;
                                // Filter out any extras without quantity
                            }).filter(e => e.quantity > 0)
                    }
                    return group;
                })
            }

        case SET_BOOKING_PEOPLE:
            return {
                ...state,
                bookingGroups: state.bookingGroups.map((group, index) => {
                    if (index === action.groupIndex) {
                        group[action.personType] = action.number;
                    }
                    return group;
                })
            }

        case SET_ORDER_USER:
            return {
                ...state,
                user: action.user
            }

        case SET_ORDER_PAID:
            return {
                ...state,
                paid: action.paid
            }

        case ORDER_WITH_LOYALTY_CARD:
            return {
                ...state,
                withLoyaltyCard: action.loyalty
            }

        case REMOVE_ORDER_USER:
            return {
                ...state,
                user: null
            }

        case CLEAR_ORDER_ALL:
            return {
                ...state,
                bookingGroups: [],
                user: null
            }

        default:
            return state
    }
}