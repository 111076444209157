import { initialState } from ".";
import { UTILS_DRAG_START, UTILS_DRAG_END } from "../actions/utils";

export default function (state = initialState.utils, action) {
    switch (action.type) {

        case UTILS_DRAG_START:
            return {
                ...state,
                dragging: action.elem
            };

        case UTILS_DRAG_END:
            return {
                ...state,
                dragging: null
            };

        default:
            return state

    }
}