import { initialState } from ".";
import { SET_SEARCH_PAGE, SET_SEARCH_FILTER, SET_SEARCH_DATE } from "../actions/ordersLookup";

export default function (state = initialState.ordersLookup, action) {
    switch(action.type) {

        // Change page
        case SET_SEARCH_PAGE:
            return {
                ...state,
                page: action.page
            };

        // Change filter
        case SET_SEARCH_FILTER:
            return {
                ...state,
                filter: action.filter
            };

        // Change date
        case SET_SEARCH_DATE:
            return {
                ...state,
                date: action.date
            }

        default:
            return state

    }
}