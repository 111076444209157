import { initialState } from ".";
import { GET_MY_ORDERS_START, GET_MY_ORDERS_SUCCESS, GET_MY_ORDERS_FAIL, GET_MY_ORDERS_COUNT_START, GET_MY_ORDERS_COUNT_SUCCESS, GET_MY_ORDERS_COUNT_FAIL, CANCEL_ORDER_SUCCESS } from "../actions/myOrders";
import { mergeObjectArrays } from "../helpers";
import { LOGOUT } from "../actions/session";

export default function (state = initialState.myOrders, action) {

	switch (action.type) {

		case GET_MY_ORDERS_START:
			return {
				...state,
				loading: true
			};

		case GET_MY_ORDERS_SUCCESS:
			return {
				...state,
				items: action.orders,
				loading: false
			};

		case GET_MY_ORDERS_FAIL:
			return {
				...state,
				loading: false
			}

		case GET_MY_ORDERS_COUNT_START:
			return {
				...state
			}

		case GET_MY_ORDERS_COUNT_SUCCESS:
			return {
				...state,
				count: action.count
			}

		case GET_MY_ORDERS_COUNT_FAIL:
			return {
				...state
			}

		// Cancelling an order.
		// case CANCEL_ORDER_START:
		// case CANCEL_ORDER_FAIL:
		case CANCEL_ORDER_SUCCESS:
			return {
				...state,
				items: mergeObjectArrays(state.items, [action.order], (a, b) => a.id === b.id)
			};

		case LOGOUT:
			return {
				...state,
				items: []
			}

		default:
			return state
	}

}