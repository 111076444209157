export const logo = "/media/icons/logo.png";
export const golfer = "/media/icons/golfer.svg";
export const chevron = "/media/icons/chevron.svg";
export const calendar = "/media/icons/calendar.svg";
export const eye = "/media/icons/eye.svg";
export const close = "/media/icons/x.svg";
export const closeWhite = "/media/icons/x-white.svg";
export const plus = "/media/icons/plus.svg";
export const minus = "/media/icons/minus.svg";
export const search = "/media/icons/search.svg";
export const arrow = "/media/icons/arrow.svg";
export const user = "/media/icons/user.svg";
export const logoutIcon = "/media/icons/logout.svg";
export const check = "/media/icons/check.svg";
export const club = "/media/icons/club.svg";
export const info = "/media/icons/info.svg";
export const star = "/media/icons/star.svg";
export const golfBall = "/media/icons/golf-ball.svg";
export const bin = "/media/icons/bin.svg";
export const warning = "/media/icons/alert-triangle.svg";
export const settings = "/media/icons/settings.svg";
export const pound = "/media/icons/pound.svg";
export const chart = "/media/icons/chart.svg";
export const mail = "/media/icons/mail.svg";