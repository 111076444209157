export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE";
export const SET_SEARCH_DATE = "SET_SEARCH_DATE";

export function setSearchFilter(filter) {
    return {
        type: SET_SEARCH_FILTER,
        filter
    }
};

export function setSearchPage(page) {
    return {
        type: SET_SEARCH_PAGE,
        page
    }
}

export function setSearchDate(date) {
    return {
        type: SET_SEARCH_DATE,
        date
    }
}