import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useBayStatuses from "./useBayStatuses";
import { SELECTED, BOOKED, DISABLED } from "../constants";

export default function BayGraphic() {

	const {
		viewingTime,
	} = useSelector(state => ({
		viewingTime: state.time.viewing
	}));

	const [getBay] = useBayStatuses(viewingTime, { length: 2 });
	const baySvg = useRef(null);

	useEffect(() => {
		if (!baySvg.current) return;
		// Find all the bay elements.
		const bayElements = baySvg.current.querySelectorAll(".bay-graphic-bay");
		// Iterate them.
		bayElements.forEach(bayElement => {
			// Finding the bay number from the id.
			const bayNumber = Number(/(\d{1,2})$/.exec(bayElement.id)[1]);
			// And get the status.
			const bayData = getBay(bayNumber);
			if (!bayData) return;
			const { status, type } = bayData;
			// Show it The right colour.
			if (status === SELECTED) {
				bayElement.querySelector("path").setAttribute("class", "state-selected selectedColor-fill bay-color");
			} else if (status === BOOKED) {
				bayElement.querySelector("path").setAttribute("class", "state-booked bookedColor-fill bay-color");
			} else if (status === DISABLED) {
				bayElement.querySelector("path").setAttribute("class", "state-selected nullColor-fill bay-color");
			} else if (type === "entertainment") {
				bayElement.querySelector("path").setAttribute("class", "state-available-entertainment availableColorEntertainment-fill bay-color");
			} else {
				bayElement.querySelector("path").setAttribute("class", "state-available bayColor-fill bay-color");
			}
		});
	}, [viewingTime, getBay]);

	function bayClicked(e) {

	}

	const textStyle = {
		fontFamily: "Montserrat",
		fontWeight: "bold",
		fontSize: "16.6px",
		fill: "#ffffff",
		// cursor: "pointer"
	}

	const bayStyle = {
		// cursor: "pointer"
	}

	return <div className="bay-graphic noSelect">
		<svg x="0px" y="0px" viewBox="0 0 1200 450" ref={baySvg}>

			<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="599.9963" y1="9" x2="599.9963" y2="579.3" gradientTransform="matrix(1 0 0 -1 0 700)">
				<stop offset="0.4287" style={{ stopColor: "#6BAA00", stopOpacity: 0 }} />
				<stop offset="1" style={{ stopColor: "#6BAA00", stopOpacity: 0.3 }} />
			</linearGradient>

			<path style={{ fill: "url(#SVGID_1_)" }} d="M122.5,441.3h955.7l92.6-246.8c0.2-1.1-0.5-2.2-1.6-2.4l-375.6-71.5H407.2L31.6,192.2c-1.1,0.2-1.8,1.3-1.6,2.4
	L122.5,441.3z"/>

			<g id="bays">
				<g className="bay-graphic-bay" id="bay-graphic-bay-12" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M449.43,113.79h30.37c1.15,0,2.08-0.93,2.08-2.08V65.55c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C447.35,112.86,448.28,113.79,449.43,113.79z"/>
					<text transform="matrix(1 0 0 1 456.6587 93.0078)" style={textStyle}>12</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-13" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M488.12,113.79h30.37c1.15,0,2.08-0.93,2.08-2.08V65.55c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C486.04,112.86,486.97,113.79,488.12,113.79z"/>
					<text transform="matrix(1 0 0 1 495.3296 93.0078)" style={textStyle}>13</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-14" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M526.76,113.79h30.37c1.15,0,2.08-0.93,2.08-2.08V65.55c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C524.69,112.86,525.62,113.79,526.76,113.79z"/>
					<text transform="matrix(1 0 0 1 533.1714 93.0078)" style={textStyle}>14</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-15" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M565.45,113.79h30.37c1.15,0,2.08-0.93,2.08-2.08V65.55c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C563.37,112.86,564.3,113.79,565.45,113.79z"/>
					<text transform="matrix(1 0 0 1 572.6392 93.0078)" style={textStyle}>15</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-16" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M604.14,113.79h30.37c1.15,0,2.08-0.93,2.08-2.08V65.55c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C602.06,112.86,602.99,113.79,604.14,113.79z"/>
					<text transform="matrix(1 0 0 1 610.9775 93.0078)" style={textStyle}>16</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-17" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M642.82,113.79h30.37c1.15,0,2.08-0.93,2.08-2.08V65.55c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C640.75,112.86,641.68,113.79,642.82,113.79z"/>
					<text transform="matrix(1 0 0 1 649.8037 93.0078)" style={textStyle}>17</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-18" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M681.51,113.79h30.37c1.15,0,2.08-0.93,2.08-2.08V65.55c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C679.43,112.86,680.36,113.79,681.51,113.79z"/>
					<text transform="matrix(1 0 0 1 688.1582 93.0078)" style={textStyle}>18</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-19" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M720.2,113.79h30.37c1.15,0,2.08-0.93,2.08-2.08V65.55c0-1.15-0.93-2.08-2.08-2.08H720.2
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C718.12,112.86,719.05,113.79,720.2,113.79z"/>
					<text transform="matrix(1 0 0 1 727.0361 93.0078)" style={textStyle}>19</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-20" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M758.88,113.79h30.74c1.07,0,1.97-0.82,2.07-1.89l4.3-46.16c0.11-1.22-0.85-2.27-2.07-2.27h-35.04
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C756.81,112.86,757.74,113.79,758.88,113.79z"/>
					<text transform="matrix(1 0 0 1 763.7285 93.0078)" style={textStyle}>20</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-11" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M410.37,113.79h30.74c1.15,0,2.08-0.93,2.08-2.08V65.55c0-1.15-0.93-2.08-2.08-2.08h-35.04
			c-1.22,0-2.18,1.05-2.07,2.27l4.3,46.16C408.4,112.98,409.3,113.79,410.37,113.79z"/>
					<text transform="matrix(1 0 0 1 419.6191 93.0078)" style={textStyle}>11</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-42" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M449.43,59.32h30.37c1.15,0,2.08-0.93,2.08-2.08V11.08c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C447.35,58.39,448.28,59.32,449.43,59.32z"/>
					<text transform="matrix(1 0 0 1 454.1895 38.5317)" style={textStyle}>42</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-43" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M488.12,59.32h30.37c1.15,0,2.08-0.93,2.08-2.08V11.08c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C486.04,58.39,486.97,59.32,488.12,59.32z"/>
					<text transform="matrix(1 0 0 1 492.8604 38.5317)" style={textStyle}>43</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-44" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M526.76,59.32h30.37c1.15,0,2.08-0.93,2.08-2.08V11.08c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C524.69,58.39,525.62,59.32,526.76,59.32z"/>
					<text transform="matrix(1 0 0 1 530.7021 38.5317)" style={textStyle}>44</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-45" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M565.45,59.32h30.37c1.15,0,2.08-0.93,2.08-2.08V11.08c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C563.37,58.39,564.3,59.32,565.45,59.32z"/>
					<text transform="matrix(1 0 0 1 570.1699 38.5317)" style={textStyle}>45</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-46" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M604.14,59.32h30.37c1.15,0,2.08-0.93,2.08-2.08V11.08c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C602.06,58.39,602.99,59.32,604.14,59.32z"/>
					<text transform="matrix(1 0 0 1 608.5078 38.5317)" style={textStyle}>46</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-47" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M642.82,59.32h30.37c1.15,0,2.08-0.93,2.08-2.08V11.08c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C640.75,58.39,641.68,59.32,642.82,59.32z"/>
					<text transform="matrix(1 0 0 1 647.334 38.5317)" style={textStyle}>47</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-48" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M681.51,59.32h30.37c1.15,0,2.08-0.93,2.08-2.08V11.08c0-1.15-0.93-2.08-2.08-2.08h-30.37
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C679.43,58.39,680.36,59.32,681.51,59.32z"/>
					<text transform="matrix(1 0 0 1 685.6885 38.5317)" style={textStyle}>48</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-49" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M720.2,59.32h30.37c1.15,0,2.08-0.93,2.08-2.08V11.08c0-1.15-0.93-2.08-2.08-2.08H720.2
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C718.12,58.39,719.05,59.32,720.2,59.32z"/>
					<text transform="matrix(1 0 0 1 724.5664 38.5317)" style={textStyle}>49</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-50" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M758.88,59.32h35.81c1.07,0,1.97-0.82,2.07-1.89l4.3-46.16c0.11-1.22-0.85-2.27-2.07-2.27h-40.11
			c-1.15,0-2.08,0.93-2.08,2.08v46.16C756.81,58.39,757.74,59.32,758.88,59.32z"/>
					<text transform="matrix(1 0 0 1 763.6865 38.5317)" style={textStyle}>50</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-41" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M405.3,59.32h35.81c1.15,0,2.08-0.93,2.08-2.08V11.08c0-1.15-0.93-2.08-2.08-2.08H401
			c-1.22,0-2.18,1.05-2.07,2.27l4.3,46.16C403.33,58.5,404.23,59.32,405.3,59.32z"/>
					<text transform="matrix(1 0 0 1 417.1494 38.5317)" style={textStyle}>41</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-22" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M835.91,121.74l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C834.03,120.44,834.78,121.52,835.91,121.74z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 845.7433 102.1953)" style={textStyle}>22</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-23" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M873.93,128.88l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C872.06,127.58,872.8,128.66,873.93,128.88z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 883.7505 109.3289)" style={textStyle}>23</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-24" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M911.91,136.01l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C910.04,134.71,910.78,135.8,911.91,136.01z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 920.9429 116.3095)" style={textStyle}>24</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-25" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M949.94,143.15l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C948.06,141.85,948.81,142.93,949.94,143.15z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 959.7343 123.5902)" style={textStyle}>25</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-26" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M987.96,150.29l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C986.09,148.99,986.83,150.07,987.96,150.29z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 997.4143 130.6624)" style={textStyle}>26</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-27" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M1025.98,157.42l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C1024.11,156.13,1024.85,157.21,1025.98,157.42z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 1035.5741 137.8251)" style={textStyle}>27</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-28" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M1064,164.56l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43l-29.85-5.6
			c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C1062.13,163.27,1062.87,164.35,1064,164.56z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 1073.2704 144.8998)" style={textStyle}>28</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-29" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M1102.02,171.7l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C1100.15,170.4,1100.9,171.49,1102.02,171.7z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 1111.4821 152.0722)" style={textStyle}>29</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-30" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M1140.05,178.84l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C1138.17,177.54,1138.92,178.63,1140.05,178.84z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 1149.1448 159.1406)" style={textStyle}>30</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-21" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M797.52,114.53l30.21,5.67c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-34.44-6.47c-1.2-0.23-2.34,0.63-2.45,1.85l-4.3,46.16C795.74,113.36,796.47,114.33,797.52,114.53z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 809.3391 95.3622)" style={textStyle}>21</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-52" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M845.96,68.2l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43l-29.85-5.6
			c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C844.09,66.9,844.83,67.98,845.96,68.2z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 855.7524 48.6456)" style={textStyle}>52</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-53" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M883.98,75.33l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43l-29.85-5.6
			c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C882.11,74.04,882.85,75.12,883.98,75.33z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 893.7595 55.7801)" style={textStyle}>53</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-54" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M921.97,82.47l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43l-29.85-5.6
			c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C920.09,81.17,920.84,82.26,921.97,82.47z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 930.952 62.7598)" style={textStyle}>54</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-55" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M959.99,89.61l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43l-29.85-5.6
			c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C958.12,88.31,958.86,89.39,959.99,89.61z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 969.7425 70.0404)" style={textStyle}>55</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-56" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M998.01,96.75l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43l-29.85-5.6
			c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C996.14,95.45,996.88,96.53,998.01,96.75z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 1007.4232 77.1141)" style={textStyle}>56</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-57" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M1036.03,103.88l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C1034.16,102.59,1034.9,103.67,1036.03,103.88z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 1045.584 84.276)" style={textStyle}>57</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-58" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M1074.05,111.02l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C1072.18,109.73,1072.93,110.81,1074.05,111.02z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 1083.2794 91.3506)" style={textStyle}>58</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-59" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M1112.08,118.16l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43
			l-29.85-5.6c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C1110.2,116.86,1110.95,117.95,1112.08,118.16z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 1121.4912 98.522)" style={textStyle}>59</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-60" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M1150.1,125.3l29.85,5.6c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43l-29.85-5.6
			c-1.13-0.21-2.21,0.53-2.43,1.66l-8.52,45.37C1148.23,124,1148.97,125.09,1150.1,125.3z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 1158.8263 105.5309)" style={textStyle}>60</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-51" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M802.59,60.05l35.2,6.61c1.13,0.21,2.21-0.53,2.43-1.66l8.52-45.37c0.21-1.13-0.53-2.21-1.66-2.43l-39.42-7.4
			c-1.2-0.23-2.34,0.63-2.45,1.85l-4.3,46.16C800.8,58.89,801.54,59.85,802.59,60.05z"/>
					<text transform="matrix(0.9828 0.1845 -0.1845 0.9828 819.3469 41.8137)" style={textStyle}>51</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-2" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M57.18,130.28l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C57.71,128.06,56.97,129.15,57.18,130.28z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 74.489 154.7261)" style={textStyle}>2</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-3" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M95.2,123.14l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C95.73,120.93,94.99,122.01,95.2,123.14z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 112.4936 147.5833)" style={textStyle}>3</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-4" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M133.19,116.01l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C133.72,113.79,132.98,114.88,133.19,116.01z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 149.6851 140.5934)" style={textStyle}>4</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-5" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M171.21,108.87l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C171.74,106.65,171,107.74,171.21,108.87z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 188.4733 133.3033)" style={textStyle}>5</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-6" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M209.23,101.73l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C209.76,99.52,209.02,100.6,209.23,101.73z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 226.1515 126.2219)" style={textStyle}>6</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-7" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M247.25,94.59l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C247.79,92.38,247.04,93.46,247.25,94.59z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 264.3111 119.05)" style={textStyle}>7</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-8" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M285.28,87.45l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C285.81,85.24,285.06,86.32,285.28,87.45z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 302.0055 111.9656)" style={textStyle}>8</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-9" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M323.3,80.31l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C323.83,78.1,323.09,79.18,323.3,80.31z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 340.214 104.7845)" style={textStyle}>9</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-10" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M361.32,73.17l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l30.21-5.67c1.05-0.2,1.79-1.17,1.69-2.24l-4.3-46.16
			c-0.11-1.22-1.25-2.08-2.45-1.85l-34.44,6.47C361.85,70.96,361.11,72.05,361.32,73.17z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 374.893 98.2668)" style={textStyle}>10</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-1" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M19.16,137.42l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C19.69,135.2,18.95,136.29,19.16,137.42z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 38.0868 161.5677)" style={textStyle}>1</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-32" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M47.13,76.74l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C47.66,74.53,46.92,75.61,47.13,76.74z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 59.7935 102.0586)" style={textStyle}>32</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-33" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M85.15,69.6l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C85.68,67.39,84.94,68.47,85.15,69.6z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 97.799 94.9157)" style={textStyle}>33</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-34" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M123.14,62.47l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C123.67,60.25,122.92,61.34,123.14,62.47z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 134.9892 87.926)" style={textStyle}>34</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-35" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M161.16,55.33l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C161.69,53.11,160.95,54.2,161.16,55.33z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 173.7779 80.6359)" style={textStyle}>35</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-36" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M199.18,48.19l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C199.71,45.98,198.97,47.06,199.18,48.19z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 211.4565 73.5544)" style={textStyle}>36</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-37" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M237.2,41.05l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C237.73,38.84,236.99,39.92,237.2,41.05z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 249.6156 66.3826)" style={textStyle}>37</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-38" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M275.22,33.91l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C275.75,31.7,275.01,32.78,275.22,33.91z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 287.3096 59.2982)" style={textStyle}>38</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-39" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M313.25,26.77l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C313.78,24.56,313.03,25.64,313.25,26.77z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 325.5186 52.1171)" style={textStyle}>39</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-40" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M351.27,19.63L359.79,65c0.21,1.13,1.3,1.87,2.43,1.66l35.2-6.61c1.05-0.2,1.79-1.17,1.69-2.24l-4.3-46.16
			c-0.11-1.22-1.25-2.08-2.45-1.85l-39.42,7.4C351.8,17.42,351.06,18.51,351.27,19.63z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 362.4035 45.1847)" style={textStyle}>40</text>
				</g>
				<g className="bay-graphic-bay" id="bay-graphic-bay-31" onClick={bayClicked}>
					<path style={bayStyle} className="nullColor-fill" d="M9.11,83.88l8.52,45.37c0.21,1.13,1.3,1.87,2.43,1.66l29.85-5.6c1.13-0.21,1.87-1.3,1.66-2.43l-8.52-45.37
			c-0.21-1.13-1.3-1.87-2.43-1.66l-29.85,5.6C9.64,81.66,8.89,82.75,9.11,83.88z"/>
					<text transform="matrix(0.9828 -0.1847 0.1847 0.9828 23.3918 108.9001)" style={textStyle}>31</text>
				</g>
			</g>
		</svg>
	</div>
}