export const SET_CURRENT_TIME = "SET_CURRENT_TIME";
export const SET_VIEWING_TIME = "SET_VIEWING_TIME";

// Both functions take a moment date as an argument
export function setCurrentTime(time) {
    return {
        type: SET_CURRENT_TIME,
        time
    }
}

export function setViewingTime(time) {
    return {
        type: SET_VIEWING_TIME,
        time
    }
}