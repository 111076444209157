import ws from "./assets/Websocket";
import "core-js";
import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { render } from "react-dom";
import moment from "moment";
import { COOKIE_NAME } from "./constants";
// Redux
import { applyMiddleware, createStore, compose } from "redux";
import { Provider, useDispatch, useSelector } from "react-redux";
import thunk from "redux-thunk";
import reducers, { initialState } from "./reducers";
import { requestCurrentUser } from "./actions/session";
import { getSettings } from "./actions/settings";
import { setCurrentTime } from "./actions/time";
// Helper functions
import { getCookies, useInterval } from "./helpers";
// Components
import { ModalProvider } from "./assets/useModal";
import PasswordReset from "./passwordReset";
import ConfirmedEmail from "./confirmedEmail";
import Admin from "./admin";
import Booking from "./booking";
import { Spinner } from "./assets";
import { GET_PUBLIC_BOOKINGS_SUCCESS, REMOVE_PUBLIC_BOOKINGS } from "./actions/publicBookings";

// Init the redux store.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    initialState,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

function AppWrapper() {

    const dispatch = useDispatch();
    const hasSettings = useSelector(state => Boolean(state.settings.id), (before, after) => before === after);

    // Bind the websocket events.
    useEffect(() => {
        ws.on("booking:update", publicBookings => {
            dispatch({
                type: GET_PUBLIC_BOOKINGS_SUCCESS,
                publicBookings
            });
        });
        ws.on("booking:delete", publicBookings => {
            dispatch({
                type: REMOVE_PUBLIC_BOOKINGS,
                publicBookings
            });
        });
    }, [dispatch]);

    useEffect(() => {
        const cookies = getCookies();
        dispatch(getSettings());
        if (cookies[COOKIE_NAME]) dispatch(requestCurrentUser());
    }, [dispatch]);

    useInterval(() => {
		dispatch(setCurrentTime(moment()));
	}, 10000);

    if (!hasSettings) return <Spinner />;

    return <Switch>
        <Route path="/password-reset" render={router => (
            <PasswordReset />
        )} />
        <Route path="/confirmed-email" render={router => (
            <ConfirmedEmail />
        )} />
        <Route path="/booking" render={router => (
            <Booking />
        )} />
        <Route path="/admin" render={router => (
            <Admin />
        )} />
        <Route path="/" render={router => (
            <Redirect to="/booking" />
        )} />
    </Switch>
}

render(
    <Provider store={store}>
        <BrowserRouter>
            <ModalProvider>
                <AppWrapper />
            </ModalProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);