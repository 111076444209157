import React from "react";
import { asQuestion } from "../QuestionSet";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

function DurationSlider({ options = [], value, onChange }) {

	const selectedSection = useRef(null);
	const [nodePosition, setNodePosition] = useState(0);

	useEffect(() => {
		function resize() {
			if (selectedSection.current)
				setNodePosition(selectedSection.current.offsetLeft + selectedSection.current.offsetWidth);
		}
		resize();
		window.addEventListener("resize", resize);
		return () => {
			window.removeEventListener("resize", resize);
		}
	}, [value]);

	const currentValueIndex = options.findIndex(option => option.value === value);

	return <div className="durationSlider">
		<div className="slider-inner">
			<div className="slider-sections flexy">
				{options.map(({ disabled, label, value: optionValue }, index) => {
					return <div
						key={optionValue}
						onClick={!disabled ? e => onChange(optionValue) : null}
						ref={optionValue === value ? selectedSection : null}
						className={[
							"slider-section",
							disabled && "slider-section-disabled",
							(index > currentValueIndex) && "slider-section-passed",
							(index === currentValueIndex) && "slider-section-selected",
						].filter(Boolean).join(" ")}
					>
						<div className="section-node" />
						{label && <div className="section-label featureFont">
							{label}
						</div>}
					</div>
				})}
			</div>
			<div className="slider-fill" style={{ width: nodePosition }} />
			<div className="slider-node" style={{ left: nodePosition }} />
		</div>
	</div>
}

function Component({ value, onChange }) {

	const { slots } = value;

	return <div>
		<h4><b>How long</b> do you want to play?</h4>
		<br />
		<div className="details">
			If you want to book a session for longer than 2 hours, <a href="https://greenwichpeninsulagolfrange.com/location" target="_blank" rel="noopener noreferrer">give us a call</a>.
		</div>
		<br />
		<DurationSlider
			value={slots || 2}
			onChange={slots => onChange({ slots })}
			options={[
				{
					label: "1 Hour",
					value: 2,
				},
				{
					label: "2 Hours",
					value: 4,
				},
				{
					label: "More?",
					disabled: true,
					value: null
				}
			]}
		/>
		<br />
	</div>
}

function validator({ slots = null }) {
    if (!slots) return "You have to select how long you wat to play for."
    return false; 
}

const Question = asQuestion(Component, { validator });
export default Question
