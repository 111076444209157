export const API_ROOT = process.env.REACT_APP_API_ROOT || "http://localhost:8082";
export const WS_API_ROOT = process.env.REACT_APP_WS_API_ROOT || "http://localhost:8082";
export const COOKIE_NAME = "n1_token";
export const VIEWING_DATE_COOKIE = "vd";
export const MAX_LOG_AGE = 5;
export const MAX_CONCURRENT_BOOKINGS = 2;
export const MAX_PEOPLE_PER_SESSION = 2;
export const MIN_CANCEL_ORDER_HOURS = 24;
export const RANGE_TYPE_PRIORITIES = ["time-ranges.date-range", "time-ranges.day-of-week-range"];
export const MINIMUM_HOURS = 1;
export const INACTIVE_TIMEOUT = 360000; // Number of milliseconds before a custoemr is considered inactive.

export const BOOKED = "BOOKED";
export const SELECTED = "SELECTED";
export const AVAILABLE = "AVAILABLE";
export const DISABLED = "DISABLED";