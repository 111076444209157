import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { createOrder } from "../../actions/orders";
import { setOrderPaid, orderWithLoyaltyCard } from "../../actions/newOrder";
import { logout } from "../../actions/session";
import { formatOrder } from "../../helpers";
import { Button, CheckboxField } from "../../fields";
import { ImgIcon } from "../../assets";
import { logoutIcon, search, user as userIcon, settings, chart } from "../../icons";
import BookingGroup from "./BookingGroup";
import UserSection from "./UserSection";
import { useModal } from "../../assets/useModal";
import OrdersLookup from "./OrdersLookup";
import UsersLookup from "./UsersLookup";
import OrdersReport from "./OrdersReport";
import Settings from "./Settings";

export default function Tools() {

	const dispatch = useDispatch();
	const { newOrder, createLoading } = useSelector(state => ({
		newOrder: state.newOrder,
		createLoading: state.orders.createLoading
	}));
	const { setModal, openModal } = useModal();
	let canCreate = Boolean(newOrder.user && newOrder.bookingGroups.length);

    return (
        <div className="tools">
            <div className="btn-row flexy">
                <div
                    title="orders" 
                    className="pointer admin-btn text-center"
                    onClick={() => {
                        setModal(
                            <OrdersLookup />
                        );
                        openModal();
                    }}
                >
                    <ImgIcon
                        cursor="pointer"    
                        size={20}
                        src={search}
                    />
                </div>
                <div
                    title="customers" 
                    className="pointer admin-btn text-center"
                    onClick={() => {
                        setModal(
                            <UsersLookup />
                        );
                        openModal();
                    }}
                >
                    <ImgIcon
                        cursor="pointer"
                        size={20}
                        src={userIcon}
                    />
                </div>
                <div
                    title="reports" 
                    className="pointer admin-btn text-center"
                    onClick={() => {
                        setModal(
                            <OrdersReport />
                        );
                        // setTimeout(openModal, 0)
                        openModal();
                    }}
                >
                    <ImgIcon
                        cursor="pointer"
                        size={20}
                        src={chart}
                    />
                </div>
                <div
                    title="settings" 
                    className="pointer admin-btn text-center"
                    onClick={() => {
                        setModal(
                            <Settings />
                        );
                        openModal();
                    }}
                >
                    <ImgIcon
                        cursor="pointer"
                        size={20}
                        src={settings}
                    />
                </div>
                <div
                    title="user logout" 
                    className="pointer admin-btn text-center"
                    onClick={() => dispatch(logout())}
                >
                    <ImgIcon
                        cursor="pointer"
                        size={20}
                        src={logoutIcon}
                    />
                </div>
            </div>
            <div style={{ padding: "20px" }}>
                {newOrder.bookingGroups.map((group, index) => <BookingGroup {...group} index={index} key={index} />)}
                <UserSection />
                <hr style={{ borderTop: "1px solid #333" }} />
                <div style={{ marginBottom: "15px" }}>
                    <CheckboxField 
                        label={<span>Create as <b>Paid</b></span>}
                        value={newOrder.paid}
                        onChange={val => dispatch(setOrderPaid(val))}
                    />
                </div>
                <div>
                    <CheckboxField 
                        label={<span>With <b>Loyalty Card</b></span>}
                        value={newOrder.withLoyaltyCard}
                        onChange={val => dispatch(orderWithLoyaltyCard(val))}
                    />
                </div>
                <div className="booking">
                    <Button
                        spinnerSize={21}
                        spinnerColor={"#333333"}
                        className="rounded"
                        disabled={!canCreate}
                        loading={createLoading}
                        onClick={() => dispatch(createOrder(formatOrder(newOrder)))}
                    >
                        BOOK BAYS
                    </Button>
				</div>
			</div>
		</div>
	)
}
