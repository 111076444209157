import axios from "axios";
import { API_ROOT } from "../constants";
import { addLog } from "./logger";
import { errorResponse } from "../helpers";

export const GET_MY_ORDERS_START = "GET_MY_ORDERS_START";
export const GET_MY_ORDERS_SUCCESS = "GET_MY_ORDERS_SUCCESS";
export const GET_MY_ORDERS_FAIL = "GET_MY_ORDERS_FAIL";
export const GET_MY_ORDERS_COUNT_START = "GET_MY_ORDERS_COUNT_START";
export const GET_MY_ORDERS_COUNT_SUCCESS = "GET_MY_ORDERS_COUNT_SUCCESS";
export const GET_MY_ORDERS_COUNT_FAIL = "GET_MY_ORDERS_COUNT_FAIL";

export const CANCEL_ORDER_START = "CANCEL_ORDER_START";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL";

export function getMyOrders(userId, start = 0, limit = 10) {
	return function (dispatch, getState) {

		dispatch({
			type: GET_MY_ORDERS_START
		})

		// Get the current token
		const { session: { token } } = getState();

		axios.get(`${API_ROOT}/orders?user=${userId}&_start=${start}&_limit=${limit}&_sort=created_at:DESC`, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(orders => {
			dispatch({
				type: GET_MY_ORDERS_SUCCESS,
				orders
			})
		}).catch(err => {
			console.error("Error getting orders: ", err);
			dispatch({
				type: GET_MY_ORDERS_FAIL
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}))
		})
	}
}

export function getMyOrdersCount(userId) {
	return function (dispatch, getState) {

		dispatch({
			type: GET_MY_ORDERS_COUNT_START
		})

		// Get current token
		const { session: { token } } = getState();

		axios.get(`${API_ROOT}/orders/count?user=${userId}`, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(count => {
			dispatch({
				type: GET_MY_ORDERS_COUNT_SUCCESS,
				count
			});
		}).catch(err => {
			console.error("Error getting order count: ", err);
			dispatch({
				type: GET_MY_ORDERS_COUNT_FAIL
			})
		})

	}
}

export function cancelOrder(orderId) {

	return function (dispatch, getState) {

		dispatch({
			type: CANCEL_ORDER_START
		});

		// Get the current token
		const { session: { token } } = getState();

		axios.get(`${API_ROOT}/orders/cancel/${orderId}`, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(order => {
			dispatch({
				type: CANCEL_ORDER_SUCCESS,
				order
			});
		}).catch(err => {
			console.error("Error cancelling order: ", err);
			dispatch({
				type: CANCEL_ORDER_FAIL,
				orderId
			});
			// Error message
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}));
		});
	}
}