import { initialState } from ".";
import { 
    GET_USERS_START, 
    GET_USERS_SUCCESS, 
    GET_USERS_FAIL, 
    UPDATE_USER_START, 
    UPDATE_USER_SUCCESS, 
    UPDATE_USER_FAIL, 
    CREATE_USER_START, 
    CREATE_USER_SUCCESS, 
    CREATE_USER_FAIL, 
    SEARCH_USERS_START, 
    SEARCH_USERS_SUCCESS, 
    SEARCH_USERS_FAIL, 
    GET_SYSTEM_USERS_START, 
    GET_SYSTEM_USERS_SUCCESS, 
    GET_SYSTEM_USERS_FAIL } from "../actions/users";
import { getUnique } from "../helpers";

export default function (state = initialState.users, action) {
    switch (action.type) {

        // Getting users
        case GET_USERS_START:
            return {
                ...state,
                loading: true
            };

        case GET_USERS_SUCCESS:
            return {
                ...state,
                items: action.users,
                loading: false
            };

        case GET_USERS_FAIL:
            return {
                ...state,
                loading: false
            };

        // Updating user 
        case UPDATE_USER_START:
            return {
                ...state,
                items: state.items.map(item => item.id === action.id ? { ...item, loading: true } : item)
            };

        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                items: state.items.map(item => item.id === action.user.id ? { ...action.user, loading: false } : item)
            };

        case UPDATE_USER_FAIL:
            return {
                ...state,
                items: state.items.map(item => item.id === action.id ? { ...item, loading: false }: item)
            };

        // Creating user  
        case CREATE_USER_START:
            return {
                ...state,
                loading: true
            };

        case CREATE_USER_SUCCESS:
            return {
                ...state,
                items: [...state.items, action.item],
                loading: false
            };

        case CREATE_USER_FAIL:
            return {
                ...state,
                loading: false
            };

        case SEARCH_USERS_START:
            return {
                ...state,
                loading: true
            };

        case SEARCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: getUnique([...state.items].concat(action.users), "id")
            }

        case SEARCH_USERS_FAIL:
            return {
                ...state,
                loading: false
            };

        case GET_SYSTEM_USERS_START:
            return {
                ...state,
                loading: true
            };

        case GET_SYSTEM_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: getUnique([...state.items].concat(action.users), "id")
            }

        case GET_SYSTEM_USERS_FAIL:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }

}