export const ADD_ORDER_BOOKING = "ADD_ORDER_BOOKING";
export const REMOVE_ORDER_BOOKING = "REMOVE_ORDER_BOOKING";
export const ADD_BOOKING_EXTRA = "ADD_BOOKING_EXTRA";
export const REMOVE_BOOKING_EXTRA = "REMOVE_BOOKING_EXTRA";
export const SET_ORDER_USER = "SET_ORDER_USER";
export const REMOVE_ORDER_USER = "REMOVE_ORDER_USER";
export const CLEAR_ORDER_ALL = "CLEAR_ORDER_ALL";
export const SET_BOOKING_PEOPLE = "SET_BOOKING_PEOPLE";
export const SET_ORDER_PAID = "SET_ORDER_PAID";
export const ORDER_WITH_LOYALTY_CARD = "ORDER_WITH_LOYALTY_CARD";

export function addBooking(booking) {
    return {
        type: ADD_ORDER_BOOKING,
        booking
    }
}

export function removeBooking(booking) {
    return {
        type: REMOVE_ORDER_BOOKING,
        booking
    }
}

export function addExtra(groupIndex, extra) {
    return {
        type: ADD_BOOKING_EXTRA,
        groupIndex,
        extra
    }
}

export function removeExtra(groupIndex, clubId) {
    return {
        type: REMOVE_BOOKING_EXTRA,
        groupIndex,
        clubId
    }
}

export function setPeople(groupIndex, personType, number) {
    return {
        type: SET_BOOKING_PEOPLE,
        groupIndex,
        personType,
        number
    }
}

export function setUser(user) {
    return {
        type: SET_ORDER_USER,
        user
    }
}

export function removeUser() {
    return {
        type: REMOVE_ORDER_USER
    }
}

export function clearNewOrder() {
    return {
        type: CLEAR_ORDER_ALL
    }
}

export function setOrderPaid(paid) {
    return {
        type: SET_ORDER_PAID,
        paid
    }
}

export function orderWithLoyaltyCard(loyalty) {
    return {
        type: ORDER_WITH_LOYALTY_CARD,
        loyalty
    }
}