import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setViewingTime } from "../../actions/time";
import { chevron, calendar } from "../../icons";
import { PaddedIcon, CalendarField } from "../../assets";

export default function DateSelector() {

    const dispatch = useDispatch();
    const { viewing } = useSelector(state => state.time);
    const [showCalendar, setShowCalendar] = useState(false);

    let ref = useRef(null);

    useEffect(() => {
        // Hiding the calendar when clicked outside of it
        function close(e) {
            if (showCalendar && !ref.current.contains(e.target)) setShowCalendar(false)
        };

        if (showCalendar) document.addEventListener("click", close);

        return () => {
            document.removeEventListener("click", close);
        }

    }, [showCalendar, setShowCalendar, ref]);

    return <div className="date-selector">
        <PaddedIcon
            size={25}
            src={chevron}
            className="shadow"
            cursor="pointer"
            onClick={() => dispatch(setViewingTime(viewing.clone().subtract(1, "d")))}
            style={{
                marginLeft: "15px",
                transform: "rotate(-90deg)"
            }}
        />
        <div className="day text-center">
            <div><b>{viewing.format("dddd")}</b></div>
            <div>{viewing.format("Do MMMM")}</div>
        </div>
        <PaddedIcon
            size={25}
            src={chevron}
            className="shadow"
            cursor="pointer"
            onClick={() => dispatch(setViewingTime(viewing.clone().add(1, "d")))}
            style={{
                marginLeft: "15px",
                transform: "rotate(90deg)"
            }}
        />
        <PaddedIcon
            size={25}
            src={calendar}
            className="shadow"
            cursor="pointer"
            onClick={() => setShowCalendar(!showCalendar)}
            style={{
                marginLeft: "15px"
            }}
        />
        <div className={"calendar-wrapper shadow" + (showCalendar ? " visible" : "")} ref={ref}>
            <CalendarField
                value={viewing.clone()}
                onChange={val => {
                    // make sure you only change the day and keep hours minutes and seconds from the current value
                    let hour = viewing.hour();
                    let minute = viewing.minute();
                    dispatch(setViewingTime(val.set({ hour, minute, second: 0, millisecond: 0 })));
                    setShowCalendar(false);
                }}
            />
        </div>
    </div>
}