import moment from "moment";
import { initialState } from ".";
import {
	ADD_CUSTOMER_BOOKING_START,
	ADD_CUSTOMER_BOOKING_SUCCESS,
	ADD_CUSTOMER_BOOKING_FAIL,
	REMOVE_CUSTOMER_BOOKING_START,
	REMOVE_CUSTOMER_BOOKING_SUCCESS,
	REMOVE_CUSTOMER_BOOKING_FAIL,
	UPDATE_CUSTOMER_BOOKING_START,
	UPDATE_CUSTOMER_BOOKING_SUCCESS,
	UPDATE_CUSTOMER_BOOKING_FAIL,
	GET_CUSTOMER_ORDER_START,
	GET_CUSTOMER_ORDER_SUCCESS,
	GET_CUSTOMER_ORDER_FAIL,
	CHECKOUT_START,
	CHECKOUT_FAIL,
    CHECKOUT_SUCCESS,
    LOYALTY_CHECKOUT_START,
    LOYALTY_CHECKOUT_FAIL,
    LOYALTY_CHECKOUT_SUCCESS,
    PING_CUSTOMER_BOOKING_START,
    PING_CUSTOMER_BOOKING_SUCCESS,
    PING_CUSTOMER_BOOKING_FAIL
} from "../actions/customerBookings";
import { mergeObjectArrays } from "../helpers";
import { LOGOUT } from "../actions/session";
import { REMOVE_PUBLIC_BOOKINGS } from "../actions/publicBookings";

export default function (state = initialState.customerBookings, action) {
	switch (action.type) {

		case CHECKOUT_START:
		case UPDATE_CUSTOMER_BOOKING_START:
		case ADD_CUSTOMER_BOOKING_START:
		case REMOVE_CUSTOMER_BOOKING_START:
		case GET_CUSTOMER_ORDER_START:
        case LOYALTY_CHECKOUT_START:
			return {
				...state,
				loading: true
			}

		case CHECKOUT_FAIL:
		case UPDATE_CUSTOMER_BOOKING_FAIL:
		case GET_CUSTOMER_ORDER_FAIL:
		case ADD_CUSTOMER_BOOKING_FAIL:
		case REMOVE_CUSTOMER_BOOKING_FAIL:
        case LOYALTY_CHECKOUT_FAIL:
			return {
				...state,
				loading: false
			}

		case GET_CUSTOMER_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				items: action.order.bookings
			}

		case ADD_CUSTOMER_BOOKING_SUCCESS:
			return {
				...state,
				loading: false,
				items: [...state.items, action.booking]
			}

		case REMOVE_CUSTOMER_BOOKING_SUCCESS:
			return {
				...state,
				loading: false,
				items: state.items.filter(booking => !(
					action.booking.bay.number === booking.bay.number &&
					action.booking.slot.from === booking.slot.from &&
					moment(action.booking.date).isSame(moment(booking.date), "day")
				))
			}

		case UPDATE_CUSTOMER_BOOKING_SUCCESS:
			return {
				...state,
				loading: false,
				items: mergeObjectArrays(state.items, [action.booking], (a, b) => a.id === b.id)
			}

		case LOGOUT:
		case CHECKOUT_SUCCESS:
        case LOYALTY_CHECKOUT_SUCCESS:
			return {
				...state,
				loading: false,
				items: []
			}
		
		// If the user's booking is a removed public booking, best to clear that up too.
		case REMOVE_PUBLIC_BOOKINGS:
			return {
				...state,
				items: state.items.filter(booking => !action.publicBookings.find(ab => ab.id === booking.id))
            }
            
        case PING_CUSTOMER_BOOKING_START:
        case PING_CUSTOMER_BOOKING_FAIL:
            return {
                ...state
            }

        case PING_CUSTOMER_BOOKING_SUCCESS:
            return {
                ...state,
                items: mergeObjectArrays(state.items, action.bookings, (a, b) => a.id === b.id)
            }

		default:
			return state
	}
}