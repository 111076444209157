import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/session";
import { user } from "../icons";
import { ImgIcon } from ".";
import { useModal } from "./useModal";
import { Button } from "../fields";
import Login from "./login";
import { usePrevious } from "../helpers";

export default function LoginWidget() {

    const dispatch = useDispatch();
    const { openModal, setModal, modalOpen, closeModal } = useModal();
    const { loggedUser, loading } = useSelector(state => ({
        loggedUser: state.session.user,
        loading: state.session.loading
    }));

    const isLogged = Boolean(loggedUser);
    const wasLogged = usePrevious(isLogged);

    useEffect(() => {
        if (isLogged && !wasLogged && modalOpen) {
            closeModal();
            setTimeout(() => {
                setModal(null);
            }, 200);
        }
    }, [modalOpen, closeModal, isLogged, wasLogged, setModal]);

    return (
        <div className="login-widget">
            {loggedUser
                ?
                <Link className="icon pointer" to="/booking/my-orders">
                    <ImgIcon cursor="pointer" size={50} src={user} style={{ backgroundSize: "65%" }} />
                </Link>
                :
                <ImgIcon className="icon" size={50} src={user} style={{ backgroundSize: "65%" }} />
            }
            <div>
                <span className="featureFont">{isLogged ? loggedUser.email : "not logged in"}</span>
                <Button
                    loading={loading}
                    onClick={() => {
                        if (isLogged) {
                            dispatch(logout())
                        } else {
                            setModal(
                                <div style={{ width: "400px", maxWidth: "100%" }}>
                                    <Login />
                                </div>, { className: "size-to-content", closable: true, clearOnClose: true }
                            );
                            openModal();
                        }
                    }}
                >
                    {isLogged ? "Log out" : "Log in"}
                </Button>
            </div>
        </div>
    )

}