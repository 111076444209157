import { initialState } from ".";
import { GET_SLOTS_START, GET_SLOTS_SUCCESS, GET_SLOTS_FAIL } from "../actions/slots";

export default function (state = initialState.slots, action) {

    switch (action.type) {
        // Getting clubs
        case GET_SLOTS_START:
            return {
                ...state,
                loading: true
            };

        case GET_SLOTS_SUCCESS:
            return {
                ...state,
                items: action.slots,
                loading: false
            };

        case GET_SLOTS_FAIL:
            return {
                ...state,
                loading: false
            };

        default:
            return state

    }
}