import axios from "axios";
import { API_ROOT } from "../constants";
import { addLog } from "./logger";
import { setUser } from "./newOrder";
import { errorResponse } from "../helpers";

export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const SEARCH_USERS_START = "SEARCH_USERS_START";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAIL = "SEARCH_USERS_FAIL";
export const GET_SYSTEM_USERS_START = "GET_SYSTEM_USERS_START";
export const GET_SYSTEM_USERS_SUCCESS = "GET_SYSTEM_USERS_SUCCESS";
export const GET_SYSTEM_USERS_FAIL = "GET_SYSTEM_USERS_FAIL";

export function getUsers() {
	return function (dispatch, getState) {

		dispatch({
			type: GET_USERS_START
		});

		// Get the current token
		const { session: { token } } = getState();

		axios.get(`${API_ROOT}/users`, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(users => {
			dispatch({
				type: GET_USERS_SUCCESS,
				users
			});
		}).catch(err => {
			console.error("Error getting lists: ", err);
			dispatch({
				type: GET_USERS_FAIL
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}));
		});
	}
}

export function createUser(user, set = false, cb) {
	return function (dispatch, getState) {

		dispatch({
			type: CREATE_USER_START
		});

		// Get the current token
		const { session: { token } } = getState();

		// Create call
		axios.post(`${API_ROOT}/users`, { ...user }, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(item => {
			dispatch({
				type: CREATE_USER_SUCCESS,
				item
			});
			// optionally setting newOrder user
			if (set) dispatch(setUser(item));
			// Run callback if passed
			if (cb) cb();
			// Success message
			dispatch(addLog({
				type: "success",
				text: "User created"
			}));
		}).catch(err => {
			console.error("Error creating user: ", err);
			dispatch({
				type: CREATE_USER_FAIL
			});
			// Error message
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}));
		})
	}
}

export function searchUsers(rawQuery) {
	return function (dispatch, getState) {

		dispatch({
			type: SEARCH_USERS_START
		});

		// Get the current token.
		const { session: { token } } = getState();

		// Transform the search query to handle full name searches.
		let query = { _q: rawQuery._q || "" };
		let names;

		if ((names = /^ ?([^\s]{1,20}) {1,3}([^\s]{1,20}) ?$/.exec(query._q))) {
			// Otherwise check for two word searches.
			let [, firstName, lastName] = names;
			query = {
				firstName_contains: firstName,
				lastName_contains: lastName
			}
		} else {
			query._q = query._q.trim();
		}

		// Search.
		axios.get(`${API_ROOT}/users`, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {},
			params: { ...query }
		}).then(res => res.data).then(users => {
			dispatch({
				type: SEARCH_USERS_SUCCESS,
				users
			})
		}).catch(err => {
			console.error("Error searching users: ", err);
			dispatch({
				type: SEARCH_USERS_FAIL
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}));
		})
	}
}

export function getSystemUsers() {
	return function (dispatch, getState) {

		dispatch({
			type: GET_SYSTEM_USERS_START
		});

		// Get the current token
		const { session: { token } } = getState();

		axios.get(`${API_ROOT}/users`, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {},
			params: { isSystemUser: true }
		}).then(res => res.data).then(users => {
			dispatch({
				type: GET_SYSTEM_USERS_SUCCESS,
				users
			})
		}).catch(err => {
			console.error("Error getting system users: ", err);
			dispatch({
				type: GET_SYSTEM_USERS_FAIL
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}));
		})
	}
}

export function updateUser(id, changes, cb) {

	return function (dispatch, getState) {

		dispatch({
			type: UPDATE_USER_START,
			id
		});

		// Get the current token
		const { session: { token } } = getState();

		axios.put(`${API_ROOT}/users/${id}`, { ...changes }, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(user => {
			dispatch({
				type: UPDATE_USER_SUCCESS,
				user
			});
			dispatch(addLog({
				type: "success",
				text: "User updated"
			}));
			if (cb) cb();
		}).catch(err => {
			console.error("Error updating user: ", err);
			dispatch({
				type: UPDATE_USER_FAIL,
				id
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}))
		})

	}

}