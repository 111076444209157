import axios from "axios";
import { API_ROOT } from "../constants";
import { addLog } from "./logger";


export const GET_SLOTS_START = "GET_SLOTS_START";
export const GET_SLOTS_SUCCESS = "GET_SLOTS_SUCCESS";
export const GET_SLOTS_FAIL = "GET_SLOTS_FAIL";

export function getSlots() {
    return function (dispatch, getState) {

        dispatch({
            type: GET_SLOTS_START
        });

        // Get the current token
        const { session: { token } } = getState();

        axios.get(`${API_ROOT}/slots`, {
            headers: token ? {
                Authorization: `Bearer ${token}`
            } : {}
        }).then(res => res.data).then(slots => {
            dispatch({
                type: GET_SLOTS_SUCCESS,
                slots
            });
        }).catch(err => {
            console.error("Error getting slots: ", err);
            dispatch({
                type: GET_SLOTS_FAIL
            })
            dispatch(addLog({
                type: "error",
                text: "Error getting slots."
            }));
        })
    }
}