import axios from "axios";
import { API_ROOT } from "../constants";
import { clearNewOrder } from "../actions/newOrder";
import { addLog } from "./logger";
import moment from "moment";
import { errorResponse } from "../helpers";

export const GET_ORDERS_START = "GET_ORDERS_START";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";
export const CREATE_ORDER_START = "CREATE_ORDER_START";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";
export const EDIT_BOOKING_START = "EDIT_BOOKING_START";
export const EDIT_BOOKING_SUCCESS = "EDIT_BOOKING_SUCCESS";
export const EDIT_BOOKING_FAIL = "EDIT_BOOKING_FAIL";
export const EDIT_ORDER_START = "EDIT_ORDER_START";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_FAIL = "EDIT_ORDER_FAIL";
export const REFUND_ORDER_START = "REFUND_ORDER_START";
export const REFUND_ORDER_SUCCESS = "REFUND_ORDER_SUCCESS";
export const REFUND_ORDER_FAIL = "REFUND_ORDER_FAIL"

export function getOrders(from = moment().startOf("day"), to = moment().endOf("day")) {
    return function (dispatch, getState) {

        dispatch({
            type: GET_ORDERS_START
        });

        // Get the current token
        const { session: { token } } = getState();

        axios.get(`${API_ROOT}/orders/by-date`, {
            headers: token ? {
                Authorization: `Bearer ${token}`
            } : {},
            params: {
                from: typeof from === "string" ? from : from.format(),
                to: typeof to === "string" ? to : to.format()
            }
        }).then(res => res.data).then(orders => {
            dispatch({
                type: GET_ORDERS_SUCCESS,
                orders
            })
        }).catch(err => {
            console.error("Error getting orders: ", err);
            dispatch({
                type: GET_ORDERS_FAIL
            });
            dispatch(addLog({
                type: "error",
                text: errorResponse(err)
            }))
        })
    }
}

export function createOrder(order) {

    return function (dispatch, getState) {

        dispatch({
            type: CREATE_ORDER_START
        });

        // Get the current token
        const { session: { token } } = getState();

        axios.post(`${API_ROOT}/orders`, { ...order }, {
            headers: token ? {
                Authorization: `Bearer ${token}`
            } : {}
        }).then(res => res.data).then(order => {
            dispatch({
                type: CREATE_ORDER_SUCCESS,
                order
            });
            // Clearing new Order state after successfully creating it.
            dispatch(clearNewOrder());
            // Success message
            dispatch(addLog({
                type: "success",
                text: "Order created"
            }))
        }).catch(err => {
            console.error("Error creating order: ", err);
            dispatch({
                type: CREATE_ORDER_FAIL
            });
            // Error message
            dispatch(addLog({
                type: "error",
                text: errorResponse(err)
            }))
        })

    }
}

export function editBooking(orderId, bookingId, changes, cb) {
    return function (dispatch, getState) {

        dispatch({
            type: EDIT_BOOKING_START,
            orderId
        });

        // Get the current token
        const { session: { token } } = getState();

        axios.put(`${API_ROOT}/bookings/${bookingId}`, { ...changes }, {
            headers: token ? {
                Authorization: `Bearer ${token}`
            } : {}
        }).then(res => res.data).then(booking => {
            dispatch({
                type: EDIT_BOOKING_SUCCESS,
                orderId,
                booking: {
                    ...booking,
                    bay: booking.bay instanceof Object ? booking.bay.id : booking.bay,
                    order: booking.order instanceof Object ? booking.order.id : booking.order
                }
            });
            dispatch(addLog({
                type: "success",
                text: "Booking updated"
            }));
            if (cb) cb();
        }).catch(err => {
            console.error("Error updating booking: ", err);
            dispatch({
                type: EDIT_BOOKING_FAIL,
                orderId
            });
            dispatch(addLog({
                type: "error",
                text: errorResponse(err)
            }));
        })

    }
}

export function editOrder(orderId, changes, cb) {
    return function (dispatch, getState) {

        dispatch({
            type: EDIT_ORDER_START,
            orderId
        });

        // Get the current token
        const { session: { token } } = getState();

        axios.put(`${API_ROOT}/orders/${orderId}`, { ...changes }, {
            headers: token ? {
                Authorization: `Bearer ${token}`
            } : {}    
        }).then(res => res.data).then(order => {
            dispatch({
                type: EDIT_ORDER_SUCCESS,
                order
            });
            dispatch(addLog({
                type: "success",
                text: "Order updated"
            }));
            if (cb) cb();
        }).catch(err => {
            console.error("Error updating order: ", err);
            dispatch({
                type: EDIT_ORDER_FAIL,
                orderId
            });
            dispatch(addLog({
                type: "error",
                text: errorResponse(err)
            }))
        })

    }

}

export function refundOrder(orderId, cb) {
    return function (dispatch, getState) {

        dispatch({
            type: REFUND_ORDER_START,
            orderId
        });

        // Get the current token
        const { session: { token } } = getState();

        axios.get(`${API_ROOT}/orders/refund/${orderId}`, {
            headers: token ? {
                Authorization: `Bearer ${token}`
            } : {}        
        }).then(res => res.data).then(order => {
            dispatch({
                type: REFUND_ORDER_SUCCESS,
                order
            });
            dispatch(addLog({
                type: "success",
                text: "Order refunded"
            }));
            if (cb) cb();
        }).catch(err => {
            console.error("Error refunding order: ", err);
            dispatch({
                type: REFUND_ORDER_FAIL,
                orderId
            });
            dispatch(addLog({
                type: "error",
                text: errorResponse(err)
            }))
        })

    }
}
