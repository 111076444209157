import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getViewingSlots, findEffect } from "../../../helpers";

export default function SlotTimes() {

    const { viewing, settings } = useSelector(state => ({
        viewing: state.time.viewing,
        settings: state.settings
    }));

    const viewingSlots = useMemo(function () {
        return getViewingSlots(viewing, 5);
    }, [viewing]);

    return <div className="time-slots">
        <div className="inner">
            {viewingSlots.map((slot, index) => {
                let date = viewing.clone().add(index * 30, "m");
                let isPeak = findEffect(slot, date, settings) === "peak";
                return (
                    <span key={slot.from}>
                        {slot.from.replace(/:00.000$/, "")}
                        {isPeak && <div className="peak-time">P</div>}
                    </span>
                )
            })}
        </div>
    </div>
}