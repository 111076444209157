import axios from "axios";
import { API_ROOT } from "../constants";
import { addLog } from "./logger";
import { errorResponse } from "../helpers";

export const GET_SETTINGS_START = "GET_SETTINGS_START";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL";
export const UPDATE_SETTINGS_START = "UPDATE_SETTINGS_START";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAIL = "UPDATE_SETTINGS_FAIL";

export function getSettings() {
	return function (dispatch, getState) {

		dispatch({
			type: GET_SETTINGS_START
		});

		// Get the current token
		const { session: { token } } = getState();

		axios.get(`${API_ROOT}/public-settings`, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(settings => {
			dispatch({
				type: GET_SETTINGS_SUCCESS,
				settings
			});
		}).catch(err => {
			console.error("Error getting settings: ", err);
			dispatch({
				type: GET_SETTINGS_FAIL
			});
			dispatch(addLog({
				type: "error",
				text: "Error getting settings."
			}));
		})
	}
}

export function updateSettings(changes, cb) {
    return function (dispatch, getState) {

        dispatch({
            type: UPDATE_SETTINGS_START
        });

        // Get the current token
        const { session: { token } } = getState();

		axios.put(`${API_ROOT}/public-settings`, { ...changes },  {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(settings => {
            dispatch({
                type: UPDATE_SETTINGS_SUCCESS,
                settings
            });
            if (cb) cb();
        }).catch(err => {
            console.error("Error updating settings: ", err);
            dispatch({
                type: UPDATE_SETTINGS_FAIL
            });
            // Error message
            dispatch(addLog({
                type: "error",
                text: errorResponse(err)
            }))
        })

    }
}