import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../actions/users";
import { InputField, Button } from "../../fields";
import { rid } from "../../helpers";
import { useModal } from "../../assets/useModal";
import { validateEmail, validateName } from "../../assets/login/helpers";

export default function UserCreator() {

	const dispatch = useDispatch();
	const loading = useSelector(state => state.users.loading);
	const [user, setUser] = useState({});
	const { setModal, closeModal } = useModal();
	const [errors, setErrors] = useState({});

	const errorList = Object.values(errors).filter(Boolean);
	const isDisabled = !user.email || errorList.length;

	return <div className="user-creator">
		<h1>Create Customer</h1>
		<hr />
		<div className="field-combiner">
			<InputField
				label="First Name"
				value={user.firstName || ""}
				onChange={val => {
					const result = validateName(val);
					if (result !== true) {
						setErrors({ ...errors, firstName: "First name is invalid." });
					} else {
						setErrors({ ...errors, firstName: null });
					}
					setUser({ ...user, firstName: val });
				}}
			/>
			<InputField
				label="Last Name"
				value={user.lastName || ""}
				onChange={val => {
					const result = validateName(val);
					if (result !== true) {
						setErrors({ ...errors, lastName: "Last name is invalid." });
					} else {
						setErrors({ ...errors, lastName: null });
					}
					setUser({ ...user, lastName: val });
				}}
			/>
		</div>
		<br />
		<div className="field-combiner">
			<InputField
				label="Email"
				value={user.email || ""}
				onChange={val => {
					const result = validateEmail(val);
					if (result !== true) {
						setErrors({ ...errors, email: "Email is invalid." });
					} else {
						setErrors({ ...errors, email: null });
					}
					setUser({ ...user, email: val });
				}}
			/>
			<InputField
				label="Telephone"
				value={user.telephone || ""}
				onChange={val => { setUser({ ...user, telephone: val }) }}
			/>
		</div>
		{Boolean(errorList.length) && <div className="errors text-center" style={{
			color: "red"
		}}>
			<br />
			<br />
			{errorList.join(" ")}
		</div>}
		<br />
		<br />
		<Button
			loading={loading}
			disabled={isDisabled}
			onClick={() => {
				dispatch(createUser(
					{
						...user,
						username: user.email,
						password: rid(10),
						confirmed: false
					},
					true,
					() => {
						closeModal();
						setModal(null);
					}
				))
			}}
		>
			Create Customer
        </Button>
	</div>

}