import React from "react";
import moment from "moment";
import { plus, minus, closeWhite, chevron } from "../icons";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

export function ImgIcon({ style, className, size, src, onClick, cursor, iconSize, ...otherProps }) {
    return <span
        className={"imgIcon " + (className || "")}
        onClick={onClick}
        style={Object.assign({
            backgroundImage: `url("${src}")`,
            backgroundSize: iconSize || "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: size || 50,
            width: size || 50,
            lineHeight: `${size || 50}px`,
            textAlign: "center",
            fontWeight: "bold",
            display: "inline-block",
            cursor: cursor ? cursor : (onClick ? "pointer" : "default"),
			fontSize: "0.8rem",
        }, style || {})}
        {...otherProps}
    />
}

export function Spinner({ size = 25, color = "#ffffff", style = {} }) {
    return (
        <span
            className="lds-dual-ring"
            style={{
                ...style,
                width: size,
                height: size
            }}
        >
            <span className="inner" style={{
                width: size * 0.8,
                height: size * 0.8,
                borderWidth: size * 0.15,
                borderColor: `${color} transparent ${color} transparent`
            }} />
        </span>
    )
}

export function SpinnerWrapper({ children, style = {}, ...props }) {
    return (
        <div style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...style
        }} {...props} >
            {children}
        </div>
    )
}

export function PaddedIcon({ style, className, size, src, onClick, cursor, ...otherProps }) {
    return (
        <span
            style={Object.assign({
                display: "inline-block",
                verticalAlign: "middle",
                cursor: cursor ? cursor : (onClick ? "pointer" : "default"),
                padding: "10px",
                borderRadius: "50%",
                backgroundColor: "white",

            }, style || {})}

            className={"paddedIcon " + (className || "")}
            onClick={onClick}
            {...otherProps}
        >
            <ImgIcon size={size} cursor={cursor} src={src} style={{ display: "block" }}/>
        </span>
    )
}

export function CalendarField({ value, onChange, disabled = null, style = {}, className = "", ...otherProps }) {

    let parsedValue = value ? value.toDate() : new Date();

    return (
        <div 
            className={"calendar " + className}
            style={style}
            {...otherProps}
        >
            <DayPicker
                modifiers={{
                    disabled
                }}
                month={parsedValue}
                selectedDays={value ? parsedValue : null}
                onDayClick={date => {
                    if (disabled && disabled(date)) {
                        return;
                    } else {
                        onChange(moment(date));
                    }
                }}
            />
        </div>
    )
}

export function QuantitySelector({ value, onChange, min = 0, max = 30, size=20 }) {

    const disableAdding = value >= max;

    return <div className="quantity-selector">
        <PaddedIcon
            cursor={value === min ? "initial" : "pointer"} 
            src={minus}
            size={size}
            style={{
                backgroundColor: "#d3d3d3",
                padding: `${size * 0.33}px`,
                opacity: value === min ? "0" : "1"
            }}
            onClick={() => {
                let newValue = value - 1;
                if (newValue < min) return;
                onChange(newValue);
            }}
        />
        <b className="align-center" style={{ 
            width: `${size * 2}px`, 
            opacity: value === min ? "0" : "1",
            textAlign: "center",
            cursor: "default" 
        }}>{value}</b>
        <PaddedIcon
            cursor={disableAdding ? "initial" : "pointer"}
            src={disableAdding ? closeWhite : plus}
            size={size}
            style={{
                backgroundColor: disableAdding ? "#ff0031" : "#d3d3d3",
                padding: `${size * 0.33}px`,
            }}
            onClick={() => {
                let newValue = value + 1;
                if (newValue > max) return;
                onChange(newValue)
            }}
        />
    </div>
}

export function Pagination({ page, totalPages, onPageChange }) {
        return (
        <div className="pagination noSelect flexy">

            <div
                className={`arrow transition ${(page !== 1 ? " active" : "")}`}
                onClick={() => {
                    if (page > 1) onPageChange(page - 1)
                }}
            >
                <img className="chevron left" src={chevron} alt="chevron left" />
            </div>

            {generateButtons(page, totalPages).map((pageNumber, index) => {
                // Index only used to generate unique key when rendering two "..." buttons
                const clickable = typeof pageNumber === "number" && pageNumber !== page;
                return <div
                    key={pageNumber + index} className={`transition ${page === pageNumber ? " current" : ""}`}
                    style={{
                        cursor: clickable ? "pointer" : "default"
                    }}
                    onClick={() => clickable ? onPageChange(pageNumber) : null}
                >
                    {pageNumber}
                </div>
            })}

            <div
                className={`arrow transition ${page !== totalPages ? " active" : ""}`}
                onClick={() => {
                    if (page < totalPages) onPageChange(page + 1)
                }}
            >   
                <img className="chevron right" src={chevron} alt="chevron right" />
            </div>
        </div>
    )
}

// Used for pagination
function generateButtons(page, totalPages) {
    let allPages = [...new Array(totalPages)].map((i, index) => index + 1);
    if (totalPages <= 7) return allPages;
    let buttons = [page];
    let variance = 1;
    while (buttons.length < 7 && variance < totalPages) {
        if (page + variance <= totalPages)
            buttons.push(page + variance);
        if (page - variance >= 1)
            buttons.unshift(page - variance);
        variance++;
    }
    if (buttons[buttons.length - 1] !== totalPages) {
        buttons[buttons.length - 1] = totalPages;
        buttons[buttons.length - 2] = "...";
    }
    if (buttons[0] !== 1) {
        buttons[0] = 1;
        buttons[1] = "...";
    }
    return buttons;
}