import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getViewingSlots, usePrevious } from "../../../helpers";
import { Slot } from ".";
import { useModal } from "../../../assets/useModal";
import { CheckboxField, DropdownField } from "../../../fields";
import { Button } from "../../../fields";
import { updateBay } from "../../../actions/bays";
import { useMemo } from "react";

export default function BayColumn({ bay, tooltipSide }) {

    const viewing = useSelector(state => state.time.viewing, (before, after) => before.isSame(after));

    const { setModal, openModal } = useModal();

    const viewingSlots = useMemo(function () {
        return getViewingSlots(viewing, 4);
    }, [viewing]);

    return <div className={`bay ${bay.type}-bay bay-${bay.bookable ? "bookable" : "restricted"} noSelect`} data-bay={bay.number}>
        <div
            className="bay-number"
            onClick={e => {
                setModal(<BayEditor bayId={bay.id} />);
                openModal();
            }}
            title="Click to edit bay."
        >
            {bay.number}
        </div>
        <div className="slots">
            {viewingSlots.map((slot, i) => {
                // Work out the actual time of this slot.
                let dateString = viewing.clone().add(30 * i, 'm').format("YYYY-MM-DD");
                // Now work out what status the slot is.
                return <Slot
                    key={slot.from}
                    dateString={dateString}
                    bay={bay}
                    slot={slot}
                    tooltipSide={tooltipSide}
                />
            })}
        </div>
    </div>
}

function BayEditor({ bayId }) {

    const bay = useSelector(state => state.bays.items.find(bay => bay.id === bayId));
    const dispatch = useDispatch();

    const { closeModal } = useModal();

    const wasLoading = usePrevious(Boolean(bay.loading));
    const [changes, setChanges] = useState({});
    const values = {
        ...bay,
        ...changes
    }

    // If the bay updates clear changes.
    useEffect(() => {
        if (!bay.loading) setChanges({});
    }, [bay]);

    // Once the bay has updated, close the modal.
    useEffect(() => {
        if (!bay.loading && wasLoading) closeModal();
    }, [bay.loading, wasLoading, closeModal]);

    return <div className="bayEditor">

        <h2><b>Bay {bay.number}</b> | Modify Properties</h2>
        <hr />
        <br />
        <div className="flexy">
            <div>
                <CheckboxField
                    label="Bookable by customers?"
                    value={values.bookable}
                    onChange={bookable => setChanges({ bookable })}
                />
            </div>
            <div>
                <div style={{ marginBottom: 10 }}>Bay type</div>
                <DropdownField
                    value={values.type}
                    onChange={type => setChanges({ type })}
                    options={[
                        { name: "standard", value: "standard" },
                        { name: "Entertainment", value: "entertainment" },
                    ]}
                />
            </div>
        </div>
        <br /><br />
        <Button
            className="align-center"
            disabled={!Object.keys(changes).length || bay.loading}
            loading={bay.loading}
            onClick={() => dispatch(updateBay(bay, changes))}
        >Update Bay</Button>
    </div>
}