import axios from "axios";
import { API_ROOT } from "../constants";
import { addLog } from "./logger";
import moment from "moment";

export const GET_PUBLIC_BOOKINGS_START = "GET_PUBLIC_BOOKINGS_START";
export const GET_PUBLIC_BOOKINGS_SUCCESS = "GET_PUBLIC_BOOKINGS_SUCCESS";
export const GET_PUBLIC_BOOKINGS_FAIL = "GET_PUBLIC_BOOKINGS_FAIL";

export const REMOVE_PUBLIC_BOOKINGS = "REMOVE_PUBLIC_BOOKINGS";

export function getPublicBookings(from = moment().startOf("day"), to = moment().endOf("day")) {
	return function (dispatch, getState) {

		dispatch({
			type: GET_PUBLIC_BOOKINGS_START
		});

		// Get the current token
		const { session: { token } } = getState();

		axios.get(`${API_ROOT}/bookings/public`, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {},
			params: {
				date_gte: from.format(),
				date_lte: to.format()
			}
		}).then(res => res.data).then(publicBookings => {
			dispatch({
				type: GET_PUBLIC_BOOKINGS_SUCCESS,
				publicBookings
			});
		}).catch(err => {
			console.error("Error getting public bookings: ", err);
			dispatch({
				type: GET_PUBLIC_BOOKINGS_FAIL
			});
			dispatch(addLog({
				type: "error",
				text: "Error getting public bookings."
			}));
		});
	}
}

export function removePublicBookings(publicBookings) {
	return {
		type: REMOVE_PUBLIC_BOOKINGS,
		publicBookings
	}
}