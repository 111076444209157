import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeBooking, addExtra, removeExtra, setPeople } from "../../actions/newOrder";
import { PaddedIcon } from "../../assets";
import { DropdownField } from "../../fields";
import { splitTime, calculateTotal, getBookingPrice } from "../../helpers";
import { close, arrow, plus, minus } from "../../icons";

export default function BookingGroup({ bookings, extras, adults, children, index }) {

    const dispatch = useDispatch();
    const { clubs, settings } = useSelector(state => ({
        clubs: state.clubs.items,
        settings: state.settings
    }));

    return <div className="booking-group noSelect">
        <div className="slots">
            <div style={{ paddingBottom: "15px", borderBottom: "1px solid #333" }}>
                <PaddedIcon
                    className="slot-arrow"
                    src={arrow}
                    size={15}
                    style={{
                        backgroundColor: "#fff",
                        padding: "5px",
                        marginRight: "10px"
                    }}
                />
                <b className="align-center">
                    {`${bookings.length} slot${bookings.length !== 1 ? "s" : ""} selected`}
                </b>
            </div>
            {bookings.map((booking, i) => {
                return <div className="slot space-between" key={i}>
                    <span className="number"><b>{booking.bay.number}</b></span>
                    <span className="time">
                        {`${splitTime(booking.slot.from).join(":")} - ${splitTime(booking.slot.to).join(":")}`}
                    </span>
                    <span className="price"><b>&#163;{getBookingPrice(booking, settings) / 100}</b></span>
                    <PaddedIcon
                        className="remove-slot"
                        cursor="pointer"
                        src={close}
                        size={15}
                        style={{
                            backgroundColor: "#4d4d4d",
                            padding: "5px"
                        }}
                        onClick={() => dispatch(removeBooking(booking))}
                    />
                </div>
            })}
        </div>
        <div className="people">
            <div className="adults space-between">
                <span>Adults</span>
                <span>
                    <PaddedIcon
                        cursor="pointer"
                        src={minus}
                        size={15}
                        style={{
                            backgroundColor: "#4d4d4d",
                            padding: "5px"
                        }}
                        onClick={() => {
                            let number = adults - 1;
                            if (number >= 0) dispatch(setPeople(index, "adults", number));
                            // Booking needs to have at least 1 person
                            if (number === 0 && children === 0) dispatch(setPeople(index, "children", 1));
                        }}
                    />
                    <b style={{ padding: "0px 10px" }}>{adults}</b>
                    <PaddedIcon
                        cursor="pointer"
                        src={plus}
                        size={15}
                        style={{
                            backgroundColor: "#4d4d4d",
                            padding: "5px"
                        }}
                        onClick={() => dispatch(setPeople(index, "adults", adults + 1))}
                    />
                </span>
            </div>
            <div className="children space-between">
                <span>Children</span>
                <span>
                    <PaddedIcon
                        cursor="pointer"
                        src={minus}
                        size={15}
                        style={{
                            backgroundColor: "#4d4d4d",
                            padding: "5px"
                        }}
                        onClick={() => {
                            let number = children - 1;
                            if (number >= 0) dispatch(setPeople(index, "children", number))
                            // Booking needs to have at least 1 person
                            if (number === 0 && adults === 0) dispatch(setPeople(index, "adults", 1));
                        }}
                    />
                    <b style={{ padding: "0px 10px" }}>{children}</b>
                    <PaddedIcon
                        cursor="pointer"
                        src={plus}
                        size={15}
                        style={{
                            backgroundColor: "#4d4d4d",
                            padding: "5px"
                        }}
                        onClick={() => dispatch(setPeople(index, "children", children + 1))}
                    />
                </span>
            </div>
        </div>
        <hr style={{ margin: "5px 0px", borderTop: "1px solid #333" }} />
        <div className="extras">
            {extras.map(extra => {
                return (
                    <div
                        key={extra.club.id}
                        className="extra space-between"
                    >
                        <span>{extra.club.name + " club"}</span>
                        <span>
                            <PaddedIcon
                                cursor="pointer"
                                src={minus}
                                size={15}
                                style={{
                                    backgroundColor: "#4d4d4d",
                                    padding: "5px"
                                }}
                                onClick={() => { dispatch(removeExtra(index, extra.club.id)) }}
                            />
                            <b style={{ padding: "0px 10px" }}>{extra.quantity}</b>
                            <PaddedIcon
                                cursor="pointer"
                                src={plus}
                                size={15}
                                style={{
                                    backgroundColor: "#4d4d4d",
                                    padding: "5px"
                                }}
                                onClick={() => { dispatch(addExtra(index, extra)) }}
                            />
                        </span>
                    </div>
                )
            })}
            <DropdownField
                style={{ marginTop: "10px" }}
                placeholder="Add extra"
                options={clubs.map(club => ({ name: `${club.name} club`, value: club.id }))}
                onChange={val => {
                    dispatch(addExtra(index, { club: clubs.filter(c => c.id === val)[0], quantity: 1 }))
                }}
            />
        </div>
        <div className="total space-between rounded" style={{ marginTop: "10px" }}>
            <span>Total</span>
            <b>&pound;{calculateTotal(bookings, extras, settings) / 100}</b>
        </div>
    </div>

}