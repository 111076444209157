import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { searchUsers } from "../actions/users";
import { Spinner, ImgIcon, CalendarField } from "../assets";
import { chevron, search, check, close, calendar } from "../icons";
import "../style/fields.scss";

export function DropdownField({ value, options, placeholder = "Select", onChange, height = 10, ...props }) {

	const [open, setOpen] = useState(false);

	let ref = useRef(null);

	useEffect(() => {
		// Closing the dropdown when clicked outside of the element
		function close(e) {
			if (open && !ref.current.contains(e.target)) setOpen(false)
		};

		if (open) document.addEventListener("click", close);

		return () => {
			document.removeEventListener("click", close);
		}
	}, [open, setOpen, ref]);

	let displayValue = value ? (options.find(o => o.value === value) || {}).name : placeholder;

	return (
		<div className="dropdown" {...props} ref={ref}>
			<div
				className={"current-value noSelect rounded pointer" + (open ? " open" : "")}
				onClick={() => {
					setOpen(!open);
				}}
			>
				{displayValue}
				<img src={chevron} alt="chevron" className='chevron transition' />
			</div>
			{open && <div className="options shadow">
				<div className="inner transition" style={{
					maxHeight: `${(height * 42) + 2}px`
				}}>
					{options.map(option => {
						return (
							<div
								key={option.value}
								className="option main-text-color transition pointer"
								onClick={() => {
									setOpen(false)
									onChange(option.value);
								}}
							>
								{option.name}
							</div>
						)
					})}
				</div>
			</div>}
		</div>
	)
}

export function InputField({ label, value, onChange, placeholder = "Type here", type = "text", required = false, ...props }) {

	return (
		<div className="input-field" {...props} >
			{label && <label>{label}{required && <span className="required">*</span>}</label>}
			<input placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)} type={type} />
		</div>
	)
}

export function CheckboxField({ label, value, onChange, size = 25, disabled = false, fill = "#333333", className = "", ...props }) {

	return (
		<div className={"checkbox-field" + (disabled ? " disabled" : "") + (" " + className) } {...props}>
			{label && <label onClick={() => { if (!disabled) onChange(!value) }}>{label}</label>}
			<div
				style={{ width: size, height: size }}
				className={"box transition noSelect" + (value ? " selected" : "")}
				onClick={() => {
					if (!disabled) onChange(!value);
				}}
			>
				<div className="inner transition" style={{ backgroundColor: disabled ? "#e20046" : fill }}>
					{disabled
						? <img src={close} alt="close" />
						: <img src={check} alt="check" />
					}
				</div>
			</div>
		</div>
	)

}

export function Button({ children, loading, onClick, disabled, className, spinnerSize = 15, spinnerColor, ...props }) {
	return (
		<button
			className={[
				className,
				loading ? "loading" : null,
				disabled ? "disabled" : null
			].filter(c => c).join(" ")}
			disabled={disabled}
			onClick={e => {
				if (onClick && !disabled && !loading)
					onClick(e);
			}}
			{...props}
		>
			{loading ? <Spinner size={spinnerSize} color={spinnerColor || "#ffffff"}/> : children}
		</button>
	)
}

export function UserLookupField({ onChange, height = 5 }) {

	const dispatch = useDispatch();
	const [filter, setFilter] = useState("");
	const [open, setOpen] = useState(false);

	const users = useSelector(state => state.users.items);

	let ref = useRef(null);

	useEffect(() => {
		// Closing the dropdown when clicked outside of the element
		function close(e) {
			if (open && !ref.current.contains(e.target)) setOpen(false)
		};

		if (open) document.addEventListener("click", close);

		return () => {
			document.removeEventListener("click", close);
		}
	}, [open, setOpen, ref]);

	useEffect(() => {
		let to;
		if (filter) {
			// Look for users if theres a filter value
			to = setTimeout(() => {
				dispatch(searchUsers({ _q: filter }));
			}, 300);
		}
		return () => {
			clearTimeout(to);
		}
	}, [filter, dispatch]);

	return (
		<div className="user-lookup" ref={ref}>
			<div className="lookup">
				<InputField
					value={filter}
					placeholder="Search customer"
					onFocus={e => {
						setOpen(true);
					}}
					onChange={val => {
						setFilter(val.toLowerCase());
						setOpen(true);
					}}
				/>
				<ImgIcon src={search} size={22} />
			</div>
			{open && <div className="options shadow">
				<div className="inner transition" style={{
					maxHeight: `${(height * 42) + 2}px`
				}}>
					{users.length
						? users.filter(user => (
							filter ? [
								`${user.firstName} ${user.lastName}`,
								user.email,
								user.tel
							].join("|").toLowerCase().includes(filter) : true
						)).map(user => <div
							className="option main-text-color transition pointer"
							key={user.id}
							onClick={e => {
								onChange(user);
								setOpen(false);
							}}
						>
							{user.firstName ? <span>{user.firstName} {user.lastName}</span> : null}
							<i>
								{user.email}
							</i>
						</div>
						)
						: <div className="option no-matches">No matches</div>
					}
				</div>
			</div>}
		</div>
	)
}

export function PriceField({ value, onChange, tooltipSide = "right", tooltipTop = 52, ...props }) {

	const [editing, setEditing] = useState(false);

	return <div className="priceField">
		<input type="text"
			{...props}
			value={editing ? String(value) : `£${(value / 100).toFixed(2)}`}
			onFocus={e => {
				setEditing(true);
				const target = e.target;
				setTimeout(() => {
					target.select()
				}, 50)
			}}
			onBlur={e => setEditing(false)}
			onChange={e => {
				onChange(Number(e.target.value) || 0);
			}}
		/>
		{editing && <div className={`floating-tooltip floating-tooltip-${tooltipSide}`} style={{
			top: tooltipTop
		}}>
			Enter value in pence. For example, £5.00 would be "500".
		</div>}
	</div>

}

export function DateField({ value, onChange, label, ...props }) {

	const [open, setOpen] = useState(false);

	let ref = useRef(null);

	useEffect(() => {
		// Hiding the calendar when clicked outside of it
		function close(e) {
			if (open && !ref.current.contains(e.target)) setOpen(false)
		};

		if (open) document.addEventListener("click", close);

		return () => {
			document.removeEventListener("click", close);
		}
	}, [open, ref, setOpen]);

	return <div className="date-field" {...props}>
		{label && <label>{label}</label>}
		<div className="inner-field" onClick={() => setOpen(!open)}>
			<span>{moment(value).format("DD-MM-YYYY")}</span>
			<img src={calendar} alt="calendar" />
		</div>
		<div className={"calendar-wrapper" + (open ? " visible" : "")} ref={ref} style={{ top: label ? "57px" : "37px" }}>
			<CalendarField
				value={moment(value)}
				onChange={val => {
					onChange(moment(val));
					setOpen(false);
				}}
			/>
		</div>
	</div>

}
