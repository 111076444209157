export const ADD_LOG = "ADD_LOG";
export const REMOVE_LOG = "REMOVE_LOG";
export const SET_LOGS = "SET_LOGS";

export function addLog(log) {
    return {
        type: ADD_LOG,
        log
    }
}

export function removeLog(logId) {
    return {
        type: REMOVE_LOG,
        logId
    }
}

export function setLogs(logs) {
    return {
        type: SET_LOGS,
        logs
    }
}