import axios from "axios";
import { API_ROOT } from "../constants";
import { addLog } from "./logger";
import { errorResponse } from "../helpers";

export const GET_BAYS_START = "GET_BAYS_START";
export const GET_BAYS_SUCCESS = "GET_BAYS_SUCCESS";
export const GET_BAYS_FAIL = "GET_BAYS_FAIL";

export const UPDATE_BAY_START = "UPDATE_BAY_START";
export const UPDATE_BAY_SUCCESS = "UPDATE_BAY_SUCCESS";
export const UPDATE_BAY_FAIL = "UPDATE_BAY_FAIL";

export function getBays() {
	return function (dispatch, getState) {

		dispatch({
			type: GET_BAYS_START
		});

		// Get the current token
		const { session: { token } } = getState();

		axios.get(`${API_ROOT}/bays`, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(bays => {
			dispatch({
				type: GET_BAYS_SUCCESS,
				bays
			});
		}).catch(err => {
			console.error("Error getting bays: ", err);
			dispatch({
				type: GET_BAYS_FAIL
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}));
		})
	}
}

export function updateBay(bay, changes, callback) {
	return function (dispatch, getState) {

		dispatch({
			type: UPDATE_BAY_START,
			bay
		});

		// Get the current token
		const { session: { token } } = getState();

		axios.put(`${API_ROOT}/bays/${bay.id}`, changes, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(bay => {
			dispatch({
				type: UPDATE_BAY_SUCCESS,
				bay
			});
			if (callback) callback();
		}).catch(err => {
			console.error("Error getting bays: ", err);
			dispatch({
				type: UPDATE_BAY_FAIL,
				bay
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(err)
			}));
		})
	}
}