import { getCookies, roundToSlot, getQuery } from "../helpers"
import { COOKIE_NAME, VIEWING_DATE_COOKIE } from "../constants";
import moment from "moment";

const cookies = getCookies();
const query = getQuery(["code", "forEmailConfirmation"]);

// Creates a list of slots 
function generateSlots() {
    return [...new Array(48)]
        .map((n, i) => ({
            from: moment().startOf("day").add(30 * i, "minutes").format("HH:mm[:00.000]"),
            to: moment().startOf("day").add(30 * (i + 1), "minutes").format("HH:mm[:00.000]")
        }))
}

const vd = cookies[VIEWING_DATE_COOKIE];

export default {
    settings: {
        loading: false
    },
    time: {
        current: moment(),
        viewing: vd && /admin$/.test(window.location.href) ? moment(vd) : roundToSlot(moment()).add(60, "minutes")
    },
    slots: {
        items: generateSlots(),
        loading: false
    },
    bays: {
        items: [],
        loading: false
    },
    clubs: {
        items: [],
        loading: false
    },
    newOrder: {
        bookingGroups: [],
        count: null,
        user: null,
        paid: true,
        withLoyaltyCard: false
    },
    // customerOrder: {
    //     bookingGroups: [],
    //     user: null  
    // },
    customerBookings: {
        items: [],
        loading: false
    },
    ordersLookup: {
        date: {
            from: moment(vd).startOf("day"),
            to: moment(vd).endOf("day")
        },
        page: 1,
        filter: ""
    },
    orders: {
        items: [],
        loading: false
    },
    myOrders: {
        items: [],
        loading: false
    },
    reportData: {
        items: [],
        loading: false
    },
    users: {
        items: [],
        getLoading: false,
        createLoading: false
    },
    logger: {
        logs: []
    },
    publicBookings: {
        loading: false,
        items: []
    },
    utils: {
        dragging: null,
        dragPosition: { x: 0, y: 0 }
    },
    session: {
        query,
        ready: !Boolean(cookies[COOKIE_NAME]),
        loading: false,
        loginError: null,
        token: cookies[COOKIE_NAME] || null,
        user: null,
        needsConfirmation: false
    }
}