import React from "react";
import { asQuestion } from "../QuestionSet";
// import { useState } from "react";
import UpDownArrows from "../../../UpDownArrows";
import { ImgIcon } from "../../../../../assets";
import { user } from "../../../../../icons";

const MAX_BAYS = 2;
const PLAYERS_TO_A_BAY = 3;

function calcBays(adults = 0, children = 0) {
	return Math.ceil((adults + children) / PLAYERS_TO_A_BAY);
}

function Component({ value, onChange, error }) {

	const { adults, children, bays } = value;
	const maxPlayers = MAX_BAYS * PLAYERS_TO_A_BAY;

	return <div className="players-question">
		<h4><b>How many</b> of you are playing?</h4>
		<br />
		<div className="details">
			If you want to book a session for more than {maxPlayers} player{maxPlayers === 1 ? "" : "s"}, <a href="https://greenwichpeninsulagolfrange.com/location" target="_blank" rel="noopener noreferrer">give us a call</a>.
		</div>
		<br />
		<div className="flexy wrapping">
			<div className="adults v-middle">
				<div className="icon">
					<div className="inner">
						<ImgIcon
							src={user}
							size={50}
						/>
						<span className="count featureFont">{adults || 0}</span>
					</div>
				</div>
				<div className="text-left">
					<span className="featureFont"><b>Adults</b></span>
					<div>18+</div>
				</div>
				<div className="controls">
					<UpDownArrows
						value={adults || 0}
						onChange={adults => onChange({ adults, bays: calcBays(adults, children) })}
					/>
				</div>
			</div>
			<div className="children v-middle">
				<div className="icon">
					<div className="inner">
						<ImgIcon
							src={user}
							size={50}
						/>
						<span className="count featureFont">{children || 0}</span>
					</div>
				</div>
				<div className="text-left">
					<span className="featureFont"><b>Children</b></span>
					<div>Under 18</div>
				</div>
				<div className="controls">
					<UpDownArrows
						value={children || 0}
						onChange={children => onChange({ children, bays: calcBays(adults, children) })}
					/>
				</div>
			</div>
		</div>
		{Boolean(!error && (adults || children)) && <div>
			<br />
			<h4>Great, you'll need <b>{bays}</b> bay{bays === 1 ? "" : "s"}.</h4>
			<br />
		</div>}
	</div>
}

function validator({ adults = 0, children = 0, bays = 0 }) {
	if (bays > MAX_BAYS) {
		return `If you'd like to make a booking for more than ${MAX_BAYS * PLAYERS_TO_A_BAY} players, please give us a call.`;
	} else if (!adults && !children) {
		return `There has to be someone playing.`;
	} else {
		return false;
	}
}

const Question = asQuestion(Component, { validator });
export default Question;
