import React from "react";
import { asQuestion } from "../QuestionSet";
import { useSelector } from "react-redux";
import useBayStatuses from "../../../../../assets/useBayStatuses";
import { AVAILABLE } from "../../../../../constants";
import { useEffect } from "react";
import moment from "moment";

function Component({ value, values, onChange }) {

	const { selected = [] } = value;
	const { viewingTime } = useSelector(state => ({
		viewingTime: state.time.viewing
	})) || [];

	const requiredBays = (values.players && values.players.bays) ? values.players.bays : 1;
	const sessionLength = values.duration.slots || 2;

	const [, bayStatuses] = useBayStatuses(viewingTime, { length: sessionLength });
	const sessionLengthInHours = moment
		.duration(30 * sessionLength, "minutes")
		.asHours();

	const availableBays = bayStatuses
		.filter(b => b.status === AVAILABLE);
	const standardBays = availableBays.filter(bay => bay.type === "standard");
	const entertainmentBays = availableBays.filter(bay => bay.type === "entertainment");

	// Check none of the bays selected just became unavailable.
	useEffect(() => {
		// Create an array of allowed bays.
		let allowed = selected.filter(bayNumber => availableBays.find(b => b.number === bayNumber));
		// If the allowed set is less than the selected set, we need to change the selection.
		if (allowed.length < selected.length) {
			onChange({ selected: allowed });
		}
	}, [availableBays, onChange, selected]);

	function toggleBay(number) {
		const isSelected = selected.includes(number);
		if (!isSelected && selected.length === requiredBays) return;
		onChange({
			selected: isSelected ?
				selected.filter(bayNumber => bayNumber !== number)
				:
				[...selected, number]
		});
	}

	return <div className="bays-question">
		<h4><b>Which bay{requiredBays > 1 ? "s" : ""}</b> would you like? You must pick <b>{requiredBays}</b>.</h4>
		<br />
		<div>
			{Boolean(entertainmentBays.length)
				?
				<div>
					<h5>Available entertainment bays:</h5>
                    <div className="bay-info text-center" style={{ marginTop: "10px" }}>
                        Virtual courses are only available in bays <b>53</b>, <b>54</b> and <b>55</b>. 
                    </div>
					<div className="bays-row v-middle">
						{entertainmentBays.map(bay => (
							<div
								key={bay.number}
								className={`bay entertainment ${selected.includes(bay.number) ? " selected" : ""}`}
								onClick={() => toggleBay(bay.number)}
							>
								{bay.number}
							</div>
						))}
					</div>
				</div>
				:
				<div style={{ color: "red" }}>
					<br />
					<h5>No entertainment bays are available from <b>{viewingTime.format("HH:mm")}</b> for <b>{sessionLengthInHours} hour{sessionLengthInHours === 1 ? "" : "s"}</b>.</h5>
					<br /><br />
				</div>
			}
			{Boolean(standardBays.length)
				?
				<div>
					<h5>Available standard bays:</h5>
					<div className="bays-row v-middle">
						{standardBays.map(bay => (
							<div
								key={bay.number}
								className={`bay standard ${selected.includes(bay.number) ? " selected" : ""}`}
								onClick={() => toggleBay(bay.number)}
							>
								{bay.number}
							</div>
						))}
					</div>
				</div>
				:
				<div style={{ color: "red" }}>
					<br />
					<h5>No standard bays are available from <b>{viewingTime.format("HH:mm")}</b> for <b>{sessionLengthInHours} hour{sessionLengthInHours === 1 ? "" : "s"}</b>.</h5>
					<br /><br />
				</div>
			}
		</div>
	</div >
}

function validator({ selected = [] }, { players: { bays } }) {
	if (selected.length !== bays) return `You have to select ${bays} bay${bays > 1 ? "s" : ""} for this booking.`;
	return false;
}

const Question = asQuestion(Component, { validator });
export default Question;

