import React from "react";
import { eye, star } from "../../icons";

export default function BaysLegend() {

    return <div className="bays-legend">
        <div className="slot-type">
            <div className="type text-center standard">xx</div>
            <span>Standard</span>
        </div>
        <div className="slot-type">
            <div className="type text-center entertainment">xx</div>
            <span>Entertainment</span>
        </div>
        <div className="slot-type">
            <div className="type text-center entertainment restricted">xx</div>
            <span>Restricted</span>
        </div>
        <div className="slot-type">
            <div className="type booked"></div>
            <span>Booked</span>
        </div>
        <div className="slot-type">
            <div className="type system">
                <svg className="system-user anonymous" viewBox="0 0 100 100">
                    <polygon points="100,0 100,100 0,100" />
                </svg>
            </div>
            <span>System User</span>
        </div>
        <div className="slot-type">
            <div className="type loyalty">
                <img className="absolute-center" src={star} alt="star" />
            </div>
            <span>Loyalty Card</span>
        </div>
        <div className="slot-type">
            <div className="type attended"></div>
            <span>Attended</span>
        </div>
        <div className="slot-type">
            <div className="type empty"></div>
            <span>Empty</span>
        </div>
        <div className="slot-type">
            <div className="type selected"></div>
            <span>Selected</span>
        </div>
        <div className="slot-type">
            <div className="type watcher">
                <img className="absolute-center" src={eye} alt="eye icon" />
            </div>
            <span>Online watcher</span>
        </div>
        <div className="slot-type">
            <div className="type text-center peak"><b>P</b></div>
            <span>Peak Time</span>
        </div>
    </div>

}