import React from "react";
import { ImgIcon } from "../assets"
import { logo } from "../icons";
import ResetPassword from "../assets/login/ResetPassword";
import "./style.scss";
import { useState } from "react";

export default function PasswordReset() {

    const [resetSuccessful, setResetSuccessful] = useState(false);

    return <div className="password-reset">
        <div className="login">
            <ImgIcon src={logo} size={150} />
            <br /><br />
            {resetSuccessful
                ?
                <>
                    <h3>Success!</h3>
                    <br />
                    <div>
                        Your password has been reset successfully! Close this tab and return to where you were trying to log in. You can then log in with your new password.
                    </div>
                </>
                :
                <>
                    <h3>New password</h3>
                    <br />
                    <div>
                        Create the new password you want to use. Make sure the passwords match.
                    <br /><br />
                    </div>
                    <ResetPassword onPasswordReset={() => setResetSuccessful(true)} />
                </>

            }
        </div>
    </div>

}