import React from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useState } from "react";
import PlayersQuestion from "./QuestionSections/PlayersQuestion";
import DurationQuestion from "./QuestionSections/DurationQuestion";
import BaysQuestion from "./QuestionSections/BaysQuestion";
import ClubsQuestion from "./QuestionSections/ClubsQuestion";

/**
 * 
 * @param {React.Component} Component 
 * @param {Object} config
 * @param {Function} config.validator Should return either true, or an
 * error message.
 * 
 * @returns {React.Component}
 */
export function asQuestion(Component, config = {}) {

	const { validator } = config;

	return function Question({ ...props }) {

		const {
			previousStage,
			nextStage,
			values,
			setValues,
			name
		} = props;

		// Create a piece of state to act as the question's error. Also initialise it so we have 
		// the correct error on mount.
		const [error, setError] = useState(validator ? validator(values[name], values, props) : null);

		// Create a new version of the onchange function that gets validated.
		function onChange(changes) {
			// Create the proposed new state.
			const newValues = {
				...values,
				[name]: {
					...values[name],
					...changes
				}
			}
			// Skip if there isn't a validator.
			if (!validator) return setValues(newValues);
			const error = validator(newValues[name], newValues, props);
			// If there was an error, notify the question set.
			if (typeof error === "string") {
				setError(error);
			} else {
				setError(null);
			}
			// And allow the actual change.
			setValues(newValues);
		}

		return <div className="question">
			<div className="inner">
				<div className="content">
					<Component
						{...props}
						error={error}
						onChange={onChange}
					/>
				</div>
				<div className="controls flexy">
					{previousStage &&
						<button style={{ flex: 1 }} onClick={previousStage}>Back</button>
					}
					{!error &&
						<button style={{ flex: 2 }} onClick={nextStage}>Next</button>
					}
				</div>
				<br />
				{Boolean(error) && <div className="error featureFont">
					{error}
				</div>}
			</div>
		</div>
	}
}

// Question orders.
const Questions = [
	["players", PlayersQuestion],
	["duration", DurationQuestion],
	["clubs", ClubsQuestion],
	["bays", BaysQuestion],
];

// Generate the default starting state.
function defaultStateObject(questions) {
	const result = {}
	questions.forEach(([name]) => {
		switch (name) {
			case "duration":
				result[name] = {
					slots: 2
				}
				break;
			case "clubs":
			case "bays":
				result[name] = {
					selected: []
				}
				break;
			default:
				result[name] = {}
				break;
		}
	});
	return result;
}

export default function QuestionSet({ onComplete }) {

	const [questionStage, setQuestionStage] = useState(0);
	const [values, setValues] = useState(defaultStateObject(Questions));

	const [questionName, Question] = Questions[questionStage];
	const atStart = !Questions[questionStage - 1];
	const atEnd = !Questions[questionStage + 1];

	return <div className="questionSet" onClick={() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }}>
		<SwitchTransition>
			<CSSTransition
				key={questionStage}
				classNames="questionStage"
				timeout={300}
			>
				<Question
					name={questionName}
					values={values}
					value={values[questionName]}
					setValues={setValues}
					atStart={atStart}
					atEnd={atEnd}
					currentStage={questionStage}
					nextStage={atEnd ? () => onComplete({ ...values }) : () => setQuestionStage(questionStage + 1)}
					previousStage={atStart ? null : () => setQuestionStage(questionStage - 1)}
				/>
			</CSSTransition>
		</SwitchTransition>
	</div>
}