import React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { setViewingTime } from "../../actions/time";
import { chevron, calendar } from "../../icons";
import { ImgIcon, PaddedIcon, CalendarField } from "../../assets";
import { useModal } from "../../assets/useModal";

export default function DateSelector() {

	const dispatch = useDispatch();
	const { openModal, setModal, closeModal } = useModal();
	const { viewing } = useSelector(state => state.time);

	const canGoBack = viewing.diff(moment(), "minutes") < 30;

	return <div className="date-selector text-center featureFont">
		<div className="date-wrapper">
			<div className="currentDate mobile">{viewing.format("ddd Do MMM")} - <b>{viewing.format("HH:mm")}</b></div>
			<div className="currentDate desktop">{viewing.format("ddd Do MMM")}</div>
			<div className="currentTime desktop"><b>{viewing.format("HH : mm")}</b></div>
			{/* <div className="currentDate desktop">{viewing.format("Do MMM")}</div> */}
			{/* <div className="currentDate mobile">{viewing.format("Do MMM")}</div> */}
		</div>
		<div className="controls">
			<div className="arrows shadow">
				<div
					className={`arrow left pointer ${canGoBack ? "disabled" : ""}`}
					onClick={() => canGoBack ? null : dispatch(setViewingTime(viewing.clone().subtract(30, "minutes")))}
				>
					<ImgIcon size={20} src={chevron} onClick={canGoBack ? null : e => { }} />
				</div>
				<div
					className={`arrow right pointer`}
					onClick={() => dispatch(setViewingTime(viewing.clone().add(30, "minutes")))}
				>
					<ImgIcon size={20} src={chevron} onClick={e => { }} />
				</div>
			</div>
			<PaddedIcon
				size={20}
				src={calendar}
				className="shadow"
				cursor="pointer"
				onClick={() => {
					setModal(
						<div>
							<h2><b>Select</b> date</h2>
							<CalendarField
								disabled={date => {
									let m = moment(date);
									if (m.isBefore(moment())) return true;
									return false;
								}}
								style={{ display: "inline-block" }}
								value={viewing.clone()}
								onChange={val => {
									// make sure you only change the day and keep hours minutes and seconds from the current value
									let hour = viewing.hour();
									let minute = viewing.minute();
									dispatch(setViewingTime(val.set({ hour, minute, second: 0, millisecond: 0 })));
									closeModal();
								}}
							/>
						</div>, { className: "size-to-content", closable: true }
					);
					openModal();
				}}
			/>
		</div>
	</div>

}