import { useEffect, useState } from "react";
import { rid } from "../helpers";
import { INACTIVE_TIMEOUT } from "../constants";

function ActivityMonitor() {

	// Some internal memory.
	this.active = true; // Whether the state is active or not.
	this.timeout = null; // Reference to the timer that checks for inactivity.
	this.listeners = []; // Array of callbacks structured { id: rid(), callback: Function }

	// Runs all of the functions in the listeners array, and passes in the current value of
	// this.active.
	const emit = () => {
		this.listeners.forEach(listener => listener.callback(this.active));
	}

	// Adds a function to the listeners array and returns the id as a reference to it.
	this.subscribe = callback => {
		let id = rid();
		this.listeners.push({
			id,
			callback
		});
		return id;
	}
	// Removes the function from the listeners array that has the same id as the one passed.
	this.unsubscribe = id => {
		this.listeners = this.listeners.filter(l => l.id !== id);
	}
	// Run whenever there is an event on the window.
	const onInteraction = e => {
		if (!this.active) { // if we aren't currently active, and there was an event, we should change to active.
			this.active = true; // Set the value.
			emit(); // And inform all the listeners of the new value.
		}
		restartTimer(); // Regardless of whether we're active, the timer must be restarted, because it has now
		// been 0 seconds since the last interaction.
	}
	window.addEventListener("mousemove", onInteraction); // Add listeners for events that count as interactions.
	window.addEventListener("keydown", onInteraction); // Add listeners for events that count as interactions.
	window.addEventListener("touchstart", onInteraction); // Add listeners for events that count as interactions.

	const restartTimer = () => { // Used to start or restart the timer.
		if (this.timeout) clearTimeout(this.timeout); // Clear if it exists.
		this.timeout = setTimeout(() => { // And if the timer manages to finish (IE it didn't get cancelled by an interaction).
			this.active = false; // The set active to false..
			emit(); // And inform any listeners.
		}, INACTIVE_TIMEOUT);
	}

	// Start the timer for the first time, or we won't capture the initial event if someone opens the page
	// and never interacts with it.
	restartTimer();
}

// Create our only needed instance of the activity monitor.
const monitor = new ActivityMonitor(); 

// And create a hook for convenience use inside components.
export default function useActivityMonitor() {
	// This state is the only important part of the hook, and the only thing that triggers a re-render
	// of any component using the hook.
	const [isActive, setIsActive] = useState(monitor.active);
	// Our useEffect is only run on component mount/unmount, and is just there to subscribe to the 
	// monitor object.
	useEffect(() => {
		const cbid = monitor.subscribe(setIsActive); // Pass the setIsActive function to the monitor
		// to be used as the callback, because when an event is emitted from the monitor, it will run
		// whatever function is passed, and give it the new value for 'isActive'.
		return () => {
			monitor.unsubscribe(cbid); // Clean up on unmount.
		}
	}, []);

	// Finally, return the stateful value of isActive to whatever component runs the hook.
	return isActive;

}