import React, { useState } from "react";
import Field from "./Field";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../fields";
import { passwordReset, setSessionData } from "../../actions/session";
import { useEffect } from "react";
import { EMAIL_LOGIN } from ".";

export default function ResetPassword({ onRequestFormChange, onPasswordReset }) {

    const {
        loading,
        loginError,
        query
    } = useSelector(state => state.session);
    const { code } = query;

    const [values, setValues] = useState({ code });
    const [fieldErrors, setFieldErrors] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        if (query.resetPassword) dispatch(setSessionData({
            query: {
                ...query,
                forEmailConfirmation: null,
                resetPassword: null,
                code: null
            }
        }));
    }, [dispatch, query]);

    function fieldChange({ name, value, error }) {
        setValues({
            ...values,
            [name]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [name]: error
        });
    }

    return <form onSubmit={e => {
        e.preventDefault();
        if (Object.values(fieldErrors).filter(e => e).length) return;
        dispatch(passwordReset(values, error => {
            if (error && onRequestFormChange) onRequestFormChange(EMAIL_LOGIN);
            if (!error && onPasswordReset) onPasswordReset();
        }));
    }}>
        {/* <h4>New password</h4>
        <br />
        <div className="featureFont">
            Create the new password you want to use. Make sure the passwords match.
			<br /><br />
        </div> */}
        <Field
            onChange={fieldChange}
            name="password"
            type="password"
            values={values}
            errors={fieldErrors}
            autoComplete="new-password"
            placeholder="New Password"
            validate={value => {
                let errors = [];
                if (!/.{7,40}/.test(value))
                    errors.push("Between 7 and 40 characters.");
                if (!/[a-z]/g.test(value))
                    errors.push("At least one lower case letter.");
                if (!/[A-Z]/g.test(value))
                    errors.push("At least one upper case letter.");
                if (!/[0-9]/g.test(value))
                    errors.push("At least one number.");
                if (!errors.length) {
                    return true;
                } else {
                    return errors.join(" ");
                }
            }}
        />
        <Field
            onChange={fieldChange}
            name="confirmPassword"
            type="password"
            values={values}
            errors={fieldErrors}
            autoComplete="new-password"
            placeholder="Confirm Password"
            validate={value => {
                if (value !== values.password) {
                    return "Must match the new password."
                } else {
                    return true;
                }
            }}
        />
        <Button
            loading={loading}
            type="submit"
            disabled={loading || Object.values(fieldErrors).filter(e => e).length}
        >Set password</Button>
        {loginError && <>
            <br />
            <div className="error text-center">
                {loginError}
            </div>
        </>}
    </form>
}