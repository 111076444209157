import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSettings } from "../../../actions/settings";
import { Button, PriceField, DropdownField } from "../../../fields";
import { ImgIcon } from "../../../assets";
import { bin, warning } from "../../../icons";
import { rid } from "../../../helpers";
import { useModal } from "../../../assets/useModal";

const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
const minutes = [...new Array(60)].map((j, i) => i < 10 ? "0" + i : String(i));
const hours = [...new Array(24)].map((j, i) => i < 10 ? "0" + i : String(i));

function checkForRuleErrors(rule, otherRules) {
    let error = null;
    const [hoursFrom, minutesFrom] = rule.fromTime.slice(0, 5).split(":");
    const [hoursTo, minutesTo] = rule.toTime.slice(0, 5).split(":");
    // to cannot be before from
    if (Number(hoursFrom) > Number(hoursTo) || (Number(hoursFrom) === Number(hoursTo) && Number(minutesFrom) >= Number(minutesTo))) {
        error = "To time have to be after From time.";
        // Checking for duplicate rules
    } else if (Boolean(otherRules.find(r => r.day === rule.day && r.fromTime === rule.fromTime && r.toTime === rule.toTime))) {
        error = "Cannot have duplicate rules";
    }

    return error;
}

function getAllRuleErrors(rules) {
    const errors = {};
    rules.forEach(rule => {
        const otherRules = rules.filter(r => r.id !== rule.id);
        if (checkForRuleErrors(rule, otherRules))
            errors[rule.id] = checkForRuleErrors(rule, otherRules);
    });
    return errors;
}

function formatSettings(values) {
    let { entertainmentBayPrice, standardBayPrice, timeClasses } = values;
    return {
        entertainmentBayPrice,
        standardBayPrice,
        timeClasses: timeClasses.map(rule => {
            if (typeof rule.id === "string" && rule.id.includes("new-")) {
                let {id, ...newRule} = rule;
                return { ...newRule };
            };
            return rule;
        })
    }
}

export default function Settings() {

    const dispatch = useDispatch();
    const { setModal, closeModal } = useModal();
    const settings = useSelector(state => state.settings);
    const [changes, setChanges] = useState({});
    // Check for errors on start.
    const [errors, setErrors] = useState(getAllRuleErrors(settings.timeClasses));

    const values = {
        ...settings,
        ...changes
    }

    useEffect(() => {
        setErrors(getAllRuleErrors(values.timeClasses));
    }, [values.timeClasses]);

    function change(data) {
        setChanges({
            ...changes,
            ...data
        });
    };

    return <div className="settings-editor">
        <div className="scroll-wrapper">
            <h2>Bay <b>Prices</b></h2>
            <hr />
            <div className="bayPrices flexy">
                <div className="standard" style={{ marginRight: 10 }}>
                    <h3>Standard Bays</h3>
                    <div className="padded-1">
                        <label>Peak price</label>
                        <PriceField
                            value={values.standardBayPrice.peak}
                            onChange={peak => change({
                                standardBayPrice: { ...values.standardBayPrice, peak }
                            })}
                        />
                    </div>
                    <div className="padded-1">
                        <label>Off-peak price</label>
                        <PriceField
                            value={values.standardBayPrice.offPeak}
                            onChange={offPeak => change({
                                standardBayPrice: { ...values.standardBayPrice, offPeak }
                            })}
                        />
                    </div>
                </div>
                <div className="entertainment" style={{ marginLeft: 10 }}>
                    <h3>Entertainment Bays</h3>
                    <div className="padded-1">
                        <label>Peak price</label>
                        <PriceField
                            value={values.entertainmentBayPrice.peak}
                            onChange={peak => change({
                                entertainmentBayPrice: { ...values.entertainmentBayPrice, peak }
                            })}
                        />
                    </div>
                    <div className="padded-1">
                        <label>Off-peak price</label>
                        <PriceField
                            value={values.entertainmentBayPrice.offPeak}
                            onChange={offPeak => change({
                                entertainmentBayPrice: { ...values.entertainmentBayPrice, offPeak }
                            })}
                        />
                    </div>
                </div>
            </div>
            <br />
            <h2>Opening <b>Hours</b></h2>
            <hr />
            <OpeningHoursSelector
                rules={values.timeClasses}
                errors={errors}
                onChange={timeClasses => {
                    change({ timeClasses });
                }}
            />
        </div>
        <Button
            disabled={!Object.keys(changes).length || Object.keys(errors).length}
            loading={settings.loading}
            onClick={e => {
                dispatch(updateSettings(formatSettings(values), () => {
                    setChanges({});
                    closeModal();
                    setTimeout(() => {
                        setModal(null)
                    }, 200)
                }))
            }}
        >Save changes</Button>
    </div>
}

function OpeningHoursSelector({ rules, onChange, errors }) {

    return <div className="openingHours">
        <Button
            style={{ backgroundColor: "#00ba89", color: "white" }}
            onClick={e => {
                const newRules = [...rules, {
                    day: "MON",
                    effect: "peak",
                    fromTime: "00:00:00.000",
                    id: `new-${rid()}`,
                    toTime: "00:00:00.000",
                    __component: "time-ranges.day-of-week-range",
                }];
                onChange(newRules);
            }}
        >
            <span>ADD RULE</span>
        </Button>
        <br />
        {rules
            .sort((a, b) => {
                if (days.indexOf(a.day) > days.indexOf(b.day)) {
                    return 1
                } else if (days.indexOf(a.day) < days.indexOf(b.day)) {
                    return -1
                } else {
                    // Same day sort by from time
                    return Number(a.fromTime.slice(0,2)) - Number(b.fromTime.slice(0,2))
                }
            }) // sorting by days
            .map(rule => {
                return <RuleEditor
                    rule={rule}
                    key={rule.id}
                    error={errors[rule.id]}
                    onChange={changes => {
                        const newRules = rules.map(originalRule => originalRule.id === rule.id ?
                            { ...originalRule, ...changes }
                            :
                            originalRule
                        );
                        onChange(newRules);
                    }}
                    onRemove={id => {
                        const newRules = rules.filter(rule => rule.id !== id);
                        onChange(newRules);
                    }}
                />
            })}
    </div>
}

function RuleEditor({ rule, onChange, onRemove, error }) {

    function change(changes) {
        const newRule = {
            ...rule,
            ...changes
        }
        onChange(newRule);
    }

    return <div className="rule-editor">
        <div className={`padded-1 flexy rules ${error ? "has-error" : ""}`} style={{ alignItems: "flex-end" }}>
            <div className="padded-h-1" style={{ flex: "2" }}>
                <label>Day</label>
                <DropdownField
                    value={rule.day}
                    onChange={day => change({ day })}
                    options={days.map(value => ({ name: value, value }))}
                />
            </div>
            <div className="padded-h-1" style={{ flex: "4" }}>
                <label>From</label>
                <TimeSelectorField
                    value={rule.fromTime}
                    onChange={fromTime => change({ fromTime })}
                />
            </div>
            <div className="padded-h-1" style={{ flex: "4" }}>
                <label>To</label>
                <TimeSelectorField
                    value={rule.toTime}
                    onChange={toTime => change({ toTime })}
                />
            </div>
            <div className="padded-h-1" style={{ flex: "2" }}>
                <label>Type</label>
                <DropdownField
                    value={rule.effect}
                    onChange={effect => change({ effect })}
                    options={[{ value: "offPeak", name: "Off-peak" }, { value: "peak", name: "Peak" }]}
                />
            </div>
            <div className="controls text-center">
                <ImgIcon
                    style={{ marginBottom: "5px" }}
                    size={25}
                    src={bin}
                    onClick={e => onRemove(rule.id)}
                />
            </div>
        </div>
        {!!error && <div className="error-block text-center">
            <ImgIcon size={20} src={warning} className="align-center" style={{ marginRight: "5px" }} />
            <span className="align-center">{error}</span>
        </div>}
    </div>
}

function TimeSelectorField({ value, onChange }) {

    const [hoursValue, minutesValue] = value.slice(0, 5).split(":");

    return <div className="timeSelectorField v-middle">
        <DropdownField
            options={hours.map(h => ({ value: h, name: h }))}
            onChange={newHours => onChange(`${newHours}:${minutesValue}:00.000`)}
            value={hoursValue}
        />
        <b style={{
            padding: "0px 5px"
        }}>:</b>
        <DropdownField
            options={minutes.map(m => ({ value: m, name: m }))}
            onChange={newMinutes => onChange(`${hoursValue}:${newMinutes}:00.000`)}
            value={minutesValue}
        />
    </div>
}