import moment from "moment";
import { useEffect, useRef } from "react";
import { RANGE_TYPE_PRIORITIES, MIN_CANCEL_ORDER_HOURS } from "../constants";

export function getCookies() {
	let result = {};
	document.cookie
		.split(";")
		.map(pair => pair.trim().split("="))
		.forEach(([name, value]) => {
			result[name] = value
		});
	return result;
}

export function setCookie({ key, value, path, expires }) {
	const cookie = [
		[key, value || ""],
		path ? ["path", path] : null,
		expires ? ["expires", moment(expires).format("ddd, DD MMM YYYY HH:MM:SS Z")] : null
	]
		.filter(c => c)
		.map(([key, value]) => `${key}=${value}`)
		.join("; ");
	document.cookie = cookie;
}

export function getQuery(removeFromQueryString = []) {
	// Build the object from the query string.
	let result = parseQueryString(window.location.search);
	// If query properties are to be removed, do it here.
	if (removeFromQueryString.length) {
		let allowedValues = { ...result };
		removeFromQueryString.forEach(key => delete allowedValues[key]);
		let newQs = objectToQuery(allowedValues);
		let newUrl = window.location.href.replace(window.location.search, newQs ? "?" + newQs : "");
		// Replace the actual history state to one without the code.
		if (window.history && window.history.replaceState)
			window.history.replaceState({}, "", newUrl);
	}
	return result;
}

export function objectToQuery(object) {
	return Object.entries(object)
		.filter(([, value]) => value !== null)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join("&");
}

export function parseQueryString(qs = "") {
	const result = {}
	qs.replace(/^\?/, "")
		.split("&")
		.map(pair => pair.split("="))
		.forEach(([key, value]) => {
			if (value)
				result[decodeURIComponent(key)] = decodeURIComponent(value);
		});
	return result;
}

/**
 * @param {String} time 
 * @param {String|moment.Moment} date 
 */
export function combineTimeAndDate(time, date) {
	const [, hours, minutes] = /^(\d{1,2}):(\d{1,2})/.exec(time);
	return (typeof date === "string" ? moment(date) : date.clone())
		.set({ hours, minutes });
}

export function errorResponse(error) {
	let message;
	if (error.response) {
		message = error.response.data.message;
		if (message instanceof Array) {
			message = message.map(m => m.messages.map(m2 => m2.message).join(" ")).join(" ");
		}
	} else if (error.request) {
		message = "No response from server."
	} else if (error.message) {
		message = error.message;
	} else {
		message = "Unknown error.";
		console.error("Unknown error: ", error);
	}
	return message;
}

/**
 * Generates an array of length 'length', which starts from 'startsFrom' and increases
 * by 1 for each index. IE incrementingArray(4, 1) returns [1, 2, 3, 4].
 * 
 * @param {Integer} length How long you'd like the array to be.
 * @param {Integer} startsFrom The first number in the array, and starting point for incrementing.
 */
export function incrementingArray(length = 10, startsFrom = 1) {
	return [...new Array(length)].map((i, index) => index + startsFrom);
}

export function useInterval(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

// Moment object as an argument
export function roundToSlot(time) {
	let minute = time.minutes();
	minute = minute >= 30 ? 30 : 0;
	return time.clone().set({ minute, second: 0, millisecond: 0 });
}

export function splitTime(timeSlot) {
	const parts = timeSlot.split(":");
	return [parts[0], parts[1]];
}

export function twoDp(number) {
	return String(number).length === 1 ? "0" + number : number;
}

export function rid(length = 20) {
	let id = [];
	let charset = "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	for (let i = 0; i < length; i++) {
		id.push(charset[Math.round(Math.random() * (charset.length - 1))]);
	}
	return id.join("");
}

export function tempId() {
	return `temp-${rid()}`;
}

export function checkTempId(id) {
	return typeof id === "string" && id.includes("temp-") ? true : false;
}

export function checkIfTempBookings(bookings) {
	return Boolean(bookings.find(booking => checkTempId(booking.id)));
}

export function canRefund(bookings) {
	const mostRecentBooking = bookings
		.map(b => {
			const from = splitTime(b.slot.from);
			return { ...b, date: moment(b.date).set({ hour: from[0], minute: from[1] }) }
		})
		.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())[0];
	return moment(mostRecentBooking.date).diff(moment(), "hours") > MIN_CANCEL_ORDER_HOURS;
}

/**
 * 
 * @param {Array} slots // Array of slot objects 
 * @param {moment.Moment} time // Current viewing time
 * @param {Integer} number // Number of items to return form the array
 */
export function getViewingSlots(time, number) {
	let result = [];
	let counter = time.clone();
	for (let i = 0; i < number; i++) {
		let entry = {
			from: counter.format("HH:mm[:00.000]"),
		}
		counter.add(30, "minutes");
		entry.to = counter.format("HH:mm[:00.000]");
		result.push(entry);
	}
	return result;
}


export function getUnique(arr, comp) {

	// store the comparison values in array
	const unique = arr.map(e => e[comp])

		// store the indexes of the unique objects
		.map((e, i, final) => final.indexOf(e) === i && i)

		// eliminate the false indexes & return unique objects
		.filter((e) => arr[e]).map(e => arr[e]);

	return unique;
}

// takes the order as stored in the reducer and formats it to what the server expects
export function formatOrder(newOrder) {
	return {
		withLoyaltyCard: newOrder.withLoyaltyCard,
		paid: newOrder.paid,
		user: newOrder.user.id,
		bookings: newOrder.bookingGroups.map(bg => {
			// Sort bookings earliest to latest.
			const bookings = [...bg.bookings]
				.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
				// And reformat the dates.
				.map(booking => ({
					...booking,
					date: booking.date,
					adults: bg.adults,
					children: bg.children
				}));
			// Add the extras onto the first one.
			bookings[0].extras = bg.extras.map(extra => ({
				...extra,
				__component: (() => {
					if (extra.club) {
						return "extra.club"
					}
				})()
			}));
			// And concat it into the bookings array.
			return bookings;
		}).flat()
	}
}

export function findBooking(slot, bayId, bookingDate, orders) {
	let bookings = [];
	orders.forEach(o => bookings.push(...o.bookings));
	return bookings.find(b => {
		return b.slot.from === slot.from &&
			b.bay === bayId &&
			b.date === bookingDate &&
			b.status !== "cancelled"
	})
}

export function mergeObjectArrays(sourceArray, newArray, comparator = () => false) {
	// Create the final array we'll use as a clone of the source.
	let finalArray = [...sourceArray];
	// Iterate the new array.
	newArray.forEach(newItem => {
		// Reference for if we found something to merge with.
		let didMerge = false;
		finalArray = finalArray.map(oldItem => {
			// If the items are a match based on the comparator.
			if (comparator(oldItem, newItem)) {
				// Flag that a merge happened.
				didMerge = true;
				// Merge them, overwriting old with new.
				return {
					...oldItem,
					...newItem
				}
			} else {
				// Otherwise just return the original.
				return oldItem;
			}
		});
		// If no merge happened, add the new item to the end.
		if (!didMerge) {
			finalArray = [...finalArray, newItem];
		}
	});
	// Finally, we can return the new merged array.
	return finalArray;
}

/**
 * 
 * @param {moment.Moment} date The date to check for.
 * @param {Object} settings The global settings object.
 * @param {Array} settings.timeClasses
 */
export function findOpeningHoursType(date, settings) {
	// Get the timeclasses, and be sure to sort by priorities.
	const timeClasses = [...(settings.timeClasses || [])]
		.sort((a, b) => RANGE_TYPE_PRIORITIES.indexOf(a.__component) -
			RANGE_TYPE_PRIORITIES.indexOf(b.__component));
	// Create a variable for the current day of the week, because it'll
	// be used to check against weekdays.
	const day = date.format("ddd").toUpperCase();
	// And one for the beginning of the day.
	const startOfDay = date.clone().startOf("day");
	// Iterate the timeClasses array, returning if one matches the rule.
	return timeClasses.find(time => {
		// Date ranges are easy, as it's a specific timestamp.
		if (time.__component === "time-ranges.date-range") {
			return date.isSameOrAfter(moment(time.fromDate)) &&
				date.isBefore(moment(time.toDate)); // "to" check must be
				// "before" not "same or before".
			// Now for weekday rules.
		} else if (
			time.__component === "time-ranges.day-of-week-range" &&
			day === time.day
		) { // If the day is the same, and we're between the times, it's
			// a match. Again remeber "isBefore" for "to" time.
			let timeFrom = combineTimeAndDate(time.fromTime, startOfDay);
			let timeTo = combineTimeAndDate(time.toTime, startOfDay);
			return date.isSameOrAfter(timeFrom) &&
				date.isBefore(timeTo);
		} else {
			return false;
		}
	}) || null; // Or null if nothing matches.
}

export function findEffect(slot, date, settings = {}) {

	let from = splitTime(slot.from);

	const day = date.format("ddd").toUpperCase();
	const dateFrom = date.clone().set({ hour: Number(from[0]), minute: Number(from[1]) });

	const dateTo = dateFrom.clone().add(30, "m");
	// find matching rules
	let matches = (settings.timeClasses || []).filter(time => {
		if (time.__component === "time-ranges.date-range") {
			return dateFrom.isSameOrAfter(moment(time.fromDate)) && dateTo.isSameOrBefore(moment(time.toDate));
		} else if (time.__component === "time-ranges.day-of-week-range") {
			let tFrom = splitTime(time.fromTime);
			let tTo = splitTime(time.toTime);
			const timeFrom = date.clone().set({ hour: Number(tFrom[0]), minute: Number(tFrom[1]) });
			const timeTo = date.clone().set({ hour: Number(tTo[0]), minute: Number(tTo[1]) });
			return day === time.day && dateFrom.isSameOrAfter(timeFrom) && dateTo.isSameOrBefore(timeTo);
		} else {
			return false;
		}
	}).sort((a, b) =>
		RANGE_TYPE_PRIORITIES.indexOf(a.__component) - RANGE_TYPE_PRIORITIES.indexOf(b.__component)
	);
	return matches.length ? matches[0].effect : null;
}

export function getBookingPrice(booking, settings) {
	let effect = findEffect(booking.slot, moment(booking.date), settings) || "offPeak";
	return ((booking.bay.type === "standard" ? settings.standardBayPrice[effect] : settings.entertainmentBayPrice[effect]));
}

export function calculateTotal(bookings, extras, settings) {

	let total = 0;

	bookings.forEach(booking => {
		total += getBookingPrice(booking, settings)
	});

	// adding extras price
	extras.forEach(e => {
		total += e.club.price * e.quantity
	});

	return total;
}

export function calculateOrderTotal(bookingGroups, settings) {

	let total = 0;
	bookingGroups.forEach(group => {
		let extras = group.bookings.map(booking => booking.extras).flat();
		total += calculateTotal(group.bookings, extras, settings)
	});

	return total;

}

/**
 * 
 * @param {Array} bookings Turns an array of booking objects into an array of
 * booking group objects.
 * 
 * A booking group is in the form {
 *      bookings: [ ...booking ]
 * }
 */
export function groupBookings(bookings) {

	const bookingGroups = [];
	// Sort by start date of the bookings.
	[...bookings].sort((a, b) => (
		combineTimeAndDate(a.slot.from, a.date).valueOf() -
		combineTimeAndDate(b.slot.from, b.date).valueOf()
	)).forEach(bookingToPlace => {
		// Find a group where there is a booking of the same bay, and a slot.to the
		// same as the booking's slot.from.
		const foundGroupIndex = bookingGroups.findIndex(group => group.bookings.find(booking =>
			booking.bay.number === bookingToPlace.bay.number &&
			booking.slot.to === bookingToPlace.slot.from
		));
		// If we got one, add to it.
		if (foundGroupIndex !== -1) {
			bookingGroups[foundGroupIndex].bookings.push(bookingToPlace);
		} else {
			// Otherwise, add a new group.
			bookingGroups.push({
				bookings: [bookingToPlace],
				extras: [],
				adults: 0,
				children: 0
			});
		}
	});
	// Done.
	return bookingGroups;
}

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export function bookingExpiresIn(date1, date2, minutes) {
	const diff = Number(moment(date1).add(minutes, "minutes").diff(moment(date2), "minutes"));
	if (diff > 1) return `expires in ${diff} minutes`;
	if (diff === 1) return `expires in ${diff} minute`
	return `expires in < 1 minute`
}