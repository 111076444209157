import React, { useState } from "react";
import { validateEmail } from "./helpers";
import Field from "./Field";
import { Button } from "../../fields";
import { useSelector, useDispatch } from "react-redux";
import { login, setSessionData, sendEmailConfirmation } from "../../actions/session";

export default function EmailLogin() {

    const dispatch = useDispatch();
    const { loading, loginError, query } = useSelector(state => state.session);
    // const { forEmailConfirmation } = query;

    const [values, setValues] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [confirmationSent, setConfirmationSent] = useState(null);

    function fieldChange({ name, value, error }) {
        setValues({
            ...values,
            [name]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [name]: error
        });
    }
    return <>
        {/* Message for existing users */}
        <div className="attention featureFont">
            <b>ATTENTION: </b> If you are an <b>existing</b> user of the <b>old</b> Greenwich Peninsula booking system, you need to reset your password to log in. Click the <b><i>forgotten your password?</i></b> link down below and enter your old login's email.
		</div>

        <form key="email-login" onSubmit={e => {
            e.preventDefault();
            dispatch(setSessionData({ query: { ...query, forEmailConfirmation: false } }));
            dispatch(login(values));
        }}>
            {/* {forEmailConfirmation ? <h4>Great! Now you just have to sign in.</h4> : <h4>Log in with email</h4>}
		<br /> */}
            <Field
                onChange={fieldChange}
                name="email"
                values={values}
                errors={fieldErrors}
                autoComplete="email"
                placeholder="Email"
                validate={validateEmail}
            />
            <Field
                onChange={fieldChange}
                name="password"
                type="password"
                values={values}
                errors={fieldErrors}
                autoComplete="current-password"
                placeholder="Password"
            />
            <Button
                type="submit"
                loading={loading}
                disabled={loading || Object.values(fieldErrors).filter(e => e).length}
            >Log In</Button>
            {loginError && <>
                <br />
                <div className="error text-center">
                    {loginError}
                </div>
            </>}
            {loginError === "Your account email is not confirmed" && <>
                <br />
                <div>Didn't get your confirmation email? Press the button to request another.</div>
                <br />
                <Button
                    disabled={loading || validateEmail(values.email) === "Invalid email."}
                    onClick={() => dispatch(sendEmailConfirmation(values.email, () => {
                        setConfirmationSent(true)
                    }))}
                >
                    Resend Confirmation Email
                </Button>
            </>}
            {confirmationSent && <>
                <br />
                <div style={{ color: "#00ba89" }}>We've sent you a new confirmation email, check your inbox and click the link in the email.</div>
            </>}
        </form>
    </>
}