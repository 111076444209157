import React from "react";
import { useSelector } from "react-redux";
import { asQuestion } from "../QuestionSet";
import UpDownArrows from "../../../UpDownArrows";
import { ImgIcon } from "../../../../../assets";
import { club } from "../../../../../icons";

function Component({ value, onChange, values }) {

	const { selected } = value;
	const playerCount = (values.players.children || 0) + (values.players.adults || 0);
	const clubs = useSelector(state => state.clubs.items);

	const woodClub = clubs.find(club => club.name === "wood") || {};
	const ironClub = clubs.find(club => club.name === "iron") || {};

	const woodCount = (selected.find(item => item.club === woodClub.id) || { quantity: 0 }).quantity;
	const ironCount = (selected.find(item => item.club === ironClub.id) || { quantity: 0 }).quantity;

	return (
		<div className="clubs-question">
			<h4>Need some <b>clubs</b>?</h4>
			<br />
			<div className="details">
				You can optionally add woods <b>(£{(woodClub.price / 100).toFixed(2)})</b> or irons <b>(£{(ironClub.price / 100).toFixed(2)})</b> to your booking.
				<br /><br />
				{playerCount === 1 ?
					<>There's only <b>1</b> of you playing.</>
					:
					<>There are <b>{playerCount}</b> of you playing.</>
				}
			</div>
			<br />
			<div className="flexy wrapping">
				<div className="iron v-middle">
					<div className="icon">
						<div className="inner">
							<ImgIcon
								style={{
									filter: "invert(1)"
								}}
								src={club}
								size={50}
							/>
							<span className="count featureFont">{ironCount}</span>
						</div>
					</div>
					<div className="text-left">
						<span className="featureFont"><b>Iron</b></span>
					</div>
					<div className="controls">
						<UpDownArrows
							value={ironCount}
							onChange={quantity => {
								// Look for an already set club.
								const matchedIndex = selected.findIndex(item => item.club === ironClub.id);
								let newSelection = [...selected];
								// If there was one, change the quantity.
								if (ironCount) {
									newSelection = selected.map((item, index) => index === matchedIndex ?
										{ ...item, quantity } : item
									);
								} else {
									// Otherwise just stick a new entry on.
									newSelection = [...selected, { quantity, club: ironClub.id }];
								}
								onChange({ selected: newSelection });
							}}
						/>
					</div>
				</div>
				<div className="wood v-middle">
					<div className="icon">
						<div className="inner">
							<ImgIcon
								style={{
									filter: "invert(1)"
								}}
								src={club}
								size={50}
							/>
							<span className="count featureFont">{woodCount}</span>
						</div>
					</div>
					<div className="text-left">
						<span className="featureFont"><b>Wood</b></span>
					</div>
					<div className="controls">
						<UpDownArrows
							value={woodCount}
							onChange={quantity => {
								// Look for an already set club.
								const matchedIndex = selected.findIndex(item => item.club === woodClub.id);
								let newSelection = [...selected];
								// If there was one, change the quantity.
								if (woodCount) {
									newSelection = selected.map((item, index) => index === matchedIndex ?
										{ ...item, quantity } : item
									);
								} else {
									// Otherwise just stick a new entry on.
									newSelection = [...selected, { quantity, club: woodClub.id }];
								}
								onChange({ selected: newSelection });
							}}
						/>
					</div>
				</div>
			</div>
			<br />
		</div>
	)
}

const Question = asQuestion(Component, {});
export default Question
