import { initialState } from ".";
import { GET_CLUBS_START, GET_CLUBS_SUCCESS, GET_CLUBS_FAIL } from "../actions/clubs";

export default function (state = initialState.clubs, action) {

    switch (action.type) {
        // Getting clubs
        case GET_CLUBS_START:
            return {
                ...state,
                loading: true
            };

        case GET_CLUBS_SUCCESS:
            return {
                ...state,
                items: action.clubs,
                loading: false
            };

        case GET_CLUBS_FAIL:
            return {
                ...state,
                loading: false
            };

        default:
            return state

    }
}