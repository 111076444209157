import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { removeBooking } from "../../actions/customerBookings";
import { ImgIcon } from "../../assets";
import { groupBookings, findEffect } from "../../helpers";
import { bin } from "../../icons";

export default function BookingsList() {

    const dispatch = useDispatch();
    const { bookings, loading, settings } = useSelector(state => ({
        bookings: state.customerBookings.items,
        loading: state.customerBookings.loading,
        settings: state.settings
    }));

    const bookingGroups = groupBookings(bookings);

    return <div className="bookings-list">
        <h2>Your selected <b>bookings</b></h2>
        <hr />
        {bookingGroups.length
            ?
            <>
                {bookingGroups.map((group, index) => {
                    const date = moment(group.bookings[0].date);
                    return (
                        <div className="group-summary" key={index}>
                            <div className="heading" style={{ paddingTop: `${index === 0 ? "0" : "15px"}` }}>
                                <div className="session align-center rounded">
                                    <b>{`Session ${index + 1}`}</b>
                                </div>
                                <div className="date align-center">
                                    <span><b>{date.format("DD MMM")}</b> {date.format("YYYY")}</span>
                                </div>
                            </div>
                            <div className="bookings">
                                {group.bookings.map(booking => {
                                    return (
                                        <div className="booking flexy" key={booking.id}>
                                            <div style={{ flex: "2" }}>
                                                <div><b>{`Bay ${booking.bay.number}`}</b></div>
                                                <div>{`(${booking.bay.type === "standard" ? "Standard" : "Entertainment"})`}</div>
                                            </div>
                                            <div style={{ flex: "2" }}>
                                                <div><b>{`${booking.slot.from.slice(0, 5)} - ${booking.slot.to.slice(0, 5)}`}</b></div>
                                                <div>{findEffect(booking.slot, moment(booking.date), settings) === "offPeak" ? "Off Peak" : "Peak"}</div>
                                            </div>
                                            <div className="text-right">
                                                <ImgIcon
                                                    // style={{ flex: "initial" }}
                                                    src={bin}
                                                    size={25}
                                                    onClick={e => {
                                                        if (!loading) dispatch(removeBooking(booking))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </>
            :
            <div>You currently have no bookings selected</div>
        }
        {/* {bookings.length
            ?
            <>
                {bookings.map((b, i) => {
                    return (
                        <div
                            key={b.id}
                            className="booking flexy"
                            style={{
                                alignItems: "center",
                                padding: `${i === 0 ? "0px 0px 15px 0px" : "15px 0px"}`,
                                borderBottom: `${i + 1 === bookings.length ? "none" : "1px solid #d5d5d5"}`
                            }}
                        >
                            <div style={{ padding: "0px 21px" }}>
                                <ImgIcon
                                    src={golfBall}
                                    size={15}
                                    style={{
                                        marginRight: "6px",
                                        position: "absolute",
                                        top: "50%",
                                        left: "0",
                                        transform: "translateY(-50%)"
                                    }}
                                />
                                <div className="text-left">
                                    <span style={{ lineHeight: "1.5rem" }}>Bay <b>{b.bay.number}</b> ({b.bay.type}) from <b>{b.slot.from.slice(0, 5)}</b> to <b>{b.slot.to.slice(0, 5)}</b> on <b>{moment(b.date).format("DD-MM-YYYY")}</b></span>
                                </div>
                            </div>
                            <ImgIcon
                                style={{ flex: "initial" }} 
                                src={bin}
                                size={25}
                                onClick={e => {
                                    if (!loading) dispatch(removeBooking(b))
                                }}
                            />
                        </div>
                    )
                })}
            </>
            :
            <div>You currently have no bookings selected</div>
        } */}
    </div>
}