import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addBooking, removeBooking } from "../../../actions/newOrder";
import { dragStart, dragEnd } from "../../../actions/utils";
import { editBooking } from "../../../actions/orders";
import { useModal } from "../../../assets/useModal";
import BookingEditor from "../BookingEditor";
import { ImgIcon } from "../../../assets";
import { eye, star } from "../../../icons";
import { findBooking } from "../../../helpers";
import { useMemo } from "react";

export default function Slot({ dateString, bay, slot, tooltipSide }) {

    const dispatch = useDispatch();
    const { newOrder, orders, publicBookings, dragging } = useSelector(state => ({
        newOrder: state.newOrder,
        orders: state.orders.items,
        publicBookings: state.publicBookings.items,
        dragging: state.utils.dragging
    }));

    const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });

    const { setModal, openModal } = useModal();

    const [hovered, setHovered] = useState(false);
    const [mouseIsDown, setMouseIsDown] = useState(false);

    // Helper variables used mostly for rendering
    const {
        existingBooking,
        bookingOrder
    } = useMemo(function () {
        const existingBooking = findBooking(slot, bay.id, dateString, orders);
        const bookingOrder = existingBooking ? orders.find(o => o.id === existingBooking.order) : null;
        return {
            existingBooking,
            bookingOrder
        }
    }, [slot, bay, dateString, orders]);

    const existingSelection = useMemo(function () {
        return existingBooking
            ? null
            : newOrder.bookingGroups.map(bg => bg.bookings).flat().find(b => (
                b.slot.from === slot.from &&
                b.bay.number === bay.number &&
                b.date === dateString
            ));
    }, [existingBooking, newOrder, bay.number, dateString, slot.from]);


    const systemUser = bookingOrder
        ? !bookingOrder.user.isSystemUser ? null : (bookingOrder.user.email === "anonymous@golfrange.co.uk" ? "anonymous" : "venue")
        : null

    const isDragged = existingBooking && dragging && dragging.id === existingBooking.id;
    const slotStatus = getStatus();
    const isDroppable = dragging && slotStatus === "available";

    const fadeOnDrag = dragging
        ? slotStatus !== "available" && (existingBooking && existingBooking.id !== dragging.id)
        : false;

    useEffect(() => {
        function onMouseUp() {
            dispatch(dragEnd());
        }
        function onMouseMove(e) {
            setDragPosition({
                x: e.clientX,
                y: e.clientY
            });
        }
        if (isDragged) {
            window.addEventListener("mouseup", onMouseUp);
            window.addEventListener("mousemove", onMouseMove);
        }
        return () => {
            window.removeEventListener("mouseup", onMouseUp);
            window.removeEventListener("mousemove", onMouseMove);
        }
    }, [isDragged, dispatch]);

    function getStatus() {
        let status;
        if (existingBooking) {
            status = existingBooking.attended ? "attended" : "booked";
            status = status + (bookingOrder.withLoyaltyCard ? "-loyalty" : "");
        } else if (existingSelection) {
            status = "selected";
        } else if (publicBookings.find(b => (
            ["tentative", "confirmed"].includes(b.status) &&
            b.slot.from === slot.from &&
            b.bay.number === bay.number &&
            b.date === dateString
        ))) {
            status = "watcher";
        } else {
            status = "available";
        }
        return status;
    }

    useEffect(() => {
        function onMouseUp() {
            setMouseIsDown(false)
        }
        if (mouseIsDown) window.addEventListener("mouseup", onMouseUp);
        return () => {
            window.removeEventListener("mouseup", onMouseUp);
        }
    }, [mouseIsDown]);

    return <>
        {/* Drag component, rendered as a guide */}
        {isDragged && <div
            className="drag-component shadow"
            style={{
                left: dragPosition.x,
                top: dragPosition.y
            }}>
            <div className={`bay-slot bay-slot-${slotStatus}`}>
                <SlotGraphic systemUser={systemUser} status={slotStatus} />
            </div>
            {bookingOrder && <div className="text-left">
                <OrderDetails order={bookingOrder} />
            </div>}
        </div>}
        <div
            className={[
                "bay-slot",
                `bay-slot-${slotStatus}`,
                isDragged && "bay-slot-dragged",
                isDroppable && "bay-slot-droppable",
                `${fadeOnDrag ? "faded" : ""}`
            ].filter(Boolean).join(" ")}
            onMouseEnter={() => {
                if (hovered !== true) setHovered(true);
            }}
            onMouseLeave={() => {
                if (hovered !== false) setHovered(false);
            }}
            onMouseDown={e => {
                if (!mouseIsDown) setMouseIsDown(true);
            }}
            onMouseUp={e => {
                if (mouseIsDown) setMouseIsDown(false);
                if (slotStatus === "available" && dragging) {
                    dispatch(editBooking(dragging.order, dragging.id, { slot, bay }));
                };
            }}
            onMouseMove={e => {
                if (mouseIsDown && existingBooking && !dragging) {
                    dispatch(dragStart(existingBooking));
                }
            }}
            onClick={e => {
                if (isDragged) return;
                if (existingBooking) {
                    // If the slot has an existing booking, open the details.
                    setModal(<BookingEditor orderId={existingBooking.order} />);
                    openModal();
                } else {
                    // Otherwise, select/deselect it.
                    existingSelection
                        ? dispatch(removeBooking(existingSelection))
                        : dispatch(addBooking({
                            date: dateString,
                            slot,
                            bay,
                            status: "confirmed"
                        }))
                }
            }}
        >
            <SlotGraphic systemUser={systemUser} status={slotStatus} />
            {(hovered && bookingOrder && !dragging) && <div className={`slot-info shadow text-left ${tooltipSide} large`}>
                <OrderDetails order={bookingOrder} />
                <div className="arrow"></div>
            </div>}
        </div>
    </>
}

function SlotGraphic({ status, systemUser }) {
    return <>
        {systemUser && <svg className={`system-user ${systemUser}`} viewBox="0 0 100 100">
            <polygon points="100,0 100,100 0,100" />
        </svg>}
        {status === "watcher" && <ImgIcon
            cursor={"pointer"}
            size={"100%"}
            iconSize={"70%"}
            src={eye}
        />}
        {(status === "booked-loyalty" || status === "attended-loyalty") && <ImgIcon
            cursor={"pointer"}
            size={"100%"}
            iconSize={"50%"}
            src={star}
        />}
    </>
}

function OrderDetails({ order }) {
    return <>
        <div>#{order.id}</div>
        {order.user.firstName && <div>{order.user.firstName} {order.user.lastName}</div>}
        <div>{order.user.email}</div>
    </>
}
