import React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { removeBooking } from "../../actions/customerBookings";
import { ImgIcon } from "../../assets";
import { bin } from "../../icons";
import { calculateTotal, bookingExpiresIn } from "../../helpers";

export default function GroupSummary({ group, groupIndex }) {

	const dispatch = useDispatch();
	const { settings, user, currentTime } = useSelector(state => ({
		settings: state.settings,
		user: state.session.user,
		currentTime: state.time.current
	}));

	const firstBooking = group.bookings[0];
	const date = moment(firstBooking.date);
	const woodClub = firstBooking.extras.find(e => e.club && e.club.name === "wood");
	const ironClub = firstBooking.extras.find(e => e.club && e.club.name === "iron");

	const adults = group.bookings[0].adults || 0;
	const children = group.bookings[0].children || 0;

	return <div className="group-summary">
		<div className="heading v-middle">
			<div className="session align-center rounded">
				<b>
					Session {groupIndex + 1}{"  |  "}
					{[
						adults ? `${adults} adult${adults === 1 ? "" : "s"}` : null,
						children ? `${children} child${children === 1 ? "" : "ren"}` : null,
					].filter(Boolean).join(", ")}
				</b>
			</div>
			<div className="date align-center">
				<span><b>{date.format("DD MMM")}</b> {date.format("YYYY")}</span>
			</div>
		</div>
		<div className="bookings">
			{group.bookings.map(booking => (
				<div className="booking" key={booking.id}>
					<div className="flexy">
						<div style={{ flex: "2" }}>
							<div><b>Bay {booking.bay.number}</b></div>
							<div>({booking.bay.type === "standard" ? "Standard" : "Entertainment"})</div>
						</div>
						<div style={{ flex: "2" }}>
							<div><b>{booking.slot.from.slice(0, 5)} - {booking.slot.to.slice(0, 5)}</b></div>
						</div>
						<div className="text-right">
							<ImgIcon
								src={bin}
								size={20}
								onClick={() => {
									if (window.confirm("Are you sure you want to remove this booking?"))
										dispatch(removeBooking(booking))
								}}
							/>
						</div>
					</div>
					{Boolean(user) && <div className="countdown featureFont">
						{bookingExpiresIn(booking.updated_at, currentTime, 5)}
					</div>}
				</div>
			))}
		</div>
		{Boolean(firstBooking.extras.length) && <div className="clubs flexy">
			<div style={{ flex: "2" }}>
				<b>Clubs</b>
			</div>
			<div style={{ flex: "2" }}>
				{Boolean(woodClub) && <div>
					<b>Wood</b> <b>x{woodClub.quantity}</b>
				</div>}
				{Boolean(ironClub) && <div>
					<b>Iron</b> <b>x{ironClub.quantity}</b>
				</div>}
			</div>
			<div></div>
		</div>}
		<div className="session-summary flexy">
			<div className="text-left">
				<b>Session total:</b>
			</div>
			<div className="text-right">
				<b>£{(calculateTotal(group.bookings, firstBooking.extras, settings) / 100).toFixed(2)}</b>
			</div>
		</div>
	</div>
}