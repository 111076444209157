import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { golfBall, club, user } from "../../icons";
import { ImgIcon } from "../../assets";
import { canRefund } from "../../helpers"
import { cancelOrder } from "../../actions/myOrders";
import { Button } from "../../fields";

export default function OrderTile({ order }) {

	const dispatch = useDispatch();
	const loading = useSelector(state => state.myOrders.loading);
	const orderIsCancelled = isCancelled(order.bookings);
	const orderCanBeCancelled = canRefund(order.bookings);

	return <div className={`order-tile shadow${orderIsCancelled ? " cancelled" : ""}`}>
		<div className="header text-right">
			<div className="order-id">
				{`#${order.id}`}
			</div>
			<div className="align-center text-left" style={{ paddingRight: "15px" }}>
				<div>ORDER PLACED</div>
				<div><b>{moment(order.created_at).format("D MMMM YYYY")}</b></div>
			</div>
			<div className="align-center text-left">
				<div>ORDER TOTAL</div>
				<div><b>{`£${calculateTotal(order.lineItems) / 100}`}</b></div>
			</div>
		</div>
		<br />
		<div style={{ padding: "0px 10px" }}>ORDER DETAILS</div>
		<div className="content">
			<div className="items">
				{order.lineItems
					.sort((a, b) => {
						let aPriority = a.type === "bay" ? 1 : 2;
						let bPriority = b.type === "bay" ? 1 : 2;
						return aPriority - bPriority;
					})
					.map((item, index) => {
						let icon = item.type === "bay" ? golfBall : club;
						return <div className={`item ${item.type}`} key={index}>
							<ImgIcon src={icon} size={15} />
							<span>{`${item.quantity}x ${item.name} - £${(item.quantity * item.price) / 100}`}</span>
						</div>
					})}
			</div>
			<div className="people">
				<div className="person">
					<ImgIcon src={user} size={15} />
					<span>{`adults x${order.bookings.reduce((acc, b) => acc + b.adults || 0, 0)}`}</span>
				</div>
				<div className="person">
					<ImgIcon src={user} size={15} />
					<span>{`children x${order.bookings.reduce((acc, b) => acc + b.children || 0, 0)}`}</span>
				</div>
			</div>
		</div>
        {order.refunded && <div className="refunded" style={{ padding: "0px 10px 10px 10px" }}>
            <div className="align-center">REFUNDED</div>
        </div>}
		{(!orderIsCancelled && orderCanBeCancelled) &&
			<Button
				className="cancel-order text-center pointer featureFont"
				onClick={() => {
					if (window.confirm("Are you sure you want to cancel this order?"))
						dispatch(cancelOrder(order.id));
				}}
				loading={loading}
			>
				Cancel order
			</Button>
		}
		{orderIsCancelled && <div className="cancellationMessage featureFont">
			Cancelled
		</div>}
	</div>
}

function calculateTotal(items) {
	let total = 0;
	items.forEach(item => total += (item.price * item.quantity));
	return total;
}

function isCancelled(bookings) {
	let result = true;
	bookings.forEach(b => {
		if (b.status !== "cancelled") result = false;
	})
	return result;
}
