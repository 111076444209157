import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { searchUsers, updateUser } from "../../actions/users";
import { sendEmailConfirmation } from "../../actions/session";
import { addLog } from "../../actions/logger";
import { CheckboxField, Button } from "../../fields";
import { ImgIcon } from "../../assets"
import { mail } from "../../icons";

export default function UsersLookup() {

    const dispatch = useDispatch();
    const { users } = useSelector(state => ({
        users: state.users.items
    }))

    const [search, setSearch] = useState("");

    useEffect(() => {
        let to;
        if (search) {
            to = setTimeout(() => {
                dispatch(searchUsers({ _q: search }));
            }, 500)
        }
        return () => {
            clearTimeout(to)
        }
    }, [search, dispatch]);

    const filteredUsers = filterUsers(users, search);

    return <div className="users-lookup-admin">
        <h2><b>Customer</b> Lookup</h2>
        <hr />
        <input
            type="text"
            placeholder="search..."
            value={search}
            onChange={e => setSearch(e.target.value)}
        />
        <div className="users-list" style={{ marginTop: "15px" }}>
            {filteredUsers.length && Boolean(search)
                ?
                <table>
                    <tbody>
                        <tr>
                            <th>Email</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Telephone</th>
                            <th>Loyalty Card</th>
                            <th></th>
                        </tr>
                        {filteredUsers.map(user => (
                            <User key={user.id} user={user} />
                        ))}
                    </tbody>
                </table>
                :
                <h2 className="no-users text-center">No matches</h2>
            }
        </div>
    </div>
}

function User({ user }) {

    const dispatch = useDispatch();
    const [changes, setChanges] = useState({});

    const values = {
        ...user,
        ...changes
    };

    const canSubmit = Object.keys(changes).length;

    function change(data) {
        const newChanges = { ...changes, ...data };
        setChanges(newChanges);
    };

    return (
        <tr className="user">
            <td>{values.email}</td>
            <td>
                <input
                    placeholder="first name"
                    className="chameleon"
                    type="text"
                    value={values.firstName || ""}
                    onChange={e => change({ firstName: e.target.value })}
                />
            </td>
            <td>
                <input
                    placeholder="last name"
                    className="chameleon"
                    type="text"
                    value={values.lastName || ""}
                    onChange={e => change({ lastName: e.target.value })}
                />
            </td>
            <td>
                <input
                    placeholder="telephone"
                    className="chameleon"
                    type="text"
                    value={values.telephone || ""}
                    onChange={e => change({ telephone: e.target.value })}
                />
            </td>
            <td>
                <CheckboxField
                    size={20}
                    label="."
                    onChange={val => change({ hasLoyaltyCard: val })}
                    style={{ padding: "0px" }}
                    value={values.hasLoyaltyCard}
                />
            </td>
            <td>
                <Button
                    disabled={user.confirmed}
                    style={{
                        padding: "3px",
                        backgroundColor: "#00ba89",

                    }}
                    onClick={() => {
                        if (window.confirm(`Are you sure you want resend an email confirmation to ${user.email}`))
                            dispatch(sendEmailConfirmation(user.email, () => {
                                dispatch(addLog({
                                    type: "success",
                                    text: "Email sent"
                                }))
                            }))
                    }}
                >
                    <ImgIcon
                        cursor={!user.confirmed ? "pointer" : "initial"} 
                        className="align-center"
                        src={mail}
                        size={15}
                        style={{ marginRight: "5px" }}
                    />
                    <span className="align-center">Resend</span>
                </Button>
            </td>
            <td>
                <Button
                    loading={user.loading}
                    disabled={!canSubmit}
                    style={{
                        padding: "3px",
                        backgroundColor: "#00ba89",

                    }}
                    onClick={() => {
                        dispatch(updateUser(user.id, changes, () => {
                            setChanges({})
                        }))
                    }}
                >
                    Save
            </Button>
            </td>
        </tr>
    )

}

function filterUsers(users, filterBy) {
    return users
        // Filtering out system users
        .filter(user => !user.isSystemUser)
        // Filtering based on user search
        .filter(user => {
            if (!filterBy) return user;
            const lowercaseSearch = filterBy.toLocaleLowerCase();
            return (user.email ? user.email.toLocaleLowerCase() : "").includes(lowercaseSearch) ||
                `${user.firstName ? user.firstName.toLocaleLowerCase() : ""} ${user.lastName ? user.lastName.toLocaleLowerCase() : ""}`.includes(lowercaseSearch) ||
                user.id === Number(filterBy);
        })
}
